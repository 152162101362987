import { NgModule } from '@angular/core';

import { 
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatCommonModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatRadioModule,
    MatSnackBarModule,
    MatTabsModule,
    MatSidenavModule,
    MatSliderModule,
    MatInputModule,
    MatToolbarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule
} from '@angular/material';

  import { 
      PortalModule
} from '@angular/cdk/portal';

import {
    OverlayModule
} from '@angular/cdk/overlay';

import {
  ReactiveFormsModule,
  FormsModule
} from '@angular/forms';

import { 
  FlexLayoutModule 
} from '@angular/flex-layout';

import {
  FileUploadModule
} from 'ng2-file-upload/ng2-file-upload';

import { CdkAccordionModule } from '@angular/cdk/accordion';

import {
  AngularSplitModule
} from 'angular-split';

import { SortablejsModule } from 'angular-sortablejs';

const MATERIAL_MODULES = [
    MatExpansionModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatCommonModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatRadioModule,
    MatSnackBarModule,
    MatSliderModule,
    MatTabsModule,
    MatSidenavModule,
    MatInputModule,
    MatToolbarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSlideToggleModule,
    CdkAccordionModule,
    OverlayModule,
    PortalModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    FileUploadModule,
    AngularSplitModule,
    MatGridListModule,
    SortablejsModule,
    MatStepperModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule
  ];

  @NgModule({
    imports: MATERIAL_MODULES,
    exports: MATERIAL_MODULES
  })
  export class AppMaterialModule { }


  