import { Subject ,  Observable } from 'rxjs';
import { SecurityService } from '../services';

export class RestQueryStore {
}

export class StoreRestQuery {
    private _path: string;
    private _subject = new Subject<any>();
    private _ready: boolean = false;

    get results(): Observable<any> { return this._subject; };

    get path(): any { return this._path };
    set path(path: any) { this._path = path; this.checkResults(); };

    get ready(): any { return this._ready };
    set ready(ready: any) { this._ready = ready; this.checkResults(); };        

    constructor(private _restQueryStore: RestQueryStore, private _securityService: SecurityService) {
    }

    private checkResults() {
        if (this._ready) {
            // TODO: check the query store to see if we already have results for this path

            this._securityService.get(this._path)
                .subscribe(r => this.processResults(r));
        }
    }

    private processResults(results) {
        // TODO: add the results to the store
        
        this._subject.next(results);
    }
}