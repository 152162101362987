import { SecurityService } from "../services";
import { AbstractQuery } from "./abstract-query";
import { map } from "rxjs/operators";

export class DataSetQuery extends AbstractQuery {

    protected _dataSetId: number = null;    
    protected _selects: any[];

    get dataSetId(): number { return this._dataSetId };
    set dataSetId(dataSetId: number) { this._dataSetId = dataSetId; this.refresh(); };        

    get selects(): any[] { return this._selects };
    set selects(selects: any[]) { this._selects = selects; this.refresh(); };        

    constructor(
        private dataSetStore: DataSetStore,
        private securityService: SecurityService
    ) {
        super();
    }

    protected refresh() {
        if (this._ready) {
            let request: any = {
                dataSetId: this._dataSetId,
                filters: this._filters,
                sorts: this._sorts,
                beginRecord: this._beginRecord || 1
            };
            if (this._recordLimit) {
                request.recordLimit = this._recordLimit;
            }
            if (this._selects) {
                request.selects = this._selects;
            }
            this.securityService.post("/sandbox/ws/dataSet/execute", JSON.stringify(request))
                .subscribe(result => {
                    result.pending = false;
                    this._resultsSubject.next(result);
                });
        }
    }
}

export class DataSetStore {
}