import { Component, OnInit, Input } from '@angular/core';
import { StoreQuery, StoreService } from '../store';

@Component({
    selector: 'app-data-set-view',
    templateUrl: './data-set-view.component.html',
    styleUrls: ['./data-set-view.component.css']
})
export class DataSetViewComponent implements OnInit {

    @Input()
    public dataSetId;

    @Input()
    public viewConfig;

    constructor(
        private storeService: StoreService
    ) { 

    }

    ngOnInit() {
    }

}
