import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService, StoreQuery } from '../../platform';

@Component({
    selector: 'app-circuit-list',
    templateUrl: './circuit-list.component.html',
    styleUrls: ['./circuit-list.component.css']
})
export class CircuitListComponent implements OnInit, OnChanges {

    @Input() 
    public locationId;

    @Input() 
    public externalSystemId;

    @Input()
    public circuitText;

    @Input()
    public clientId;

    @Input()
    public filterEnabled = false;

    public circuitQuery: StoreQuery;
    public filters: any = {};

    constructor(
        private storeService: StoreService,
        private router: Router
    ) 
    {
        this.circuitQuery = storeService.query();
        this.circuitQuery.baseSpec = {
            queryType: 'circuits/circuitList',
            recordLimit: 50,
            summaries: {
                circuitStatus: { circuitStatus: 'none', circuitId: 'count' },
                vendor: { vendorName: 'none', circuitId: 'count' },
                commonCircuitType: { commonCircuitType: 'none', circuitId: 'count' }
              },
              determinePopulated: true   
        };

    }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        if (changes.locationId || changes.externalSystemId || changes.circuitText) {
            this.refresh();
        }
    }

    refresh() {
        let filters:any = Object.assign({}, this.filters);

        if (this.locationId) {
            filters.locationId = this.locationId;
        }
        if (this.externalSystemId) {
            filters.externalSystemId = this.externalSystemId;
        }
        if (this.externalSystemId) {
            filters.clientId = this.clientId;
        }
        if (this.circuitText) {
            filters.circuitText = this.circuitText;
        }

        if (filters.locationId || filters.externalSystemId) {
            this.circuitQuery.filters = filters;
            this.circuitQuery.ready = true;
        }
    }

    handleChangeFilter(event) {
        if (!event.values) {
            delete this.filters[event.propertyName];
        } else {
            this.filters[event.propertyName] = {};
            this.filters[event.propertyName][event.operator] = event.values ;
        }
        this.refresh();
    }
    
    handleRowClick(record) {
        this.router.navigate([ 'pages/circuits/circuitDetail', record.circuitId]);
    }
}

