import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { StoreService, StoreQuery } from '../../../platform';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-retail-site-detail',
    templateUrl: './retail-site-detail.component.html',
    styleUrls: ['./retail-site-detail.component.css']
})
export class RetailSiteDetailComponent implements OnInit, OnDestroy {

    @Input()
    public filterEnabled: boolean = true;

    public productQuery: StoreQuery;
    public locationQuery: StoreQuery;

    private locationId;
    private filters = [];
    private destroyed: Subject<boolean> = new Subject<boolean>()

    constructor(
        private storeService: StoreService,
        private route: ActivatedRoute
    ) {

        this.productQuery = storeService.query();
        this.productQuery.baseSpec = {
            queryType: 'client/total-wine/store-product',
            recordLimit: 20,
            summaries: {
                productType: { productType: 'none', itemId: 'count' },
                finish: { finish: 'none', itemId: 'count' },
                country: { country: 'none', itemId: 'count' }
            }
        };

        this.locationQuery = storeService.query();
        this.locationQuery.baseSpec = {
            queryType: 'client/total-wine/location'
        };


    }

    refresh() {
        console.log("CALLING REFRESH", this.locationId);
        if (this.locationId) {
            this.productQuery.filters = Object.assign({}, { locationId: this.locationId }, this.filters);
            this.productQuery.ready = true;
        }
    }

    ngOnInit() {
        this.route.params.pipe(takeUntil(this.destroyed)).subscribe(params => {
            console.log("PARAMMAP:", params);
            if (params['locationId']) {
                this.locationId = +params['locationId'];

                this.locationQuery.filters = { locationId: this.locationId };
                this.locationQuery.ready = true;

                this.refresh();
            }
        });
    }

    ngOnDestroy() {
        this.destroyed.next(true);
    }

    handleSearchTextChange(searchText) {
        if (searchText && searchText.length > 0) {
            this.filters['searchableText'] = { searchText: searchText };
        } else {
            delete this.filters['searchableText'];
        }
        this.refresh();
    }

    handleChangeFilter(event) {
        var me = this;

        if (!event.values) {
            delete me.filters[event.propertyName];
        } else {
            me.filters[event.propertyName] = {};
            me.filters[event.propertyName][event.operator] = event.values;
        }
        this.productQuery.beginRecord = 1;
        this.refresh();
    }

}
