import { Component, OnInit, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { DataSetQuery } from '../../store/data-set-store';
import { QueryResult, UtilityService } from '../../services';
import { Subject } from 'rxjs';
import { StoreService } from '../../store';
import * as L from 'leaflet';
import * as Wkt from 'wicket';
import 'leaflet-measure';

@Component({
    selector: 'app-data-set-map-view',
    templateUrl: './data-set-map-view.component.html',
    styleUrls: ['./data-set-map-view.component.css' ]
})
export class DataSetMapViewComponent implements OnInit {

    @Input()
    dataSetId: number;

    @Input()
    viewConfig: any;

    public dataSetQuery: DataSetQuery;
    chart: any;
    results: QueryResult;
    destroyed = new Subject<boolean>();
    map: any;
    markers: any[];
    markerLayer: any;

    constructor(
        private storeService: StoreService,
        private utilityService: UtilityService
    ) {
        this.dataSetQuery = storeService.dataSetQuery();
        //this.dataSetQuery.recordLimit = 50;
        this.dataSetQuery.results.pipe(takeUntil(this.destroyed)).subscribe(results => {
            this.results = results;
            this.displayMap();
        });
    }

    ngOnInit() {
        this.displayMap();
    }

    ngAfterViewInit() {

        this.map = L.map('map').setView([51.505, -98.5795], 13);

        L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
            attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
            maxZoom: 18,
            id: 'mapbox.streets',
            accessToken: 'pk.eyJ1IjoiZGF2aWRwY29ybGV5IiwiYSI6ImNqY3Awa29objJkbjMyeG1yemk3aGZ4dWwifQ.KG7k5UmmwJmpTNvq_Hp4qA'
        }).addTo(this.map);

        let measureControl = L.control.measure({
            primaryAreaUnit: 'sqfeet', secondaryAreaUnit: 'sqmiles',
            primaryLengthUnit: 'feet', secondaryLengthUnit: 'miles',
            activeColor: '#9932CC'
        });
        measureControl.addTo(this.map);

        this.map.invalidateSize();
    }

    ngOnChanges(changes) {
        if (changes.dataSetId) {
            this.refresh();
        }
    }

    ngOnDestroy() {
        this.destroyed.next(true);
    }

    refresh() {
        if (this.viewConfig && this.dataSetId) {
            let selectProperties = new Set([
                this.viewConfig.geographyProperty,
                ...this.viewConfig.popupProperties,
                ...(this.viewConfig.scaleProperty ? [this.viewConfig.scaleProperty] : [])
            ]);

            // figure out all the things we need to select in the query

            let selects = [];
            selectProperties.forEach(p => selects.push({ property: p }));
            this.dataSetQuery.dataSetId = this.dataSetId;
            this.dataSetQuery.selects = selects;
            this.dataSetQuery.ready = true;

        } else {
            this.dataSetQuery.ready = false;
        }
    }


    private displayMap() {
        if (!this.viewConfig || !this.map) {
            return;
        }
        console.log("RESULTS", this.results);
        let records = this.results.records;
        let maxMeasure = 0;

        // if there is point scaling, then figure out the maximum value

        if (this.viewConfig.scaleProperty) {
            records.forEach(r => {
                let value = r[this.viewConfig.scaleProperty];
                if (value) {
                    maxMeasure = Math.max(maxMeasure, value);
                }
            });

            this.markers = [];
            records.forEach(r => {
                // get the lat/lon

                let wktValue = r[this.viewConfig.geographyProperty];
                if (!wktValue) {
                    return;
                }

                let wkt = new Wkt.Wkt();
                wkt.read(wktValue);
                let geo = wkt.toJson();

                let lat = geo.coordinates[1];
                let lon = geo.coordinates[0];
                
                let markerSize = this.viewConfig.markerSize || (this.viewConfig.scaleProperty ? 20 : 5);
                
                if (this.viewConfig.scaleProperty && maxMeasure) {
                    markerSize = markerSize * (r[this.viewConfig.scaleProperty] / maxMeasure);
                }
                console.log("SIZE: ", this.viewConfig.scaleProperty, markerSize, r[this.viewConfig.scaleProperty], maxMeasure);

                console.log("MARKER", lat, lon, markerSize);
                let marker = L.circleMarker([lat, lon], {
                    stroke: true,
                    fillColor: this.viewConfig.fillMarkers ? '#f03' : undefined,
                    color: '#f03',
                    fillOpacity: 0.25,
                    radius: markerSize
                });

                if (this.viewConfig.popupProperties) {
                    let popup = '<table>';
                    this.viewConfig.popupProperties.forEach(pp => {
                        if (r[pp]) {
                            let c = this.results.columns.find(c => c.name == pp);
                            let value = this.utilityService.formatColumnValue(r[pp], c);
                            popup = popup + '<tr><td><b>' + (c ? c.label : pp ) + '</b></td><td>' + value + '</td></tr>';
                        }
                    });
                    console.log(popup);
                    marker.bindPopup(popup);
                }
                this.markers.push(marker);
            });

            if (!this.markerLayer) {
                this.markerLayer = L.featureGroup(this.markers);
                this.map.addLayer(this.markerLayer);
                if (this.markers.length) {
                    this.map.fitBounds(this.markerLayer.getBounds());
                }
            } else {
                this.markerLayer.clearLayers();
                this.markers.forEach(m => this.markerLayer.addLayer(m));
            }
        }
    }
}