import { Component, Input, Output, ViewChild, ElementRef, OnChanges, SimpleChanges, EventEmitter, ChangeDetectorRef } from '@angular/core';

import { Logger } from "angular2-logger/core";

import { Query } from '../services/query.model';
import { QueryService } from '../services/query.service';
import { QueryResult } from '../services/queryResult.model';
import { ThemeService } from '../services/theme.service';

import * as Chart from 'chart.js';
import 'chart.piecelabel.js';

@Component({
    selector: 'query-chart',
    templateUrl: './queryChart.html',
})
export class QueryChartComponent implements OnChanges{

    private chart: any;

    @Input() queryType: string;
    @Input() queryModifiers: any = {};
    @Input() queryResult: QueryResult = new QueryResult();
    @Input() querySummary: string;
    @Input() chartType: string = "bar";
    @Input() chartOptions: any;
    @Input() labelColumn: string;
    @Input() dataColumns: string[];
    @Input() seriesLabels: string[] = [];
    @Input() noDataMessage: string;

    @Output()
    private clickData: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('chartElement') public chartElement: ElementRef;

    chartReady: boolean = false;
    chartHasData: boolean = false;

    private chartData: any;
    private query: Query;


    constructor(private queryService: QueryService, private logger: Logger, private themeService: ThemeService, private changeDetectorRef: ChangeDetectorRef) {
        var me = this;

    }

    ngAfterViewInit() {
        var me = this;
        if (me.queryType) {
            me.query = new Query(me.queryService);
            me.query.results.subscribe(function(r) {
                me.logger.debug("chart received new results: pending=" + r.pending);
                me.queryResult = r;
                me.checkDrawChart();
            });
            me.executeQuery();
        }
    }

    private executeQuery() {
        var me = this;
        if (me.queryType) {
            me.chartReady = false;
            me.query.execute(Object.assign({ queryType: me.queryType }, me.queryModifiers));
        }
    }

    private getChartRecords() {
        var me = this;
        if (me.queryResult) {
            if (me.querySummary) {
                return me.queryResult.summaries[me.querySummary];
            } else {
                return me.queryResult.records;
            }
        }
    }

    private checkDrawChart() {
        var me = this;

        me.logger.debug("checkDrawChart() called, pending=" + me.queryResult.pending);

        if (this.chart) {
            me.logger.debug("destroying previous chart");
            this.chart.destroy();
            this.chart = null;
            let canvas = this.chartElement.nativeElement;
            canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
        }
        
        if (me.queryResult.pending) {
            me.chartReady = false;
            me.changeDetectorRef.detectChanges();
            return;
        }
        me.chartReady = true;
        
        var records = me.getChartRecords();

        me.chartHasData = records.length > 0;

        if (records) {
            let labelColumn = me.labelColumn;
            let dataColumns = me.dataColumns;

            var data = {
                labels: records.map(r => r[labelColumn] || 'N/A'),
                datasets: []
            };
            for (let i = 0; i < dataColumns.length; i++) {
                let dataset: any = {
                    label: me.seriesLabels.length > i ? me.seriesLabels[i] : undefined,
                    data: records.map(r => r[dataColumns[i]])
                };
                if (me.chartType != 'pie') {
                    dataset.backgroundColor = me.themeService.getChartColorForId(i);
                } else {
                    dataset.backgroundColor = me.themeService.getChartColors();
                }
                data.datasets.push(dataset);
            }

            let options:any = Object.assign({ 
                responsive: true, 
                maintainAspectRatio: false, 
                tooltips: { enabled: false },
                onClick: function(event, array) { 
                    me.handleChartClick(event, array); 
                }
            }, 
                me.chartOptions);

            // https://github.com/chartjs/Chart.js/issues/2808
            if (me.chartOptions.fixMirror) {
                let fix = function() {
                    const chartInstance = this.chart;
                    const ctx = chartInstance.ctx;
                    const dataset = this.data.datasets[0];
                    const meta = chartInstance.controller.getDatasetMeta(0);
                
                    Chart.helpers.each(meta.data.forEach((bar, index) => {
                        const label = this.data.labels[index];
                        const labelPositionX = 20;
                        const labelWidth = ctx.measureText(label).width + labelPositionX;
                
                        ctx.textBaseline = 'middle';
                        ctx.textAlign = 'left';
                        ctx.fillStyle = '#333';
                        ctx.fillText(label, labelPositionX, bar._model.y);
                    }));
                }

                options.animation = {
                    onComplete: fix,
                    onProgress: fix
                };

                // disable the normal rendering
                options.scales.yAxes[0].ticks.callback = () => "";
                
                // remove fixMirror since this is just a hack and not a real option
                delete options.fixMirror;
            }

            this.chart = new Chart(this.chartElement.nativeElement, {
                type: me.chartType,
                data: data,
                options: options
            });

            this.chartData = data;
            me.logger.debug("after chart redraw: chartHasData=" + me.chartHasData + ", chartReady=" + me.chartReady);
        }
    }

    ngOnChanges(changes) {
        var me = this;
        if (Object.keys(changes).length == 1 && changes.chartOptions) {
            return;
        }
        me.logger.debug("chart received changes: " + JSON.stringify(Object.keys(changes)));
        if (me.query && (changes.queryType || changes.queryModifiers)) {
            me.executeQuery();
        } else if (changes.queryResult) {
            me.checkDrawChart();
        }
        
    }

    private handleChartClick(event, array) {
        var me = this,
            records = me.getChartRecords();

        if (array && array[0]) {
            me.clickData.emit(records[array[0]._index]);
        }
    }
}