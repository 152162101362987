import { Component, Input, Output, EventEmitter, OnChanges, ContentChildren, ViewChild, QueryList, ElementRef, HostListener, AfterViewChecked, OnDestroy, AfterContentInit, TemplateRef, ContentChild } from '@angular/core';

import { QueryService, QueryResult, Query } from '../../services';
import { StoreQuery, StoreService } from '../../store';

import { QueryTableColumnComponent } from './queryTableColumn.component';
import { QueryPagerComponent } from '../queryPager.component';
import { collectExternalReferences } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ObservableMedia } from '@angular/flex-layout';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DataSetQuery } from '../../store/data-set-store';
import { LinkHelperService } from '../../link-helper.service';

@Component({
    selector: 'query-table',
    templateUrl: './queryTable.html',
    styleUrls: [ './queryTable.scss' ]
})
export class QueryTableComponent implements OnChanges, AfterContentInit, OnDestroy {

    @Input() 
    query: Query | StoreQuery | DataSetQuery;

    @Input()
    private displayNames: string[];
    
    @Input() 
    private paging: boolean = false;

    // Hide table if no records
    @Input()
    private hideEmpty: boolean = false;

    // hide columns where the 
    @Input()
    private hideEmptyColumns: boolean = false;

    // You must specify trackByProperty if selectable = true
    @Input()
    private selectable: boolean = false;

    @Input()
    private rowStyle: (any) => any;
    
    @Input()
    public tableLayout: 'wide' | 'narrow' = 'wide';

    @Input()
    public trackByProperty: string;

    @Input()
    public narrowBreakpoint = 'lt-md';

    @Input()
    public searchable = false;

    @Output()
    private rowClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    private rowDoubleClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    private cellClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    private selectionChange: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    private searchTextChange: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(QueryPagerComponent)
    private pager: QueryPagerComponent;

    @ViewChild('selectColumnsDialog')
    private selectColumnsTemplate: TemplateRef<any>;

    @ContentChildren(QueryTableColumnComponent) 
    private columnComponentChildren: QueryList<QueryTableColumnComponent>;

    @ContentChild('tableMenuItemsTemplate') 
    public tableMenuItemsTemplate: TemplateRef<ElementRef>;

    @ContentChild('toolbarButtonsTemplate') 
    public toolbarButtonsTemplate: TemplateRef<ElementRef>;    
    
    public sortablejsOptions;
    public selectedRecordCount;
    public allRecordsSelected = false;

    private allColumnComponents: QueryTableColumnComponent[] = [];
    private selectedColumnComponents: QueryTableColumnComponent[] = [];

    private sortProperty: string;
    private sortDirection: 'ascending' | 'descending';

    private destroyed: Subject<boolean> = new Subject<boolean>();

    private results: QueryResult = new QueryResult();    
    private records: any[];
    private rowStyles: any[];

    private selectedRecords = {};

    private searchText;

    private selectAllQuery: StoreQuery;
    private pendingSelectAll = false;

    constructor(
        private storeService: StoreService,
        private observableMedia: ObservableMedia,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        private linkHelperService: LinkHelperService
    )
    {
        this.selectAllQuery = storeService.query();
        this.selectAllQuery.results.pipe(takeUntil(this.destroyed)).subscribe(r => this.handleSelectAllResults(r));

        this.observableMedia.asObservable().pipe(takeUntil(this.destroyed)).subscribe(() => {
            if (this.narrowBreakpoint) {
                if (this.observableMedia.isActive(this.narrowBreakpoint)) {
                    this.tableLayout = 'narrow';
                } else {
                    this.tableLayout = 'wide';
                }
            }
        });

        this.sortablejsOptions = {
            handle: '.sortablejs-handle',
            onUpdate: () => {
                this.handleColumnsChanged();
            }
        };
    }

    ngOnChanges(changes) {
        console.log("CHANGES: ", changes);
        if (changes.query && this.query) {
            this.query.results.pipe(takeUntil(this.destroyed)).subscribe(results => {
                this.handleNewResults(results);
            });
        }
    }

    private handleNewResults(results) {
        console.log("RESULTS: ", results);
        this.records = results.records;
        this.results = results;

        // determine row styles based on supplied function
        if (this.rowStyle) {
            this.rowStyles = new Array(this.records.length);
            for (let i = 0; i < this.records.length; i++) {
                this.rowStyles[i] = this.rowStyle(this.records[i]) || {};
            }
            console.log(this.rowStyles);
        } else {
            this.rowStyles = null;
        }
        this.buildColumnComponents();
    }

    ngAfterContentInit() {
        this.buildColumnComponents();
    }

    ngOnDestroy() {
        this.destroyed.next(true);
    }

    buildColumnComponents() {

        if (!this.results || this.results.pending) {
            return;
        }
        let hiddenHints = {};

        // start with any column components that were specified in the table

        let allColumnComponents = [];
        if (this.columnComponentChildren) {
            allColumnComponents = this.columnComponentChildren.toArray();
        }
        allColumnComponents.forEach((cc, i) => {
            if (!cc.name) {
                // if no name is specified, create one that won't conflict with
                // the existing
                cc.name = cc.property || '_column' + (i + 1);
            }
        });


        // add or update all column components from the result set
        
        this.results.columns.forEach((resultColumn, i) => {
            let cc = allColumnComponents.find(cc => cc.name == resultColumn.name);
            if (!cc) {
                if (this.isSpecialColumn(resultColumn.name)) {
                    return;
                }
                cc = new QueryTableColumnComponent();
                cc.name = resultColumn.name;
                cc.property = resultColumn.name;
                allColumnComponents.push(cc);
            }
            hiddenHints[cc.name] = (resultColumn.hints && resultColumn.hints.indexOf('hidden') != -1) ||
                (this.hideEmptyColumns && !resultColumn.populated);

            cc.title = cc.title || resultColumn.label;
            if ((resultColumn.dataType == "timestamp" || resultColumn.dataType == "date") && !resultColumn.dataFormat){
                resultColumn.dataFormat = "date";
            }
            cc.format = cc.format || resultColumn.dataFormat;
            
            cc.linkType = this.linkHelperService.getLinkTypeFromHints(resultColumn.hints);
        });

        if (!this.displayNames) {
            if (this.columnComponentChildren && this.columnComponentChildren.length) {
                // if displayNames were not specified and there were child column components
                // then just display those as long as they weren't marked hidden
                this.displayNames = this.columnComponentChildren
                    .filter(cc => !cc.hidden)
                    .map(cc => cc.name);
            } else {
                // if displayNames were not specified and there weren't child column components,
                // show all of the non-hidden ones
                this.displayNames = allColumnComponents
                .filter(cc => !hiddenHints[cc.name])
                .map(cc => cc.name);
            }
        } else {
            // put in displayNames order
            let save = allColumnComponents;
            allColumnComponents = this.displayNames.map(name => save.find(s => s.name == name));
            allColumnComponents = allColumnComponents.concat(save.filter(cc => allColumnComponents.indexOf(cc) == -1));
        }
        allColumnComponents.forEach(cc => cc.hidden = !this.displayNames.find(name => cc.name == name));
        this.allColumnComponents = allColumnComponents;
        this.handleColumnsChanged();
    }

    private isSpecialColumn(name) {
        return name == "_rownum" || name == "__rownum" || name == "_totalRecords";
    }

    private handleHeaderClick(column) {
        if (column.sortable) {
            if (this.sortProperty == column.property) {
                this.sortDirection = (this.sortDirection == 'ascending' ? 'descending' : 'ascending');
            } else {
                this.sortProperty = column.property;
                this.sortDirection = 'ascending';
            }
            let sorts = [ { property: this.sortProperty, direction: this.sortDirection } ];
            if (this.query instanceof StoreQuery || this.query instanceof DataSetQuery) {
                this.query.sorts = sorts;
            } else {
                this.query.querySpec.sorts = sorts;
                this.query.execute();
            }
        }
    }

    private handleRowClick(event, record) {
        if (event.target.children) {
            for (let i = 0; i < event.target.children.length; i++) {
                if (event.target.children[i].tagName.toLowerCase() == "input")
                return;
            }
        }
        this.rowClick.emit(record);

    }

    private handleRowDoubleClick(event, record) {
        this.rowDoubleClick.emit(record);
    }

    public handleCellClick(event, record, column) {
        this.cellClick.emit({ record: record,  column: column });
    }
 
    public handleSelectColumns() {
        this.dialog.open(this.selectColumnsTemplate);
    }

    public handleColumnsChanged() {
        this.selectedColumnComponents = this.allColumnComponents.filter(cc => !cc.hidden);
        this.displayNames = this.selectedColumnComponents.map(cc => cc.name);
    }

    public handleColumnCheckboxChange(event, column) {
        column.hidden = !event.checked;
        this.handleColumnsChanged();
    }    

    public handleRecordCheckboxChange(event, record) {
        if (event.checked) {
            this.selectedRecords[record[this.trackByProperty]] = true;
        } else {
            delete this.selectedRecords[record[this.trackByProperty]];
            if (this.allRecordsSelected) {
                this.allRecordsSelected = false;
                this.selectedRecords = {};
            }
        }
        this.emitSelectionChange();
    }

    private emitSelectionChange() {
        this.selectedRecordCount = Object.keys(this.selectedRecords).length;
        this.selectionChange.emit({ 
            allRecordsSelected: this.allRecordsSelected,
            selectedRecords: Object.keys(this.selectedRecords)
        });
    }

    handleSearchKeyUp(event) {
        if (this.searchText != event.target.value) {
            this.searchText = event.target.value;
            this.searchTextChange.emit(this.searchText);
        }
    }

    handleSelectAllClick() {
        this.selectedRecords = {};
        this.selectedRecordCount = 0;

        // For a select all across pages, execute a new query that fetches just
        // the trackBy column from the query with no record limit

        this.pendingSelectAll = true;
        let spec  = Object.assign({}, this.results.spec, { selects: [ this.trackByProperty ] });
        delete spec.recordLimit;
        this.selectAllQuery.baseSpec = spec;
        this.selectAllQuery.ready = true;
    }

    handleSelectAllResults(results) {
        if (!results.pending) {
            results.records.forEach(record => this.selectedRecords[record[this.trackByProperty]] = true);
            this.selectAllQuery.ready = false;
            this.pendingSelectAll = false;
            this.emitSelectionChange();
        }
    }

    handleSelectPageClick() {
        this.records.forEach(record => this.selectedRecords[record[this.trackByProperty]] = true);
        this.emitSelectionChange();
    }

    handleClearSelectionClick() {
        this.selectedRecords = {};
        this.selectedRecordCount = 0;
        this.allRecordsSelected = false;
        this.emitSelectionChange();
    }

    handleExportToExcel() {
        if (this.query) {
            if (this.query instanceof StoreQuery) {
                this.query.createDataSet()
                    .subscribe(r => { console.log("DATA SET", r); this.exportDataSet(r.dataSetId); })
            } else if (this.query instanceof DataSetQuery) {
                this.exportDataSet(this.query.dataSetId);
            }
        }
    }

    private exportDataSet(dataSetId) {
        this.storeService.dispatchNotify({
            actionType: 'exportDataSet',
            payload: {
                request: {
                    dataSetId: dataSetId
                }
            }
        }).then(v => {
            this.snackbar.open('Download requested', 'Dismiss', { duration: 3000 });
        })
        .catch(v => {
            console.log("FAIL", v);
            this.snackbar.open('Download request failed', 'Dismiss', { duration: 3000 });
        });
    }
}
