import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AvailableDownloadsComponent } from "./availableDownloads.component";
import { MatSnackBarRef } from "@angular/material/snack-bar";
import { SimpleSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: 'download-requested',
    templateUrl: 'downloadRequested.html'
})
export class DownloadRequestedComponent {

    constructor(private dialog: MatDialog, private snackBar: MatSnackBarRef<SimpleSnackBar>) {
    }

    viewAvailableDownloads() {
        this.dialog.open(AvailableDownloadsComponent);
        this.snackBar.dismiss();
    }

    dismiss() {
        this.snackBar.dismiss();
    }
}