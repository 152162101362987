import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';

import * as Chart from 'chart.js';

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit, AfterViewInit {

    @Input()
    public data: any;

    @Input()
    public chartType: string;

    @Input()
    public options: any = {};

    @ViewChild('canvas')
    private canvas: ElementRef;

    private chart;

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        this.checkDrawChart();
    }

    ngAfterViewInit() {
        this.checkDrawChart();
    }

    checkDrawChart() {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }

        let canvas = this.canvas.nativeElement;
        console.log("CHART", this.chartType, this.data, this.options);
        this.chart = new Chart(canvas, {
            type: this.chartType,
            data: this.data,
            options: this.options
        });

    }

}
