import { Component, OnInit, Input } from '@angular/core';
import { AnimationBuilder } from '@angular/animations/src/animation_builder';
import * as easing from 'eases/expo-out';
import * as numeral from 'numeral';

@Component({
  selector: 'app-number-animator',
  templateUrl: './number-animator.component.html',
  styleUrls: ['./number-animator.component.css']
})
export class NumberAnimatorComponent implements OnInit {

  desiredValue = 0;
  
  @Input()
  set value(value) { 
    this.desiredValue = value;
    this.checkAnimation();
  };

  get value() { return this.desiredValue };

  @Input() 
  duration = 2000;

  @Input()
  format = '0.0a';

  displayValue = 0;
  formattedDisplayValue;
  startTime;
  startValue = 0;

  constructor() { 
  }

  public ngOnInit() {
    this.formatDisplayValue();
  }

  private tick() {
    let elapsed = Date.now() - this.startTime;
    if (elapsed >= this.duration) {
      this.displayValue = this.desiredValue;
      this.formatDisplayValue();
    } else {
      let v = elapsed  / this.duration;
      this.displayValue = ((this.desiredValue - this.startValue) * easing(v)) + this.startValue;
      this.formatDisplayValue();
      requestAnimationFrame(() => this.tick());
    }
  }

  private formatDisplayValue() {
    this.formattedDisplayValue = numeral(this.displayValue).format(this.format);
  }

  private checkAnimation() {
    if (typeof this.desiredValue != 'number') {
      this.desiredValue = 0;
    }
    
    if (this.desiredValue != this.displayValue) {
      this.startTime = Date.now();
      this.startValue = this.displayValue;
        requestAnimationFrame(() => this.tick());
    }
  }

}
