import { Component, Input, OnInit, OnChanges, ContentChild, TemplateRef, ElementRef, ViewChild } from "@angular/core";
import * as Hammer from 'hammerjs';

import {
    trigger,
    state,
    style,
    animate,
    transition
  } from '@angular/animations';
import { Logger } from "angular2-logger/core";

@Component({
    selector: 'record-swiper',
    templateUrl: './recordSwiper.html',
    styleUrls: [ './recordSwiper.scss' ],
    animations: [
        trigger('swipeTo', [
            state('left', style({
                transform: 'translateX(-100%)'
            })),
            state('middle', style({
                transform: 'translateX(0)'
            })),
            state('right', style({
                transform: 'translateX(100%)'
            })),
            transition('* => middle', animate('250ms ease-in')),
            transition('middle => *', animate('250ms ease-in'))
        ])
    ]    
})
export class RecordSwiperComponent implements OnInit, OnChanges {
    @Input()
    private records: any[] = [];

    @ContentChild(TemplateRef) 
    public template: TemplateRef<any>;

    @ViewChild('container')
    private container: ElementRef;

    private visiblePanelIndex = 0;
    private visibleRecordIndex = 0;
    
    windowRecordIndexes: number[] = [ -1, -1, -1 ];

    constructor(private logger: Logger) {
    }

    private handleSwipe(event) {

        // Propagation hack: if there is another record-swiper below this one that was the target 
        // of the event, then don't process it on this one. This allows correct handling of 
        // nested swipers.

        let element = event.target;
        do {
            if (element.parentElement && element.parentElement.localName == 'record-swiper' && element != this.container.nativeElement) {
                return;
            }
            element = element.parentElement;
        } while (element && element != this.container.nativeElement);

        if (!event.alreadyHandled) {
            this.logger.info('processing record swiper event', event, this);
            event.alreadyHandled = true;
            if (event.direction == Hammer.DIRECTION_LEFT) {
                if (this.visibleRecordIndex < this.records.length -1) {
                    this.visibleRecordIndex++;
                    this.populateIndexes();
                }
            } else if (event.direction == Hammer.DIRECTION_RIGHT) {
                if (this.visibleRecordIndex > 0) {
                    this.visibleRecordIndex--;
                    this.populateIndexes();
                }
            }
        }
    }

    public ngOnInit() {
        this.populateIndexes();
    }

    public ngOnChanges() {
        this.populateIndexes();
    }

    private populateIndexes() {
        if (this.records && this.records.length) {
            this.windowRecordIndexes = [ -1, -1, -1 ];
            for (let i = this.visibleRecordIndex - 1; i <= this.visibleRecordIndex + 1; i++) {
                if (i < 0) {
                    this.windowRecordIndexes[i + this.windowRecordIndexes.length] = -1;
                } else if (i >= this.records.length) {
                    this.windowRecordIndexes[i % 3] = -1;
                } else {
                    this.windowRecordIndexes[i % 3] = i;
                }
            }
            this.logger.info('visibleRecordIndex', this.visibleRecordIndex);
            this.logger.info('windowRecordIndexes', this.windowRecordIndexes);
        }
    }
}