import { Component, Input } from "@angular/core";

@Component({
    selector: 'image-gallery',
    templateUrl: './imageGallery.html',
    styleUrls: [ './imageGallery.scss' ]
})
export class ImageGalleryComponent {
    @Input()
    images: any[];
}