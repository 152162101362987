import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StoreQuery, StoreService, UtilityService } from "../../platform";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PagesService } from '../../pages/pages.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'create-claim',
    templateUrl: './createClaim.html',
    styleUrls: [ './createClaim.scss']
})
export class CreateClaimComponent implements OnInit {
    public clientId;
    public claimType;
    public spinner = false;

    claimForm: FormGroup;

    vendorQuery: StoreQuery;

    private validationResults: any = { client: {}, vendor: {}, subClient: {} };
    
    private destroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private _dialogRef: MatDialogRef<CreateClaimComponent>, 
        private _formBuilder: FormBuilder, 
        private _storeService: StoreService, 
        private _utilityService: UtilityService,
        private _router: Router,
        private pagesService: PagesService
    ) {
        this.claimForm = _formBuilder.group({
            clientId: ['', Validators.required ],
            subClientId: ['', Validators.required ],
            vendorId: [ undefined ],
            auditNumber: [ undefined ],
            savingsType: ['D', Validators.required ],
            savingsSubtype: ['FAC', Validators.required ],
            claimType: ['O', Validators.required ],
            originalSavingsId:  [ '', Validators.required ]
        });
        this.claimForm.valueChanges.subscribe(x => this.handleFormChange(x));

        pagesService.client.pipe(takeUntil(this.destroyed)).subscribe(client => {
            this.claimForm.get('clientId').setValue(client.clientId);
        });        
    }

    public ngOnInit() {
    }

    public ngOnDestroy() {
        this.destroyed.next(true);
    }

    save() {
        let payload: any = 

        this.spinner = true;
        this._storeService.dispatch({
            actionType: 'savings/createClaim',
            payload: { 
                clientId: this.claimForm.get('clientId').value,
                subClientId: this.claimForm.get('subClientId').value,
                savingsTypeCode: this.claimForm.get('savingsType').value,
                savingsSubtypeCode: this.claimForm.get('savingsSubtype').value,
                claimTypeCode: this.claimForm.get('claimType').value,
                auditNumber: this.claimForm.get('auditNumber').value,
                vendorId: this.claimForm.get('vendorId').value || 0
            }
        })
        .then((result) => {
            console.log(result);
            this.spinner = false;
            this._router.navigate(['/pages/savings/savingsDetail', result.payload.savingsId ]);
            this._dialogRef.close();
        })
        .catch(r => {
            this.spinner = false;
            console.log("ACTION FAILED");
        });
    }

    private handleFormChange(x) {
        let clientId = this.claimForm.get('clientId').value;
        if (this.clientId != clientId) {
            this.clientId = clientId;
            this.claimForm.get('subClientId').setValue(undefined);
        }
        this.claimType = this.claimForm.get('claimType').value;
        this.enableControls();
    }

    private enableControls() {
        let enabled = this.claimForm.get('claimType').value == 'O';

        this._utilityService.enableFormControls(this.claimForm, [ 'vendorId', 'savingsType', 'savingsSubtype' ], enabled);
        this._utilityService.enableFormControls(this.claimForm, [ 'originalSavingsId' ], !enabled);
    }

    private handleClientValidated(client) {
        this.validationResults.client = client;
    }

    private handleVendorValidated(vendor) {
        this.validationResults.vendor = vendor;
    }
 
    trackVendor(v) {
        return v.vendorId;
    }

    trackClient(c) {
        return c.clientId;
    }

    trackClaim(c) {
        return c.savingsId;
    }    

    trackSubClient(sc) {
        return sc.subClientsId;
    }   
}