import { NgZone, HostListener, Component, ViewChild, OnInit, OnDestroy, DoCheck } from '@angular/core';
import { Router, RouterOutlet, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Logger } from 'angular2-logger/core';

import { PagesService } from './pages.service';
import { SecurityService, Query, QueryService, StoreService, StoreQuery } from '../platform';
import { SelectClientComponent } from './selectClient.component';
import { CreateClaimComponent } from './createClaim/createClaim.component';
import { AvailableDownloadsComponent } from "./availableDownloads.component";
import { ObservableMedia } from "@angular/flex-layout";
import { LinkHelperService } from '../platform/link-helper.service';


@Component({
  selector: 'pages',
  templateUrl: './pages.html',
  styleUrls: [ './pages.scss' ]
})
export class Pages implements DoCheck, OnDestroy {

  private  menuItems: any[] = [
    { title: 'Dashboard', icon: 'home', route: 'dashboard' },
    { title: 'Data Import', icon: 'cloud_upload', route: 'dataImport' },
    { title: 'Sites', icon: 'room', route: 'sites' },
    { title: 'Documents', icon: 'book', route: 'documents' },
    { title: 'Audits', icon: 'playlist_add_check', route: 'audit' },
    { title: 'Savings', icon: 'attach_money', route: 'savings' },
    { title: 'Reports', icon: 'book', route: 'reports' },
    //{ title: 'Circuits', icon: 'settings_ethernet', route: 'circuits' },
    { title: 'Search', icon: 'search', route: 'search' },
    { title: 'Image Search', icon: 'image_search', route: 'search/imageSearch' },
    { title: 'Action Items', icon: 'assignment', route: 'actionItems'  },
    //{ title: 'Operations', icon: 'schedule', route: 'operations'},
    { title: 'System', icon: 'laptop', route: 'system', requiredRole: 'admin' },
    //{ title: 'Physical Audit', icon: 'cloud_upload', route: 'physicalAudit', requiredRole: 'beta' },
    //{ title: 'New Savings', icon: 'assignment', dialog: CreateClaimComponent, separator: true, requiredRole: 'beta' },
  ];

  @ViewChild(RouterOutlet)
  private routerOutlet: any;

  allowedMenuItems: any[];
  filterEnabled: boolean = false;
  refreshEnabled: boolean = true;
  sidebarMode = 'side';

  private dialogRef: any;
  private equipmentQuery: Query;
  private client: any;
  private clientSub: any;
  private scannerInputSub: any;
  private savingsAmount = 0;

  private savingsSummaryQuery: StoreQuery;

  constructor(
      private queryService: QueryService, 
      public securityService: SecurityService, 
      public pagesService: PagesService, 
      public linkHelperService: LinkHelperService,
      private router: Router, 
      public dialog: MatDialog, 
      private logger: Logger, 
      private zone: NgZone, 
      private activatedRoute: ActivatedRoute, 
      private MatSnackBar: MatSnackBar,
      private storeService: StoreService,
      private media: ObservableMedia) {
    var me = this;
    pagesService.initialize(router, securityService);
    me.equipmentQuery = queryService.createQuery();
    media.asObservable().subscribe(change => this.handleMediaChange(change));
    this.logger.info('media', this.media);

    this.savingsSummaryQuery = storeService.query();
    this.savingsSummaryQuery.baseSpec = {
        queryType: 'savings/savingsSummary'
    };
    this.savingsSummaryQuery.results.subscribe(r => {
        if (!r.pending && r.totalRecords > 0) {
            this.savingsAmount = r.records[0].savingsAmount
        }
    });

    linkHelperService.addLinkHandler("location", (obj, config) =>
        [ "/pages/sites/explore/location", obj.locationId ]);
    linkHelperService.addLinkHandler("savings", (obj, config) =>
        [ "/pages/savings/savingsDetail", obj.savingsId ]); 
 }

  public ngOnInit() {
    var me = this;

    me.equipmentQuery.results.subscribe(r => me.handleQueryResults(r));
    me.securityService.authenticationEvents.subscribe(event => this.handleSecurityEvent(event));
    me.clientSub = me.pagesService.client.subscribe(client => this.setClient(client));
    me.scannerInputSub = me.pagesService.scannerInput.subscribe(input => me.gotoEquipment(input));
    this.populateAllowedMenuItems();
  }

  public ngOnDestroy() {
    var me = this;
    me.clientSub.unsubscribe();
    me.scannerInputSub.unsubscribe();
  }

  @HostListener('document:contextmenu')
  handleContextMenu(event) {
    return window['allowContextMenu'] || false;
  }

  private setClient(client) {
    if (this.client && client && this.client.clientId == client.clientId) {
        return;
    }

    this.client = client;
    if (client) {
        this.savingsSummaryQuery.removeAllRefreshNotifications();
        this.savingsSummaryQuery.filters = { clientId: client.clientId };
        this.savingsSummaryQuery.addRefreshNotification({
            'type': 'savingsSummaryUpdated'
        });  
        this.savingsSummaryQuery.ready = true;
        this.storeService.dispatch({
            actionType: 'setApplicationUserSetting',
            payload: {
                data: {
                    applicationUserId: this.securityService.getUser().applicationUserId,
                    settingName: 'defaultClientId',
                    settingValue: client.clientId
                }
            }
        }).then(() => null);
    }
  }

  handleMediaChange(change) {
    this.sidebarMode = this.media.isActive('xs') ? 'over' : 'side';
  }

  handleSelectClient() {
    var me = this;
    me.dialogRef = me.dialog.open(SelectClientComponent);
  }

  handleViewAvailableDownloads() {
    var me = this;
    me.dialogRef = me.dialog.open(AvailableDownloadsComponent);
  }  

  handleSecurityEvent(event) {
    this.populateAllowedMenuItems();
  }

  private populateAllowedMenuItems() {
    var me = this;
    me.allowedMenuItems = me.menuItems.filter(item => !item.requiredRole || me.securityService.userHasRole(item.requiredRole))
  }

  public ngDoCheck() {
    var me = this;
    if (me.routerOutlet.isActivated) {
      let component = me.routerOutlet.component;
      me.filterEnabled = (typeof component.filterEnabled !== 'undefined');
      me.refreshEnabled = (typeof component.refresh !== 'undefined');
      me.logger.log("checking buttons: filter=" + me.filterEnabled + ", refresh=" + me.refreshEnabled, component);
    }
  }

  handleFilterClicked() {
    var me = this,
        component = me.routerOutlet.component;

    if (component && typeof component.filterEnabled !== 'undefined') {
      component.filterEnabled = !component.filterEnabled;
    }
  }

  handleRefreshClicked() {
    var me = this,
        component = me.routerOutlet.component;

    if (component && typeof component.refresh !== 'undefined') {
      component.refresh();
    }
  }  

  gotoEquipment(assetTagNumber) {  
    var me = this;
    me.MatSnackBar.open("Searching for asset tag " + assetTagNumber, "Dismiss", { duration: 3000 });
    me.equipmentQuery.execute({
      queryType: 'model/equipment',
      filters: {
        clientId: me.client.clientId,
        assetTagNumber: assetTagNumber
      }
    });
  }

  private handleQueryResults(results) {
    var me = this;

    if (!results.pending) {
      if (results.totalRecords > 0) {
        if (results.records[0].equipmentId) {
          this.router.navigate([ 'sites/explore/equipment', results.records[0].equipmentId ], { relativeTo: me.activatedRoute });
        }
      } else {
        me.MatSnackBar.open("Could not find asset tag", "Dismiss", { duration: 3000 });
      }
    }
  }

  private createDialog(dialog) {
    this.dialogRef = this.dialog.open(dialog);
  }

  logout() {
    this.securityService.logout();
    // this is a bit of a cheat. Need to do it this way in order to 
    // clear the router cache among other things
    location.assign("/");
  }
}
