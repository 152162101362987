import { Component, OnInit, ViewChild, AfterViewInit, EventEmitter, Output, OnDestroy, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityService } from '../services';
import { DisplayImageComponent } from '../components/displayImage.component';

declare var ImageCapture;

@Component({
    selector: 'app-capture-image-component',
    templateUrl: './capture-image-component.component.html',
    styleUrls: ['./capture-image-component.component.css']
})
export class CaptureImageComponentComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('video')
    private video: any;

    @ViewChild('canvas')
    private canvas: any;

    @ViewChild('selectPhoto')
    private selectPhoto: any;

    @ViewChild(DisplayImageComponent)
    private displayImageComponent: DisplayImageComponent;

    @Input()
    public showSpinner = false;

    @Input()
    public set annotations(annotations) { 
        this.image.annotations = annotations;
        this.displayImageComponent.buildDiagramComponents();
    }

    @Output()
    private newImage: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    private imageClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    private cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _selectedDevice: MediaDeviceInfo;
    public get selectedDevice() { return this._selectedDevice; };
    public set selectedDevice(d: MediaDeviceInfo) { this.selectDevice(d); }
    MediaDeviceInfo;
    public availableDevices: MediaDeviceInfo[];

    private cameraMessage;
    private stream: MediaStream;

    public snapshotTaken = false;

    private imageCapture;
    private blob: Blob;
    private imageURL: any;
    private image: any;

    private mediaConstraints = {
        video: {
            width: { min: 1280, ideal: 1920 },
            height: { min: 1080, ideal: 1080 },
            facingMode: "environment"
        },
        audio: false
    };

    constructor(private domSanitizer: DomSanitizer,
        private securityService: SecurityService) { }

    ngOnInit() {
        let mediaDevices = navigator.mediaDevices;
        this.securityService.log("IN INIT");

        if (mediaDevices) {
            this.securityService.log("have media devices");
            if (mediaDevices.enumerateDevices) {
                this.securityService.log("have enumerate");
                mediaDevices.enumerateDevices().then(devices => this.setAvailableDevices(devices.filter(d => d.kind == "videoinput")));
            } else if (mediaDevices.getUserMedia) {
                this.securityService.log("have getUserMedia");
                mediaDevices.getUserMedia(this.mediaConstraints).then(stream => this.setStream(stream));
            }
        }
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        if (this.stream) {
            this.stream.getTracks().forEach(t => t.stop());
        }
    }

    private setAvailableDevices(devices: MediaDeviceInfo[]) {
        devices.forEach(d => this.securityService.log("available device " + d.kind + "," + d.label + ": " + JSON.stringify(d)));
        this.availableDevices = devices;
        if (devices.length) {
            let selectedDevice = devices.findIndex(d => d.label && !!d.label.match(/back/i));
            if (selectedDevice < 0) {
                selectedDevice = 0;
            }
            this.selectDevice(devices[selectedDevice]);
        }
    }

    private stopStreams() {
        if (this.stream) {
            this.stream.getTracks().forEach(t => t.stop());
        }
    }

    private selectDevice(device: MediaDeviceInfo) {
        let constraints = {
            video: {
                deviceId: { exact: device.deviceId },
                height: 1080,
                width: 1920
            }
        };
        let getUserMedia: any = navigator.mediaDevices.getUserMedia;
        let mediaDevices: any = navigator.mediaDevices;
        this.stopStreams();
        mediaDevices.getUserMedia(constraints)
            .then(stream => this.setStream(stream))
            .catch(err => this.cameraMessage = err.name + ':' + err.message);
    }

    private setStream(stream) {
        this.stopStreams();

        this.stream = stream;

        // this works on chrome on windows
        //this.video.nativeElement.src = window.URL.createObjectURL(stream);
        this.securityService.log('setting stream: ' + JSON.stringify(stream));
        this.video.nativeElement.srcObject = stream;
        if (typeof ImageCapture != 'undefined') {
            this.imageCapture = new ImageCapture(stream.getVideoTracks()[0]);
        }
        // something else?
        //this.video.nativeElement.src = stream;
        this.video.nativeElement.play();
    }

    public takePicture() {
        this.image = null;
        this.blob = null;
        if (this.imageCapture) {
            this.securityService.log("TAKE PICTURE: CASE 1");
            this.imageCapture.takePhoto().then(blob => this.setBlob(500, 500, blob));
        } else {
            this.securityService.log("TAKE PICTURE: CASE 2");
            let trackSettings = this.stream.getVideoTracks()[0].getSettings();
            let height = trackSettings.height;
            let width = trackSettings.width;
            this.securityService.log("height: " + height + ", width: " + width);
            this.canvas.nativeElement.width = width;
            this.canvas.nativeElement.height = height;
            let context = this.canvas.nativeElement.getContext('2d');
            context.drawImage(this.video.nativeElement, 0, 0, width, height);
            this.canvas.nativeElement.toBlob(blob => this.setBlob(width, height, blob), "image/png");
        }
        this.snapshotTaken = true;
    }

    private setBlob(height, width, blob) {
        this.blob = blob;
        this.imageURL = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        this.image = {
            height: height,
            width: width,
            imageUrl: this.imageURL
        };        
        this.newImage.next(this.blob);
    }

    public clickSelectFile() {
        this.selectPhoto.nativeElement.click();
    }

    public selectFile(event) {
        let file = this.selectPhoto.nativeElement.files.item(0);

        if (FileReader) {
            let fileReader = new FileReader();
            fileReader.onload = () => {
                let x: any = fileReader.result;
                this.setBlob(500, 500, new Blob([new Uint8Array(x)]));
                this.snapshotTaken = true;
            }
            fileReader.readAsArrayBuffer(file);
        }
    }
}
