import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../appMaterial.module';

import { QueryChartComponent } from './components/queryChart.component';
import { QueryService } from './services/query.service';
import { QueryResult } from './services/queryResult.model';
import { ThemeService } from './services/theme.service';
import { SecurityService } from './services/security.service';
import { UtilityService } from './services/utility.service';
import { NotificationService } from './services/notification.service';
import { QueryComponent } from './components/query.component';
import { PagerComponent } from './components/pager.component';
import { QueryPagerComponent } from './components/queryPager.component';
import { DiagramComponent } from './components/diagram.component';
import { FilterListComponent } from './components/filterList.component';
import { QueryFilterListComponent } from './components/queryFilterList.component';
import { GoogleMapComponent, GoogleMapMarkersComponent, GoogleMapService, GoogleMapInfoWindowComponent, GoogleMapInfoWindowService } from './components/maps';
import { QueryTableComponent, QueryTableColumnComponent, QueryTableCellComponent, SimpleCellEditComponent } from './components/queryTable';
import { AuthGuard } from './guards/auth.guard';
import { ActionGeneratorDirective } from './actions';
import { DisplayImageComponent } from './components/displayImage.component';
import { RecordSwiperComponent, SwiperPanelComponent, DisplayImageDialogComponent, SimpleDiagramComponent, QuerySelectionDialogComponent, ImageGalleryComponent } from './components';
import { AnalyzeDataSetComponent } from './analyze-data-set/analyze-data-set.component';
import { AnalyzeDataSetTableComponent } from './analyze-data-set/analyze-data-set-table/analyze-data-set-table.component';
import { AnalyzeDataSetChartComponent } from './analyze-data-set/analyze-data-set-chart/analyze-data-set-chart.component';
import { AnalyzeDataSetPropertyListComponent } from './analyze-data-set/analyze-data-set-property-list/analyze-data-set-property-list.component';
import { DragDropDirective } from './drag-drop.directive';
import { AnalyzeDataSetMapComponent } from './analyze-data-set/analyze-data-set-map/analyze-data-set-map.component';
import { DisableAutocompleteDirective } from './disable-autocomplete.directive';
import { CaptureImageComponentComponent } from './capture-image-component/capture-image-component.component';
import { NumberAnimatorComponent } from './number-animator/number-animator.component';
import { LeafletMapComponent } from './leaflet-map/leaflet-map.component';
import { VennDiagramComponent } from './venn-diagram/venn-diagram.component';
import { QueryAutocompleteFormFieldComponent } from './query-autocomplete-form-field/query-autocomplete-form-field.component';
import { QueryAutocompleteDialogComponent } from './query-autocomplete-dialog/query-autocomplete-dialog.component';
import { QueryAutocompleteLabelComponent } from './query-autocomplete-label/query-autocomplete-label.component';
import { ChartComponent } from './chart/chart.component';
import { ChevronGroupComponent } from './chevron-group/chevron-group.component';
import { ChevronComponent } from './chevron/chevron.component';
import { ActionFileDropAreaComponent } from './action-file-drop-area/action-file-drop-area.component';
import { FilterPromptComponent } from './filter-prompt/filter-prompt.component';
import { DataSetViewComponent } from './data-set-view/data-set-view.component';
import { DataSetTableViewComponent } from './data-set-view/data-set-table-view/data-set-table-view.component';
import { DataSetChartViewComponent } from './data-set-view/data-set-chart-view/data-set-chart-view.component';
import { DataSetMapViewComponent } from './data-set-view/data-set-map-view/data-set-map-view.component';
import { DataSetViewWidgetComponent } from './widgets/data-set-view-widget/data-set-view-widget.component';
import { WidgetViewComponent } from './widgets/widget-view/widget-view.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { InitialsPipe } from './initials.pipe';
import { FromNowPipe } from './from-now.pipe';
import { ContainsAnyPipe } from './contains-any.pipe';
import { ContainsNonePipe } from './contains-none.pipe';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { DataSetVennViewComponent } from './data-set-view/data-set-venn-view/data-set-venn-view.component';
import { ReportResultsViewComponent } from './report-results-view/report-results-view.component';


@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule
  ],
  declarations: [
    QueryChartComponent,
    QueryComponent,
    DiagramComponent,
    GoogleMapComponent,
    GoogleMapMarkersComponent,
    GoogleMapInfoWindowComponent,
    QueryTableComponent,
    QueryTableColumnComponent,
    QueryTableCellComponent,
    PagerComponent,
    QueryPagerComponent,
    FilterListComponent,
    QueryFilterListComponent,
    SimpleCellEditComponent,
    ActionGeneratorDirective,
    RecordSwiperComponent,
    SwiperPanelComponent,
    DisplayImageDialogComponent,
    SimpleDiagramComponent,
    QuerySelectionDialogComponent,
    DisplayImageComponent,
    ImageGalleryComponent,
    AnalyzeDataSetComponent,
    AnalyzeDataSetTableComponent,
    AnalyzeDataSetChartComponent,
    AnalyzeDataSetPropertyListComponent,
    DragDropDirective,
    AnalyzeDataSetMapComponent,
    DisableAutocompleteDirective,
    CaptureImageComponentComponent,
    NumberAnimatorComponent,
    LeafletMapComponent,
    VennDiagramComponent,
    QueryAutocompleteFormFieldComponent,
    QueryAutocompleteDialogComponent,
    QueryAutocompleteLabelComponent,
    ChartComponent,
    ChevronGroupComponent,
    ChevronComponent,
    ActionFileDropAreaComponent,
    FilterPromptComponent,
    DataSetViewComponent,
    DataSetTableViewComponent,
    DataSetChartViewComponent,
    DataSetMapViewComponent,
    DataSetViewWidgetComponent,
    WidgetViewComponent,
    ErrorDialogComponent,
    InitialsPipe,
    FromNowPipe,
    ContainsAnyPipe,
    ContainsNonePipe,
    FilePreviewComponent,
    DataSetVennViewComponent,
    ReportResultsViewComponent
    ],
  exports: [
    QueryComponent,
    QueryChartComponent,
    DiagramComponent,
    GoogleMapComponent,
    GoogleMapMarkersComponent,
    GoogleMapInfoWindowComponent,
    QueryTableComponent,
    QueryTableColumnComponent,
    PagerComponent,
    QueryPagerComponent,
    FilterListComponent,
    QueryFilterListComponent,
    ActionGeneratorDirective,
    RecordSwiperComponent,
    SwiperPanelComponent,
    DisplayImageDialogComponent,
    SimpleDiagramComponent,
    DisplayImageComponent,
    ImageGalleryComponent,
    AnalyzeDataSetComponent,
    DragDropDirective,
    DisableAutocompleteDirective,
    CaptureImageComponentComponent,
    NumberAnimatorComponent,
    LeafletMapComponent,
    VennDiagramComponent,
    QueryAutocompleteFormFieldComponent,
    QueryAutocompleteDialogComponent,
    QueryAutocompleteLabelComponent,
    ChartComponent,
    ChevronComponent,
    ActionFileDropAreaComponent,
    FilterPromptComponent,
    DataSetViewComponent,
    WidgetViewComponent,
    ErrorDialogComponent,
    InitialsPipe,
    FromNowPipe,
    ContainsAnyPipe,
    ContainsNonePipe,
    FilePreviewComponent,
    ReportResultsViewComponent
  ],
  entryComponents: [ 
    SimpleCellEditComponent,
    DisplayImageDialogComponent,
    QuerySelectionDialogComponent,
    ErrorDialogComponent
  ],
  providers: [
  ]
})
export class PlatformModule { }

