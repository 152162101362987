import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { QueryResult } from './queryResult.model';
import { SecurityService } from './security.service';
import { Query } from './query.model';
import { map } from 'rxjs/operators';

@Injectable()
export class QueryService {

  constructor(private securityService: SecurityService) {
  }

  public getQueryResult(query : any) : Observable<QueryResult>
  {
    console.log("GET QUERY RESULT: ", query);
    return this.securityService.post("/sandbox/ws/query/execute", JSON.stringify(query))
      .pipe(map(this.createQueryResult));
  }

  public createDataSet(query : any) : Observable<any>
  {
    return this.securityService.post("/sandbox/ws/query/createDataSet", JSON.stringify(query));
  }  
  
  private createQueryResult(queryResult: any) {
    queryResult.pending = false;
    return queryResult;
  }

  public createQuery(): Query {
    return new Query(this);
  }
  
}