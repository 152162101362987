import { Injectable } from '@angular/core';

import 'style-loader!./theme.scss';

@Injectable()
export class ThemeService {
  private colors = [
    'rgb(91,155,213)', //blue
    'rgb(237,125,49)',
    'rgb(165,165,165)',
    'rgb(255,192,0)',
    'rgb(68,114,196)',
    'rgb(112,173,71)'
    // 'rgba(255, 99, 132, 0.8)',
    // 'rgba(255, 206, 86, 0.8)',
    // 'rgba(75, 192, 192, 0.8)',
    // 'rgba(153, 102, 255, 0.8)',
    // 'rgba(255, 159, 64, 0.8)',
    // 'rgba(54, 162, 235, 0.8)'
  ];


  public getChartColorForId(id) {
    return this.colors[id % this.colors.length];
  }

  public getChartColors() {
      return this.colors;
  }

}