import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';

import { Logger } from 'angular2-logger/core';

import { QueryResult } from '../services/queryResult.model';
import { UtilityService } from '../services/utility.service';

@Component({
    selector: 'query-filter-list',
    templateUrl: './queryFilterList.html'
})
export class QueryFilterListComponent {

    @Input()
    queryResult: QueryResult;

    @Input() 
    querySummary: string;

    @Input()
    sortProperty: string;

    @Input() 
    labelProperty: string;

    @Input() 
    totalProperty: string;

    @Input()
    totalFormat: string;

    @Input()
    top: number = 10;

    @Input() 
    title: string;

    @Input()
    filters: any = {};

    @Output()
    private changeFilter: EventEmitter<any> = new EventEmitter<any>();

    records: any[] = [];
    selected: any[] = [];

    constructor(private logger: Logger, private utilityService: UtilityService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        var me = this;
        if (changes.queryResult && me.queryResult) {
            me.records = me.queryResult.summaries[me.querySummary];
        }
        if (me.records) {
            me.selected = me.records
                .filter(r => me.isFiltered(r))
                .map(r => r[me.labelProperty]);
        }
    }

    private isFiltered(record) {
        var me = this;
        
        if (me.filters[me.labelProperty]) {
            let filteredValue = me.filters[me.labelProperty].equal;

            if (filteredValue == record[me.labelProperty]) {
                return true;
            }
            if (Array.isArray(filteredValue)) {
                return filteredValue.indexOf(record[me.labelProperty]) >= 0;
            }
        }
        return false;
    }
    
    handleChange(event) {
        this.changeFilter.emit(event);
    }
}
