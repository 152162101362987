import { Component, Input, Output, EventEmitter, HostListener, ViewChild, OnChanges, ElementRef, OnInit, SimpleChanges, AfterViewChecked } from '@angular/core';
import { Logger } from 'angular2-logger/core';
import { UtilityService} from '../services';
import { DiagramComponent } from "./diagram.component";
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'simple-diagram',
    styleUrls: [ 'simpleDiagram.scss' ],
    templateUrl: './simpleDiagram.html',
})
export class SimpleDiagramComponent implements OnChanges, OnInit {

    @Input()
    diagramId: number;

    @Input()
    diagram: any;

    @Input()
    private componentTypeStyles = {};
    
    @ViewChild('diagram')
    private diagramComponent: DiagramComponent;


    @Output()
    private componentClicked: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: HttpClient, private logger: Logger, private utilityService: UtilityService) {
    }

    ngOnInit() {
        this.fetchDiagram();
    }

    ngOnChanges(changes) {
        if (changes.diagramId) {
            this.fetchDiagram();
        }
        if (changes.diagram) {
            this.diagram.redraw();
        }
    }

    private fetchDiagram() {
        this.http.get("/sandbox/ws/diagram/" + this.diagramId)
            .subscribe((diagran: any) => {
                this.diagram.redraw();
            });
    }

    public drawComponents() {
        for (let c of this.diagram.diagramComponents.sort((a, b) => this.compareComponents(a, b))) {
            this.diagramComponent.drawComponent(c);
        }
    }

    private compareComponents(a, b) {
        var me = this;
        let val = (a.level || 0) - (b.level || 0);

        if (!val) {
            // TODO: move this to DiagramComponent
            // if on the same level and one is under the mouse, put it later
            // so that the highlight gets rendered later
            //
            // if (me.componentContainsMouse(a)) {
            //     val = 1;
            // } else if (me.componentContainsMouse(b)) {
            //     val = -1;
            // }
        }
        
        if (!val) {
            val = (a.diagramComponentId || 0) - (b.diagramComponentId || 0);
        }

        return val;
    }



}
