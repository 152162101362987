import { Component, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Logger } from 'angular2-logger/core';
import * as _ from "lodash";

import { UtilityService, Query, QueryService, StoreService, StoreQuery } from '../../platform';
import { PagesService } from '..';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DownloadRequestedComponent } from "../downloadRequested.compponent";
import { MatDialog, MatTabGroup } from '@angular/material';
import { EquipmentEditComponent } from './index';

@Component({
    selector: 'location-detail',
    templateUrl: './locationDetail.html',
    styleUrls: [ './locationDetail.scss' ]
})
export class LocationDetailComponent implements OnChanges {
    @Input()
    private locationId: any;

    @ViewChild('tabGroup')
    private tabGroup: MatTabGroup;

    equipmentQuery: StoreQuery;
    locationQuery: StoreQuery;
    childLocationQuery: StoreQuery;

    private client: any;
    private destroyed: Subject<boolean> = new Subject<boolean>();
    
    constructor(
            private storeService: StoreService, 
            private activatedRoute: ActivatedRoute, 
            private router: Router, 
            private queryService: QueryService, 
            private logger: Logger, 
            private utilityService: UtilityService,
            private pagesService: PagesService,
            private snackbar: MatSnackBar,
            private dialog: MatDialog) {
        var me = this;
        me.equipmentQuery = storeService.query();
        me.equipmentQuery.baseSpec = {
            queryType: 'model/equipment',
            filters: {
                attachedToEquipmentId: { isNull: true } 
            },
            sorts: [ { property: 'equipmentName', direction: 'ascending'}]
        };
        this.locationQuery = storeService.query();
        this.locationQuery.baseSpec = {
            queryType: 'model/locations',
            sorts: [ { property: 'locationName', direction: 'ascending'}]
        };

        me.childLocationQuery = storeService.query();
        me.childLocationQuery.baseSpec = {
            queryType: 'model/locations',
            sorts: [ { property: 'locationName', direction: 'ascending'}]
        };
    }

    ngOnInit() {
        var me = this;
        this.pagesService.client.pipe(takeUntil(this.destroyed)).subscribe(client => me.handleChangeClient(client));
    }
    
    ngOnChanges(changes) {
        if (changes.locationId) {
            this.equipmentQuery.addRefreshNotification({
                type: 'actionCompleted',
                'payload.actionTypeName': 'physicalAudit/createEquipment',
                'payload.payload.locationId': this.locationId
            });
            this.refresh();
            // change focus back to general tab
            this.tabGroup.selectedIndex = 0;
        }
    }

    ngOnDestroy()
    {
        this.destroyed.next(true);
        this.destroyed.complete();
    }

    handleChangeClient(client) {
        var me = this;
        me.client = client;
        me.refresh();
    }
    
    refresh() {
        if (this.locationId && this.client) {
            this.locationQuery.filters = {
                locationId: this.locationId
            };
            this.locationQuery.ready = true;

            this.equipmentQuery.filters = { 
                locationId: this.locationId, 
            };
            this.equipmentQuery.ready = true;

            this.childLocationQuery.filters = {
                parentLocationId: this.locationId
            };
            this.childLocationQuery.ready = true;
        }
    }

    handleLocationRowClick(location) {
        this.router.navigate([ '../../location', location.locationId ], { relativeTo: this.activatedRoute });
    }

    handleEquipmentRowClick(equipment) {
        this.router.navigate([ '../../equipment', equipment.equipmentId ], { relativeTo: this.activatedRoute });
    }

    handleEquipmentSurvey(event, equipment) {
        event.stopPropagation();
        this.router.navigate([ '/pages/physicalAudit/equipmentAudit', equipment.equipmentId ]);
    }

    createAuditTemplate() {
        this.storeService.dispatch({
            actionType: 'sites/createLocationAuditTemplate',
            payload: {
                locationId: this.locationId
            }
        }).then(
            x => this.snackbar.openFromComponent(DownloadRequestedComponent, { duration: 10000 }),
            x => this.snackbar.open("Failed to request download", "Dismiss", { duration: 10000 })
        );
    }      

    addEquipment() {
        this.dialog.open(EquipmentEditComponent, { 
            disableClose: true, 
            data: { locationId: this.locationId } 
        });
    }
}
