import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { StoreService } from '../store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SecurityService, UtilityService, NotificationService } from '../services';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-report-results-view',
    templateUrl: './report-results-view.component.html',
    styleUrls: ['./report-results-view.component.css']
})
export class ReportResultsViewComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    public reportRequestId;

    @Input()
    public toolbar = true;

    reportRequestQuery: any;
    reportRequest: any;

    private destroyed: Subject<boolean> = new Subject<boolean>();
    reportType: any;
    selectedView: any;
    views: any[];
    notificationSubscription: any;
    lastReportRequestId;

    defaultViews = `[{
        name: "Table",
        "default": true,
        widget: 'dataSet',
        config: { 
            viewType: 'table',
            dataSetId: \${context.dataSets.main}
        }        
    }]`;

    constructor(
        private storeService: StoreService,
        private securityService: SecurityService,
        private utilityService: UtilityService,
        private route: ActivatedRoute,
        private notificationService: NotificationService
    ) {
        this.reportRequestQuery = storeService.query();
        this.reportRequestQuery.baseSpec = {
            queryType: 'reports/reportRequests'
        };

        this.reportRequestQuery.results.pipe(takeUntil(this.destroyed)).subscribe(r => {
            if (r.totalRecords == 1) {
                this.reportRequest = r.records[0];

                // NOTE: this needs to only happen when the results are complete. When the reportType comes 
                // the query it is evaluated against the context and the context needs to have things like
                // the data set ids in it

                this.securityService.get("/sandbox/ws/report/type/" + this.reportRequest.reportTypeName)
                    .subscribe(r => {

                        this.reportType = r;
                        let context = { dataSets: {} };

                        this.reportRequest.dataSets.forEach(ds => context.dataSets[ds.name] = ds.dataSetId);

                        this.views = utilityService.evaluateExpressionsInContext(r.views ||this.defaultViews, context);
                        if (this.views && this.views.length > 0) {
                            this.selectedView = this.views[0];
                        }
                    });
            }
        });

        this.route.params.subscribe(params => {
            let reportRequestId = params['reportRequestId'];
            if (reportRequestId) {
                this.reportRequestId = reportRequestId;
                this.refresh();
            }
        });

    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.destroyed.next(true);
    }

    ngOnChanges(changes) {
        if (changes.reportRequestId) {
            this.refresh();
        }
    }

    private refresh() {
        if (this.reportRequestId) {
            this.reportType = null;
            this.reportRequestQuery.filters = {
                reportRequestId: this.reportRequestId
            };
            this.reportRequestQuery.ready = true;

            if (this.lastReportRequestId != this.reportRequestId) {
                if (this.notificationSubscription) {
                    this.notificationSubscription.unsubscribe();
                }

                // subscribe to changes on this report request
                this.notificationSubscription = this.notificationService.notify({
                    type: 'reportRequestUpdated',
                    'payload.reportRequestId': this.reportRequestId
                }).pipe(takeUntil(this.destroyed)).subscribe(r => this.refresh());
            }
            this.lastReportRequestId = this.reportRequestId;
        }
    }

}
