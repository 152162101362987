import { Component, Input, Output, OnInit, AfterViewInit, OnChanges, SimpleChanges, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { GoogleMapService } from './googleMap.service';
import { GoogleMapInfoWindowService } from './googleMapInfoWindow.service';

import { Logger } from 'angular2-logger/core';

@Component({
    selector: 'google-map-markers',
    template: '<ng-content></ng-content>', 
    providers: [ GoogleMapInfoWindowService ]
})
export class GoogleMapMarkersComponent implements OnInit, OnChanges {

    @Input()
    public data: any[];

    @Input()
    public icon: string;

    @Input()
    public zIndex: number = 0;

    @Output()
    markerSelected: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    trackBy: string;

    private map: any;
    private google: any;
    private markers: any;
    private internalId: number = 1; // counter to be used if no trackBy provided

    constructor(private elementRef: ElementRef, 
        private googleMapService: GoogleMapService, 
        private googleMapInfoWindowService: GoogleMapInfoWindowService, 
        private logger: Logger, private zone: NgZone) {
    }

    ngOnInit() {
        var me = this;
        me.markers = {};
        me.googleMapService.getMap().subscribe(function (data) {
            me.map = data.map;
            me.google = data.google;
            me.renderNewMarkers();
        });
    }

    ngAfterViewInit() {
        this.googleMapInfoWindowService.setContentElement(this.elementRef.nativeElement);
    }

    ngOnChanges(changes: SimpleChanges) {
        var me = this;
        me.logger.debug("checking changes for markers, data.length=" + me.data.length, changes, me.map);
        if (changes['data'] && me.map) {
            me.removeOldMarkers();
            me.renderNewMarkers();
        }
    }

    private removeOldMarkers() {
        var me = this;
        var key;
        
        // set to delete
        for (key in me.markers) { 
            me.markers[key].keep = false;
        }

        // figure out which to keep
        for (let i = 0; i < me.data.length; i++) {
            key = me.getTrackingKey(me.data[i]);
            if (me.markers[key]) {
                me.markers[key].keep = true;
            }
        }

        // delete any that weren't marked to be kept
        for (key in me.markers) { 
            if (!me.markers[key].keep) {
                me.logger.log("deleting old marker: " + JSON.stringify(key));
                me.markers[key].setMap(null);
                delete me.markers[key];
            }
        }

    }

    private renderNewMarkers() {
        var me = this;
        if (me.data) {
            for (let i = 0; i < me.data.length; i++) {
                let key = me.getTrackingKey(me.data[i]);
                me.logger.log("check render new marker: " + JSON.stringify(key));
                if (!me.markers[key]) {
                    me.logger.log("rendering new marker: " + JSON.stringify(key));
                    me.renderMarker(me.data[i]);
                }
            }
        }
    }

    private renderMarker(obj) {
        var me = this;
        var options = { position: { lat: obj.lat, lng: obj.lon }, map: me.map, zIndex: me.zIndex };

        if (me.icon) {
            options['icon'] = me.icon;
        }
        me.logger.log("render marker", options);
        var marker = new me.google.maps.Marker(options);
        marker.addListener('click', function() {
            me.zone.run(function() {
                me.googleMapInfoWindowService.clearInfoWindow();
                me.markerSelected.emit({ obj: obj, map: me.map, marker: marker });
                me.googleMapInfoWindowService.openInfoWindow(marker);
            });
        });
        me.markers[me.getTrackingKey(obj)] = marker;
    }

    getTrackingKey(obj)
    {
        let me = this;
        let key = me.trackBy;

        if (!key) {
            key = '_googleMapMarker';
            if (!(obj[key])) {
                obj[key] = me.internalId++;
            }
        }
        return obj[key];
    }

}
