import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';

import { Logger } from 'angular2-logger/core';

import { UtilityService } from '../services/utility.service';

@Component({
    selector: 'filter-list',
    templateUrl: 'filterList.html',
    styleUrls: [ 'filterList.scss' ]
})
export class FilterListComponent {

    @Input()
    records: any[];

    // property name to be returned in the changeFilter event
    @Input()
    eventProperty: string;

    // property name to be returned in the changeFilter event and used in selected.
    // defaults to same value as labelProperty
    @Input()
    valueProperty: string;

    // record property to sort the list on
    @Input()
    sortProperty: string;

    // record property to use as a label
    @Input() 
    labelProperty: string;

    // record property to use to display total
    @Input() 
    totalProperty: string;

    // format for the total field
    @Input()
    totalFormat: string;

    @Input()
    top: number = 10;

    @Input() 
    title: string;

    @Input()
    private selected: any[] = [];

    @Output()
    private changeFilter: EventEmitter<any> = new EventEmitter<any>();

    showMore: boolean = false;
    initialRecords: any[];
    sortedRecords: any[];

    constructor(private logger: Logger, private utilityService: UtilityService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        var me = this;
        if (!me.initialRecords) {
            me.initialRecords = me.records;
        }
        if (me.records) {
            me.sortedRecords = me.records
                .sort((a, b) => me.compareRecords(a, b))
                .filter(r => r[me.totalProperty] > 0);
        }
    }

    private compareRecords(a, b) {
        var me = this;

        // descending order

        if (a[me.totalProperty] < b[me.totalProperty]) {
            return 1;
        }
        if (a[me.totalProperty] > b[me.totalProperty]) {
            return -1;
        }
        return 0;
    }

    private handleChange(event) {
        let me = this,
            checkbox = event.source,
            index = me.selected.indexOf(checkbox.value);

        if (!checkbox.checked && index >= 0) {
            me.selected.splice(index,1);
        } else if (checkbox.checked && index < 0) {
            me.selected.push(checkbox.value);
        }
        me.changeFilter.emit({ propertyName: me.eventProperty || me.labelProperty, operator: 'equal', values: me.selected.length > 0 ? me.selected : null });
    }

    private formatTotal(value) {
        var me = this;
        switch (me.totalFormat) {
        case 'currency':
            return me.utilityService.formatCurrency(value);
        case 'integer':
            return me.utilityService.formatInteger(value);
        default:
            if (me.totalFormat) {
                return me.utilityService.numeral(value).format(me.totalFormat);
            }
            return value;
        }
    }

    private isSelected(record) {
        return (this.selected.indexOf(record[this.valueProperty || this.labelProperty]) >= 0);
    }    
}
