import { Component, OnInit, Input, ViewChild, forwardRef, ElementRef, EventEmitter, Output, ContentChild, TemplateRef } from '@angular/core';
import { StoreService, StoreQuery } from '../store';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroupDirective, NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatInput } from '@angular/material';
import { Subject } from 'rxjs';
import { QueryAutocompleteDialogComponent, AbstractQueryAutocomplete } from '../query-autocomplete-dialog/query-autocomplete-dialog.component';

@Component({
    selector: 'query-autocomplete-form-field',
    templateUrl: './query-autocomplete-form-field.component.html',
    styleUrls: ['./query-autocomplete-form-field.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QueryAutocompleteFormFieldComponent),
            multi: true
        }
    ]
})
export class QueryAutocompleteFormFieldComponent extends AbstractQueryAutocomplete implements OnInit, ControlValueAccessor {

    @Input()
    public required: boolean;

    @Output()
    public valueChange: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(MatInput)
    private input: MatInput;

    @ViewChild('input')
    private inputElement: ElementRef;

    @ViewChild(QueryAutocompleteDialogComponent)
    private dialog: QueryAutocompleteDialogComponent;

    @ContentChild(TemplateRef)
    public displayTemplate: TemplateRef<any>;

    isDisabled = false;

    public selectedRecord: any;

    private onChangeHandler;
    private onTouchedHandler;
    private value: any;
    public formGroup: FormGroup;
    public displayPager = false;

    constructor(
        private formBuilder: FormBuilder
    ) {
        super();
    }

    ngOnInit() {
        if (!this.displayProperty) {
            this.displayProperty = this.valueProperty;
        }
        this.formGroup = this.formBuilder.group({
            inputValue: this.required ? ['', Validators.required ] : ['']
        });
    }

    private handleChangeRecord(record) {
        this.selectedRecord = record;
        this.writeValue(record[this.valueProperty]);
    }

    handleInputKeyPress(event) {
        this.dialog.openDialog(event.char);
    }

    handleInputClick(event) {
        this.dialog.openDialog(this.input.value);
    }

    writeValue(value: any): void {
        console.log("WRITE VALUE", value, this.value, this.valueProperty, this.selectedRecord);
        let control = this.formGroup.get('inputValue');

        if (this.selectedRecord && value == this.selectedRecord[this.valueProperty]) {
            control.setValue(this.selectedRecord[this.displayProperty]);
        }

        if (this.value != value) {
            this.value = value;
            this.valueChange.next(value);
            if (this.onChangeHandler) {
                console.log("CHANGE HANDLER EXECUTE", this.onChangeHandler, value);
                this.onChangeHandler(value);
            }
        }
    }

    registerOnChange(fn: any): void {
        this.onChangeHandler = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouchedHandler = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
