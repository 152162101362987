import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { StoreService, StoreQuery, UtilityService } from '../../../platform';

@Component({
    selector: 'app-site-detail-overview',
    templateUrl: './site-detail-overview.component.html',
    styleUrls: ['./site-detail-overview.component.css']
})
export class SiteDetailOverviewComponent implements OnInit, OnChanges {

    @Input()
    locationId: any;

    invoiceQuery: StoreQuery;
    inventoryQuery: StoreQuery;

    constructor(
        private storeService: StoreService,
        public utilityService: UtilityService) {

        this.invoiceQuery = storeService.query();
        this.invoiceQuery.baseSpec = {
            queryType: 'sites/locationLineItemSummary',
            filters: {
                n: 10
            }
        };

        this.inventoryQuery = storeService.query();
        this.inventoryQuery.baseSpec = {
            queryType: 'sites/locationInventorySummary',
            filters: {
                n: 10
            }
        };

    }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        if (changes.locationId) {
            this.invoiceQuery.filters = { locationId: this.locationId };
            this.invoiceQuery.ready = true;

            this.inventoryQuery.filters = { locationId: this.locationId };
            this.inventoryQuery.ready = true;            
        }
    }
}
