import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";

import { StoreService, StoreQuery } from "../platform";

@Component({
    selector: 'available-downloads',
    templateUrl: './availableDownloads.html'
})
export class AvailableDownloadsComponent implements OnInit {

    @ViewChild('downloadIframe')
    private downloadIframe: any;

    downloadsQuery: StoreQuery;
    
    constructor(private storeService: StoreService) {
        this.downloadsQuery = storeService.query();
        this.downloadsQuery.baseSpec = {
            queryType: 'general/availableDownloads',
            recordLimit: 50
        };
        this.downloadsQuery.ready = true;
    }

    ngOnInit() {
        this.downloadsQuery.addRefreshNotification( { type: 'availableDownloadChanged' });
    }

    ngOnDestroy() {
        this.downloadsQuery.destroy();
    }

    private download(fileId) {
        this.downloadIframe.nativeElement.src = '/sandbox/ws/file/' + fileId + "?attachment=true"
    }
    
}