import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { SecurityService } from '../services';

@Component({
    selector: 'app-action-file-drop-area',
    templateUrl: './action-file-drop-area.component.html',
    styleUrls: ['./action-file-drop-area.component.css']
})
export class ActionFileDropAreaComponent implements OnInit, OnChanges {

    @Input()
    public action: any;

    @Input()
    public clientId: number;

    @Input()
    public showCompleted = false;

    @Output()
    public actionComplete: EventEmitter<any> = new EventEmitter<any>();

    private fileUploader: FileUploader = new FileUploader({});

    public failedFileItems: FileItem[] = [];
    public completedFileItems: FileItem[] = [];
    public uploadingFileItems: FileItem[] = [];
    public pendingFileItems: FileItem[] = [];
    public fileActionResults: { fileItem: FileItem, result: any }[] = [];

    constructor(
        private securityService: SecurityService
    ) {
        this.fileUploader.onBuildItemForm = (fileItem: FileItem, form: any): any => {
            this.addActionToForm(form);
            return { fileItem, form };
        };

        this.fileUploader.onAfterAddingFile = f => this.handleQueueChanged();
        this.fileUploader.onCancelItem = f => this.handleQueueChanged();
        this.fileUploader.onCompleteItem = (f: FileItem, response, status)  => {
            this.handleQueueChanged();
            console.log("RESPONSE: ", response);
            let json = JSON.parse(response);
            if (json.length == 1) {
                json = json[0];
            }
            
            this.fileActionResults.push({ fileItem: f, result: json});
            if (!json.failed) {
                this.actionComplete.emit(json);
            }
        };
        this.fileUploader.onProgressItem = f => this.handleQueueChanged();
        this.fileUploader.onErrorItem = (f, response, status) => {
            this.handleQueueChanged();
        };
        this.setFileUploaderOptions();
    }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        if (changes.clientId) {
            this.setFileUploaderOptions();
        }
    }

    private setFileUploaderOptions() {
        let options: any = {
            autoUpload: true,
            url: '/sandbox/ws/action/upload'
        };
       if (this.clientId) {
           options.url = options.url + '?clientId=' + this.clientId;
       }
       this.fileUploader.setOptions(options);
       // changing options clears the authToken - set it
       this.fileUploader.authToken = this.securityService.getAuthToken();        
    }

    private handleQueueChanged() {
        this.failedFileItems = this.fileUploader.queue.filter(f => f.isError || f.isCancel);
        this.completedFileItems = this.fileUploader.queue.filter(f => f.isSuccess);
        this.uploadingFileItems = this.fileUploader.queue.filter(f => f.isUploading);
        this.pendingFileItems = this.fileUploader.queue.filter(f => f.isReady);
    }

    private addActionToForm(form: FormData) {
        form.append("action", JSON.stringify(this.action));
    }

    handleResponse() {
    }

    public reset() {
        this.fileUploader.clearQueue();
        this.handleQueueChanged();
    }

    public handleRemoveCompletedItem(item) {
        this.fileUploader.queue.splice(this.fileUploader.queue.indexOf(item), 1);
        this.completedFileItems.splice(this.completedFileItems.indexOf(item), 1);
    }

    public getCompletedActionResults() {
        return this.completedFileItems.map(f => this.fileActionResults.find(r => r.fileItem == f).result);
    }

}
