import { Component, Input, ViewContainerRef, OnChanges, ViewChild } from "@angular/core";
import {
    trigger,
    state,
    style,
    animate,
    transition
  } from '@angular/animations';

@Component({
    selector: 'swiper-panel',
    templateUrl: './swiperPanel.html',
    styleUrls: [ './swiperPanel.scss' ],
})
export class SwiperPanelComponent implements OnChanges {
    @Input()
    private record: any;

    @Input() 
    private template: any;

    @ViewChild('container', { read: ViewContainerRef }) 
    private container: ViewContainerRef;

    constructor(private viewContainerRef: ViewContainerRef) {
    }

    public ngOnChanges() {
        if (this.template) {
            this.container.createEmbeddedView(this.template, { record: this.record });
        }
    }
}