import { Routes, RouterModule }  from '@angular/router';
import { Pages } from './pages.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthGuard } from '../platform/guards/auth.guard';

// noinspection TypeScriptValidateTypes

export const routes: Routes = [
    {
    path: 'pages',
    component: Pages,
    canActivate: [ AuthGuard ],

    children: [
        { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
        { path: 'dashboard', loadChildren: './dashboard/dashboardPage.module#DashboardPageModule' },
        { path: 'savings', loadChildren: './savings/savingsPage.module#SavingsPageModule' },
        { path: 'audit', loadChildren: './audit-page/audit-page.module#AuditPageModule' },
        { path: 'reports', loadChildren: './reports-page/reports-page.module#ReportsPageModule' },
        { path: 'circuits', loadChildren: './circuits-page/circuits-page.module#CircuitsPageModule'},
        { path: 'documents', loadChildren: './documents/documentsPage.module#DocumentsPageModule' },
        { path: 'actionItems', loadChildren: './actionItems/actionItemsPage.module#ActionItemsPageModule' },
        { path: 'operations', loadChildren: './operations/operationsPage.module#OperationsPageModule' },
        { path: 'physicalAudit', loadChildren: './physicalAudit/physicalAuditPage.module#PhysicalAuditPageModule' },
        { path: 'search', loadChildren: './search/searchPage.module#SearchPageModule' },
        { path: 'system', loadChildren: './system/systemPage.module#SystemPageModule' },
        { path: 'files', loadChildren: './files-page/files-page.module#FilesPageModule' },
        { path: 'misc', loadChildren: './misc/miscPage.module#MiscPageModule' },
        { path: 'dataImport', loadChildren: './data-import-page/data-import-page.module#DataImportPageModule' },
        { path: 'sites', loadChildren: './sites/sitesPage.routing#SitesPageRoutingModule' },
        { path: '**', redirectTo: '' }
    ]
  }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ]
})
export class PagesRoutingModule {}
