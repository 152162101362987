import { NgZone, Injectable } from '@angular/core';
import { RoutesRecognized  } from '@angular/router';
import { BehaviorSubject, Subject }    from 'rxjs';

import { Logger } from 'angular2-logger/core';

import { Query, QueryService, SecurityService, AuthenticationEvent, UtilityService, StoreService, StoreQuery } from '../platform';

@Injectable()
export class PagesService {
    public state;
    public clients: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public client: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public roles: BehaviorSubject<any> = new BehaviorSubject<string[]>([]);
    public scannerInput: Subject<any> = new Subject<any>();
    public settings: BehaviorSubject<any> = new BehaviorSubject<any>({});


    private router;
    private securityService: SecurityService;
    private clientsQuery: Query;
    private roleQuery: StoreQuery;
    private settingsQuery: StoreQuery;
    private selectedClient: any = null;
    private currentClientId: number = null;
    private availableClients: any[] = null;

    private scannedInput: string = null;
    private scanning: boolean = false;
  
      constructor(
          private queryService: QueryService, 
          private storeService: StoreService,
          private logger: Logger, 
          private utilityService: UtilityService, 
          private zone: NgZone
        ) {
        this.zone.runOutsideAngular(() => {
            document.addEventListener('keypress', event => {
              this.handleKeyPress(event);
            });
          });
      
        this.clientsQuery = new Query(queryService, { queryType: 'user-clients' } );
        this.clientsQuery.results.subscribe(r => this.handleClientQueryResults(r));
        this.clientsQuery.execute();

        this.roleQuery = storeService.query();
        this.roleQuery.baseSpec = {
            queryType: 'client-roles'
        };
        this.roleQuery.results.subscribe(r => this.handleRoleQueryResults(r));

        this.settingsQuery = storeService.query();
        this.settingsQuery.baseSpec = {
            queryType: 'application-settings'
        };
        this.settingsQuery.results.subscribe(r => this.handleSettingsQueryResults(r));        
    }

    public handleClientQueryResults(results) {

        if (results.pending) {
            return;
        }

        let clients = results.records;
        this.availableClients = clients;
        this.clients.next(clients);

        console.log("CLIENT QUERY RESULTS: ", clients, this.selectedClient, this.currentClientId);

        if (!this.selectedClient || this.selectedClient.clientId != this.currentClientId) {
            let client = clients.find(c => c.clientId == this.currentClientId);

            // if the currently selected client isn't in the new list, select 
            // the default
            if (!client) {
                console.log("COULDN'T FIND", this.currentClientId, clients);
                client = clients.find(c => c.defaultFlag == 'Y');
            }

            // if none are marked as default, then pick the first one
            if (!client) {
                console.log("COULDN'T FIND DEFAULT", clients);
                client = clients[0];
            }
            
            this.setClient(client);
        }
    }

    public initialize(router, securityService) {
        this.router = router;
        this.securityService = securityService;
        this.securityService.authenticationEvents.subscribe(function(event: AuthenticationEvent) {
            if (event.status == 'login') {
                this.clientsQuery.execute();
            }
        })
    }

    public setClient(client) {

        console.log("SET CLIENT", client);

        // if we are clearing the client
        if (!client) {
            this.selectedClient = null;
            this.currentClientId = null;
            this.client.next(null);
            this.roles.next(null);
            return;
        }

        // setting ready to false and then true forces the query to get 
        // executed exactly once

        this.currentClientId = client.clientId;
        this.selectedClient = client;

        this.roleQuery.ready = false;
        this.roleQuery.filters = { clientId: client.clientId };
        this.roleQuery.ready = true;

        this.settingsQuery.ready = false;
        this.settingsQuery.filters = { clientId: client.clientId };
        this.settingsQuery.ready = true;        

        this.client.next(this.selectedClient);
    }

    public setClientById(clientId) {
        console.log("SET CLIENT BY ID", clientId);
        this.currentClientId = clientId;
        if (!this.selectedClient || this.selectedClient.clientId != clientId) {
            if (this.availableClients) {
                this.setClient(this.availableClients.find(c => c.clientId == clientId));
            }
        }
    }

    public handleRoleQueryResults(results) {

        if (results.pending) {
            return;
        }

        let roles = results.records
            .filter(r => r.clientId == this.currentClientId)
            .map(r => r.applicationRoleName);
        this.roles.next(roles);
    }

    public handleSettingsQueryResults(results) {
        if (!results.pending) {
            let settings = {};
            console.log("SETTINGS RESULTS", results);
            results.records.forEach(s => {
                settings[s.settingName] = eval("(" + s.settingValue + ")");
            });
            this.settings.next(settings);
        }
    }

  // NOTE: Hostlistener would be cleaner, but it triggers change detection on every call
  // which has performance implications. Until angular provides a way to decorate
  // this to ignore change detection, I manually add a listener to the document

  //@HostListener('document:keydown', ['$event'])
  private handleKeyPress(event: KeyboardEvent) {
    if (!this.scanning && event.key == "`") {
      // begin barcode scan
      this.scanning = true;
      this.scannedInput = "";
    } else if (this.scanning && (event.key == "`" || event.keyCode == 13)) {
      // finish scanning
      this.scanning = false;
      this.zone.run(() => this.scannerInput.next(this.scannedInput));

    } else if (this.scanning) {
        this.scannedInput = this.scannedInput + event.key;
    }
    if (event.key == "`") {
      event.preventDefault();
    }
  }

    
}
