import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { DataSetQuery } from '../../store/data-set-store';
import { StoreService } from '../../store';
import { QueryResult, ThemeService, UtilityService } from '../../services';
import * as Chart from 'chart.js';
import { takeUntil, find } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LinkHelperService } from '../../link-helper.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-data-set-chart-view',
    templateUrl: './data-set-chart-view.component.html',
    styleUrls: ['./data-set-chart-view.component.css']
})
export class DataSetChartViewComponent implements OnInit, OnDestroy {

    @Input()
    dataSetId: number;

    @Input()
    viewConfig: any;

    @ViewChild('chartCanvas')
    private chartCanvas: any;

    public dataSetQuery: DataSetQuery;
    chart: any;
    results: QueryResult;
    chartReady: boolean;
    destroyed = new Subject<boolean>();
    dataSets = [];

    constructor(
        private storeService: StoreService,
        private themeService: ThemeService,
        private utilityService: UtilityService,
        private linkHelperService: LinkHelperService,
        private router: Router
    ) {
        this.dataSetQuery = storeService.dataSetQuery();
        this.dataSetQuery.recordLimit = 50;
        this.dataSetQuery.results.pipe(takeUntil(this.destroyed)).subscribe(results => {
            this.results = results;
            this.displayChart();
        });
    }

    ngOnInit() {
        this.displayChart();
    }

    ngOnChanges(changes) {
        if (changes.dataSetId) {
            this.refresh();
        }
    }

    ngOnDestroy() {
        this.destroyed.next(true);
    }

    refresh() {
        if (this.viewConfig && this.dataSetId) {
            let selects = [...this.viewConfig.labels, ...this.viewConfig.values].map(s => { return { property: s } });
            if (this.viewConfig.dataSets) {
                selects.push({ property: this.viewConfig.dataSets });
            }

            // if selects are specified, add any where the property wasn't already added
            // because of the  data or label properties

            if (this.viewConfig.selects) {
                let add = [];
                this.viewConfig.selects.forEach(s => {
                    if (!selects.find(f => f.property == s.property)) {
                        add.push(s);
                    }
                });
                add.forEach(a => selects.push(a));
            }

            this.dataSetQuery.dataSetId = this.dataSetId;
            this.dataSetQuery.selects = selects;
            this.dataSetQuery.sorts = this.viewConfig.sorts;
            if (this.viewConfig.recordLimit) {
                this.dataSetQuery.recordLimit = this.viewConfig.recordLimit;
            }
            this.dataSetQuery.ready = true;
        } else {
            this.dataSetQuery.ready = false;
        }
    }

    public displayChart() {

        if (!this.chartCanvas) {
            return;
        }

        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
            let canvas = this.chartCanvas.nativeElement;
            canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
        }

        let chartType = this.viewConfig.chartType || 'bar';

        let records = this.results.records;
        let columnNames = this.results.columns.map(c => c.name);

        this.chartReady = records.length > 0;

        console.log("RESULTS", this.results);
        if (records) {
            // use the first dimension in the list as the label
            // use all measures in the list as the data

            let labelColumn = this.results.columns.find(c => c.name == this.viewConfig.labels[0]);
            let dataColumns = this.results.columns.filter(c => this.viewConfig.values.find(name => c.name == name));

            console.log("CHART", labelColumn, dataColumns);

            var labelValues = this.utilityService.distinctValues(records.map(r => r[labelColumn.name]));

            var data = {
                labels: labelValues.map(l => this.formatValue(l || 'N/A', labelColumn)),
                datasets: []
            };

            if (this.viewConfig.dataSets) {

                // if the chart's data sets are created based on the value of a property
                // in the data set

                // find the distinct set of values
                // excludes null and undefined values

                let dataSetNames = records.reduce((a, c) => {
                    let value = c[this.viewConfig.dataSets];
                    if (value != undefined && value != null && a.indexOf(value) < 0) {
                        return [value, ...a];
                    } else {
                        return a;
                    }
                }, []);

                for (let i = 0; i < dataSetNames.length; i++) {
                    let dataset: any = {
                        label: dataSetNames[i],
                        data: this.buildDataSetData(records, this.viewConfig.dataSets, dataSetNames[i], this.viewConfig.values[0], labelColumn.name, labelValues)
                    }
                    this.augmentDataSet(dataset, chartType, i, labelColumn.hints);
                    data.datasets.push(dataset);
                }
            } else {

                // if the chart's data sets are created from one or more properties
                // in the data set, with the labels coming from the names of
                // the properties in the data set

                for (let i = 0; i < dataColumns.length; i++) {
                    let c = dataColumns[i];
                    let dataset: any = {
                        label: c.label,
                        data: records.map(r => r[c.name])
                    };
                    this.augmentDataSet(dataset, chartType, i, labelColumn.hints);
                    data.datasets.push(dataset);
                }
            }

            let options: any = {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: { enabled: false },
                onClick: (event, array) => this.handleChartClick(event, array)
            };

            let stacked = !!this.viewConfig.stacked;
            if (chartType == 'horizontalBar') {
                options.scales = {
                    xAxes: [{
                        ticks: {
                            callback: (label) => this.formatValue(label, dataColumns[0])
                        },
                        stacked: stacked
                    }],
                    yAxes: [ {
                        stacked: stacked
                    }]
                };
            }
            if (chartType == 'bar') {
                options.scales = {
                    yAxes: [{
                        ticks: {
                            callback: (label) => this.formatValue(label, dataColumns[0])
                        },
                        stacked: stacked
                    }],
                    xAxes: [ {
                        stacked: stacked
                    }]
                };
            }            

            if (chartType == 'pie') {
                options.pieceLabel = {
                    render: (args) => this.formatValue(args.value, dataColumns[0])
                };
            }
            this.dataSets = data.datasets;
            this.chart = new Chart(this.chartCanvas.nativeElement, {
                type: chartType,
                data: data,
                options: options
            });
        }
    }

    private augmentDataSet(dataset, chartType, index, hints) {
        if (chartType != 'pie') {
            dataset.backgroundColor = this.themeService.getChartColorForId(index);
        } else {
            dataset.backgroundColor = this.themeService.getChartColors();
        }

        dataset.linkType = this.linkHelperService.getLinkTypeFromHints(hints);
    }

    private buildDataSetData(records, dataSetColumnName, dataSetValue, dataColumnName, labelColumnName, labelValues)
    {
        let data = [];
        for (let i = 0; i < labelValues.length; i++) {
            let record = records.find(r => r[dataSetColumnName] == dataSetValue && r[labelColumnName] == labelValues[i]);
            data.push(record ? record[dataColumnName] : null);
        }
        return data;
    }

    private formatValue(value, column) {
        if (column) {
            if (column.dataFormat == "date") {
                return this.utilityService.formatDate(value);
            }

            if (column.dataFormat == "currency") {
                return this.utilityService.formatCurrency(value);
            }

            if (column.dataType == "number" && column.dataFormat) {
                return this.utilityService.formatNumber(value, column.dataFormat);
            }
        }
        return value;
    }

    private handleChartClick(event, array) {
        let linkType = this.dataSets[array[0]._datasetIndex].linkType;

        if (linkType) {
            let record = this.results.records[array[0]._index];
            let location = this.linkHelperService.getLinkRoute(linkType, record);
            console.log("LOCATION", location);
            if (location) {
                this.router.navigate(location);
            }
        }
    }
}
