import { Component } from '@angular/core';
import { MatDialogRef} from '@angular/material';

import { PagesService } from './pages.service';

@Component({
  selector: 'select-client',
  templateUrl: './selectClient.html',
  styleUrls: [ './selectClient.scss' ]
})
export class SelectClientComponent {

  constructor(
    public pagesService: PagesService, 
    private  dialogRef: MatDialogRef<SelectClientComponent>) {
  }

  selectClient(client) {
      this.pagesService.setClient(client);
  }

}
