import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'initials'
})
export class InitialsPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!value) {
            return value;
        }
        
        var tmp = value.replace(/[^A-Z]*([A-Z]).*([A-Z])[^A-Z]*$/, '$1$2');
        if (tmp == value) {
            tmp = value.substring(0, 1).toUpperCase();
        }
        return tmp;
    }

}
