import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chevron',
  templateUrl: './chevron.component.html',
  styleUrls: ['./chevron.component.scss']
})
export class ChevronComponent implements OnInit {

    @Input()
    public first = false;

    constructor() { }

  ngOnInit() {
  }

}
