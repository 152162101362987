import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Logger } from 'angular2-logger/core';

import { UtilityService, Query, QueryService } from '../../platform';

@Component({
    selector: 'address-detail',
    templateUrl: './addressDetail.html'
})
export class AddressDetailComponent implements OnChanges {
    @Input()
    private addressId: any;

    locationQuery: Query;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private queryService: QueryService, private logger: Logger) {
        var me = this;
        me.locationQuery = new Query(queryService);
    }

    public ngOnChanges(changes) {
        var me = this;
        if (changes.addressId) {
            me.refresh();
        }
    }

    refresh() {
        var me = this;
        if (me.addressId) {
            me.locationQuery.execute({
                queryType: 'sites/addressLocations',
                filters: { addressId: me.addressId, parentSameAddressFlag: 'N' }
            });
        }
    }

    handleRowClick(location) {
        var me = this;
        console.log(location);
        me.router.navigate(['../../location', location.locationId], { relativeTo: me.activatedRoute });
    }

}
