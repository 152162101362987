import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { StoreQuery, StoreRestQuery, StoreService } from '../../platform';

@Component({
  selector: 'equipment-explorer',
  styleUrls: [ './equipmentExplorer.scss' ],
  templateUrl: './equipmentExplorer.html'
})
export class EquipmentExplorerComponent implements OnInit, OnDestroy {

    @Input()
    private locationId;

    pathQuery: StoreRestQuery;
    lastPathEntry: any;

    private params: any;
    private subscriptions: any[] = []; 
    
    constructor(private route: ActivatedRoute, private router: Router, private storeService: StoreService) {
        this.pathQuery = this.storeService.restQuery();
    }

     ngOnInit() {
        var me = this;
        
        this.subscriptions.push(this.pathQuery.results.subscribe(path  => 
            this.lastPathEntry = (path && path.length > 0 ? path[path.length - 1] : null)));

        this.subscriptions.push(this.route.params.subscribe(
            function(params) 
            {
                me.params = params;
                if (params.equipmentId) {
                    me.pathQuery.path = '/sandbox/ws/path/equipment/' + params.equipmentId;
                } else if (me.params.locationId) {
                    me.pathQuery.path = '/sandbox/ws/path/location/' + params.locationId;
                } else if (me.params.addressId) {
                    me.pathQuery.path = '/sandbox/ws/path/address/' + params.addressId;
                }
                me.pathQuery.ready = true;
            }));
     }

     ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
     }

     ngOnChanges(changes) {
         if (changes.locationId) {
             this.pathQuery.path = '/sandbox/ws/path/location/' + changes.locationId;
         }
     }

    private handlePathClick(pathEntry) {
        var me = this,
            type, id;

        if (pathEntry.locationId) {
            type = 'location';
            id = pathEntry.locationId;
        } else if (pathEntry.addressId) {
            type = 'address';
            id = pathEntry.addressId;
        } else if (pathEntry.equipmentId) {
            type = 'equipment';
            id = pathEntry.equipmentId;
        }
        me.router.navigate([ '../..',  type, id ], { relativeTo: me.route });
    }
 }


