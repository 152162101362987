import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PlatformModule } from '../platform/platform.module';
import { AppMaterialModule } from '../appMaterial.module';

import { SiteListComponent } from './site-list/site-list.component';
import { ReportViewComponent } from './report-view/report-view.component';

import { RouterModule } from '@angular/router';
import { CircuitListComponent } from './circuit-list/circuit-list.component';
import { FileCardComponent } from './file-card/file-card.component';
import { EquipmentListComponent } from './equipment-list/equipment-list.component';
import { LocationListComponent } from './location-list/location-list.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PlatformModule,
        AppMaterialModule,
        RouterModule
    ],
    exports: [
        SiteListComponent,
        ReportViewComponent,
        CircuitListComponent,
        FileCardComponent,
        LocationListComponent,
        EquipmentListComponent
    ],
    declarations: [
        SiteListComponent,
        ReportViewComponent,
        CircuitListComponent,
        FileCardComponent,
        EquipmentListComponent,
        LocationListComponent
    ]
})
export class CommonComponentsModule { }
