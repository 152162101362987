import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { StoreService } from '../../store';
import { DataSetQuery } from '../../store/data-set-store';
import { first, filter, mergeMap, map } from 'rxjs/operators';
import { SecurityService } from '../../services/security.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-data-set-table-view',
    templateUrl: './data-set-table-view.component.html',
    styleUrls: ['./data-set-table-view.component.css']
})
export class DataSetTableViewComponent implements OnInit, OnChanges {
    @Input()
    dataSetId: number;

    @Input()
    viewConfig: any;

    public dataSetQuery: DataSetQuery;
    reportQuery: any;

    constructor(
        private storeService: StoreService,
        private securityService: SecurityService,
        private router: Router
    ) {
        this.dataSetQuery = storeService.dataSetQuery();
    }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        if (changes.dataSetId) {
            this.refresh();
        }
    }

    refresh() {
        if (this.dataSetId) {
            this.dataSetQuery.dataSetId = this.dataSetId;
            this.dataSetQuery.sorts = this.viewConfig.sorts;
            this.dataSetQuery.selects = this.viewConfig.selects;
            this.dataSetQuery.recordLimit = this.viewConfig.recordLimit || 50;
            this.dataSetQuery.ready = true;
        } else {
            this.dataSetQuery.ready = false;
        }
    }
}
