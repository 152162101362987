import { Injectable, NgZone } from '@angular/core';

import { AsyncSubject, Observable } from 'rxjs';

import * as GoogleMapsLoader from 'google-maps';

@Injectable()
export class GoogleMapService {

    private mapSubject: AsyncSubject<any> = new AsyncSubject<any>();

    public map: any;
    public google: any;
    public bounds: any;

    constructor(private zone: NgZone) {
    }

    public load(callback) {
        var me = this;
        GoogleMapsLoader.KEY = 'AIzaSyBggJNC706pvaLSM4hN0h1Zsy4paLoc_Zw';
        GoogleMapsLoader.load(function (google) {
            me.zone.run(function() {
                me.google = google;
                me.map = callback.call(this, google);
                me.mapSubject.next({ map: me.map, google: me.google });
                me.mapSubject.complete();
                me.fitBounds();
            });
        });
    }

    public getMap() {
        return this.mapSubject;
    }

    public fitBounds() {
        var me = this;

        if (me.map && me.bounds) {
            me.map.fitBounds(me.bounds);
        }
    }

    public setBounds(bounds: any) {
        this.bounds = bounds;
    }
}
