import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'containsAny'
})
export class ContainsAnyPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!value) {
            return false;
        }
        return !!args.find(a => value.find(v => v == a));
    }

}
