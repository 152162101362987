import { 
    Directive,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    HostListener
} from '@angular/core';

import { Action } from '.';

@Directive({
    selector: '[actionGenerator]'
})
export class ActionGeneratorDirective {

    @Input()
    private actionType: string;

    @Input()
    private actionProperty: string;
    
    @Input()
    private actionData: any;

    @Output()
    private actions = new EventEmitter<Action>();

    constructor(private elementRef: ElementRef) {
    }

    @HostListener('change')
    onChange(event) {
        this.actions.emit({ 
            actionType: this.actionType, 
            payload: {
                data: this.actionData,
                properties: [ this.actionProperty ]
             }
        });
    }
}
