import { ErrorHandler, Injectable, Injector } from '@angular/core';

import * as stacktrace from 'stacktrace-js';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  public url: string;

  constructor(private injector: Injector) { }

  handleError(error) {
    var http = this.injector.get(HttpClient);
    http.post(this.url, error.toString() + "\n" + error.stack).subscribe(r => r);
    throw error;
  }
  
}