import { BehaviorSubject } from 'rxjs';
import { QueryResult } from './queryResult.model';
import { QueryService } from './query.service';

export class Query {

    public results: BehaviorSubject<QueryResult>;
    
    public querySpec: any;
    public beginRecord: number;
    public recordLimit: number;
    
    private queryService: QueryService;
    private lastRequest: number = 0;
    private lastResult = new QueryResult();

    private additionalFilters: any = {};

    private pendingSpec: any;

    constructor(queryService: QueryService, querySpec?: any, recordLimit?: number) {
        this.queryService = queryService;
        this.querySpec = querySpec;
        this.recordLimit = recordLimit;
        this.results = new BehaviorSubject<QueryResult>(this.lastResult);
    }

    public execute(newQuerySpec?: any, options?: any) {
        let me = this,
            request = ++me.lastRequest,
            querySpec;

        if (newQuerySpec) {
            me.querySpec = newQuerySpec;
        }

        querySpec = Object.assign({}, me.querySpec);
        
        querySpec.filters = Object.assign({}, querySpec.filters, me.additionalFilters);

        if (!querySpec.queryType) {
            return;
        }

        if (me.beginRecord) {
            querySpec.beginRecord = me.beginRecord
        }
        if (me.recordLimit) {
            querySpec.recordLimit = me.recordLimit;
        }
        
        // only change the query result to pending if a) the options are set so that 
        // it should and b) the last result wasn't a pending result (creating a new
        // pending result can cause multiple check issues with angular)

        if ((!options || options.pending) && (!me.lastResult || !me.lastResult.pending)) {
            me.lastResult = Object.assign({}, me.lastResult, { pending: true });
            me.results.next(me.lastResult);
        }

        // Push this until the next tick. That way, if there are multiple
        // changes to the query the query will only get executed once

        this.pendingSpec = querySpec;
        setTimeout(() => this.executePending(), 0);
    }

    private executePending()
    {
        let request = this.lastRequest;
        if (this.pendingSpec) {
            let spec = this.pendingSpec;
            this.queryService.getQueryResult(this.pendingSpec).subscribe(r => {
                console.log("execute pending request", request, this.lastRequest);
                if (this.lastRequest == request) {
                    this.lastResult = r;
                    // add the query spec to the result
                    this.results.next(Object.assign({}, r, { spec: spec }));
                }
            });
            this.pendingSpec = null;
        }
    }

    public filter(filter: any) {
        this.additionalFilters = filter;
        this.execute();
    }

    public destroy() {
        this.results.complete();
    }
}