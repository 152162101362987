import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

export class AppHammerGestureConfig extends HammerGestureConfig  {
   events: string[] = ['pinch pan']; //necessary for ng2 to recognize "pinch pan" event
   buildHammer(element: HTMLElement) {
    let options: any = { domEvents: true };

    // fix so that vertical scrolling works in divs with swipe enabled
    if (!(element instanceof HTMLCanvasElement))  {
        options.touchAction = 'pan-y';
    }
    let mc = new Hammer(element, options);
      mc.get('pinch').set({enable: true});
      mc.get('pinch').recognizeWith(mc.get('pan'));
      return mc;
   }
}
