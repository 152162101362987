import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, NgZone, AfterViewChecked, ViewChild } from '@angular/core';
import { GoogleMapService } from './googleMap.service';
import { UtilityService } from '../../services';
import { Logger } from 'angular2-logger/core';

@Component({
  selector: 'google-map',
  styleUrls: ['./googleMap.scss'],
  templateUrl: './googleMap.html',
  providers: [GoogleMapService]
})
export class GoogleMapComponent implements AfterViewChecked {

  @Input()
  options: any = {};

  @Input()
  initialBoundsObjects: any[];

  @Output()
  private boundsChanged: EventEmitter<any> = new EventEmitter<any>();

  private pendingBoundsObjects: any;
  private setBoundsComplete: boolean = false;
  private lastFitBounds: any;
  private lastBounds: any;

  private markerObjects: any[] = [];

  private receivedBoundsChanged: boolean = false;

  private google: any;
  private map: any;

  @ViewChild('map')
  private divElement: any;

  constructor(private googleMapService: GoogleMapService, private logger: Logger, private zone: NgZone, private utilityService: UtilityService) {
  }

  ngAfterViewInit() {
    var me = this;

    me.googleMapService.load(function(google) {
        me.logger.debug("loaded google api", me, google);
        me.handleLoad(google);
        
        // this callback needs to return the newly created map to the googleMapService
        return me.map;
    });
  }

  private handleLoad(google) {
      var me = this;

      me.logger.debug("maps API load complete");
      console.log(me.divElement);

      me.google = google;

      // default to zoomed out all the way

      me.map = new google.maps.Map(me.divElement.nativeElement, 
        Object.assign({ 
          zoom: 3,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          center: new google.maps.LatLng(42.877742, -97.380979)
         }, me.options))

      
      me.checkInitialBounds();
      me.checkPendingBounds();

      me.map.addListener('idle', () => me.zone.run(function() {
        me.handleMapIdle();
      }));
      me.map.addListener('bounds_changed', (event) => me.zone.run(function() {
        me.handleBoundsChanged();
      }));
  }

  private checkInitialBounds() {
    var me = this;

      // if there are initialBoundsObjects, and setBounds has not been called, then 
      // set the pendingBoundsObjects to the intialBoundsObjects
      me.logger.debug("checking initial bounds: setBoundsComplete=" + me.setBoundsComplete + ", intialBoundsObjects.length=" + (me.initialBoundsObjects && me.initialBoundsObjects.length));

      if (!me.setBoundsComplete  && !me.pendingBoundsObjects && me.initialBoundsObjects) {
        me.setBoundsForObjects(me.initialBoundsObjects);
        return true;
      }
      return false;
  }

  public ngOnChanges(changes : SimpleChanges) {
    if (changes.initialBoundsObjects) {
      this.checkInitialBounds();
    }
  }

  private handleBoundsChanged() {
    var me = this;
    me.logger.debug("bounds_changed event, bounds=" + JSON.stringify(me.convertBounds(me.map.getBounds())));
    me.receivedBoundsChanged = true;
  }

  private checkPendingBounds() {
    var me = this;

    if (me.pendingBoundsObjects) {
      let objects = me.pendingBoundsObjects;
      me.pendingBoundsObjects = null;
      me.setBoundsForObjects(objects);
      return true;
    }
    return false;
  }

  public setBoundsForObjects(objects: any[]) {
    var me = this;

    // if the maps API hasn't finished loading, then just save
    // the objects to use to calculate bounds later. Can't calculate
    // bounds without the API because need LatLng class

    if (!me.google || !me.google.maps || me.isDetached()) {
      me.logger.debug("deferring bounds calculation until maps loaded, count=" + objects.length, me.isDetached());
      me.pendingBoundsObjects = objects;
      return;
    }

    let newBounds = undefined;

    me.logger.debug("checking bounds, objects=" + objects.length);
    for (let i = 0; i < objects.length; i++) {
      let obj = objects[i];
      me.logger.log("checking bounds: lat=" + obj.lat + ", lon=" + obj.lon);

      let latLon = new me.google.maps.LatLng(obj.lat, obj.lon);
      let circle = new me.google.maps.Circle({ center: latLon, radius: 10000 });

      if (newBounds) {
        newBounds.extend(circle.getBounds().getNorthEast());
        newBounds.extend(circle.getBounds().getSouthWest());
      } else {
        newBounds = circle.getBounds();
      }
      me.logger.log("newBounds=" + JSON.stringify(newBounds));
    }

    if (newBounds) {
      let centerLat = (newBounds.getSouthWest().lat() + newBounds.getNorthEast().lat()) / 2,
          centerLon = (newBounds.getSouthWest().lng() + newBounds.getNorthEast().lng()) / 2;

      me.logger.debug("setting map bounds to: " + JSON.stringify(me.convertBounds(newBounds)));
      me.logger.debug("setting center to: " + centerLon + "," + centerLat);


      me.map.fitBounds(newBounds);
      me.map.setCenter(new me.google.maps.LatLng(centerLat, centerLon));
      me.lastFitBounds = newBounds;
    }

    me.setBoundsComplete = true;
  }

  private handleMapIdle() {
    var me = this;

    if (me.isDetached()) {
      me.logger.debug("skiping bounds handling - map is detached");
    } else {
      if (!me.checkPendingBounds()) {
        me.logger.debug("handleMapIdle: recievedBoundsChanged=" + me.receivedBoundsChanged);
        me.logger.debug("bounds from map", me.convertBounds(me.map.getBounds()), me.lastBounds);

        if (me.receivedBoundsChanged) {
          let bounds = me.convertBounds(me.map.getBounds());
          if (me.lastBounds && 
              bounds.northeastLat == me.lastBounds.northeastLat &&
              bounds.northeastLon == me.lastBounds.northeastLon &&
              bounds.southwestLat == me.lastBounds.southwestLat &&
              bounds.southwestLon == me.lastBounds.southwestLon) {
            me.logger.debug("bounds didn't really change");
          } else {
            me.logger.debug("firing boundsChanged event: " + JSON.stringify(bounds));
            me.boundsChanged.emit(bounds);
            me.lastBounds = bounds;
          }
        }
      }
      me.receivedBoundsChanged = false;
    }
  }

  private convertBounds(bounds) {
    if (bounds) {
      return {
        southwestLat: bounds.getSouthWest().lat(),
        southwestLon: bounds.getSouthWest().lng(),
        northeastLat: bounds.getNorthEast().lat(),
        northeastLon: bounds.getNorthEast().lng()
      };
    }
    return null;
  }

  public ngAfterViewChecked() {
    this.checkResize();
  }

  public checkResize() {
    var me = this;
    me.logger.debug("checkResize() called");
    if (me.map) {
      if (!me.checkInitialBounds()) {
        me.google.maps.event.trigger(me.map, 'resize');
      }
    }
  }

  private isDetached(): boolean {
    return this.utilityService.isDetached(this.divElement.nativeElement);
  }
}
