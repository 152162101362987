import { Component, ViewContainerRef } from '@angular/core';
import { ThemeService, GlobalErrorHandler } from './platform';

import 'style-loader!./global.scss';
//import 'style-loader!../../node_modules/font-awesome/css/font-awesome.min.css';

import { Logger } from "angular2-logger/core";

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app',
  templateUrl: './app.html',
  styleUrls: [ './app.scss' ]
})
export class App {

  constructor(
      private viewContainerRef: ViewContainerRef,
      private globalErrorHandler: GlobalErrorHandler,
      private logger: Logger) {
      
    globalErrorHandler.url = "/sandbox/ws/log/logMessage";
    logger.level = logger.Level.INFO;
  }

}
