import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AsyncValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import { Logger } from 'angular2-logger/core';

import { QueryStore, StoreQuery } from './queryStore';
import { SecurityService, QueryService, Query, NotificationService, QueryResult } from '../services';
import { RemoteActionService } from "../actions";
import { RestQueryStore, StoreRestQuery } from './restQueryStore';
import { MatSnackBar } from '@angular/material';
import { map } from 'rxjs/operators';
import { DataSetQuery, DataSetStore } from './data-set-store';

export class QueryValidatorOptions {
    resultCallback?: (r: any) => void;
    dynamicFiltersCallback?: () => any;
}

@Injectable()
export class StoreService {

    private restQueryStore: RestQueryStore;
    private queryStore: QueryStore;
    private dataSetStore: DataSetStore;

    constructor(
            private securityService: SecurityService, 
            private queryService: QueryService, 
            private actionService: RemoteActionService,
            private notificationService: NotificationService,
            private logger: Logger,
            private snackbar: MatSnackBar) {

            this.queryStore = new QueryStore(queryService, notificationService, logger);
            this.dataSetStore = new DataSetStore();
    }

    public query() : StoreQuery {
        return this.queryStore.query();
    }

    public restQuery() : StoreRestQuery {
        return new StoreRestQuery(this.restQueryStore, this.securityService);
    }

    public dataSetQuery() : DataSetQuery {
        return new DataSetQuery(this.dataSetStore, this.securityService);
    }

    public queryValidator(baseSpec: any, property: string, options: QueryValidatorOptions = {}): AsyncValidatorFn {
        return (control: AbstractControl): Promise<ValidationErrors> => {
            return new Promise(resolve => {
                let query = this.query();
                query.baseSpec = baseSpec;
                query.filters = {};
                query.filters[property] = control.value;
                if (options.dynamicFiltersCallback) {
                    Object.assign(query.filters, options.dynamicFiltersCallback());
                }
                query.ready = true;
                query.results.subscribe(r => {
                    if (!r.pending) {
                        if (r.totalRecords > 0) {
                            if (options.resultCallback) {
                                options.resultCallback(r.records[0]);
                            }
                            resolve(null);
                        } else {
                            resolve({ noMatchingRecord: true });
                        }
                    }
                });
            });
        };
    }

    public dispatch(action: any): Promise<any> {
        this.logger.debug("dispatching action", action);
        return this.actionService.execute(action);
    }

    public dispatchNotify(action: any, options?: any): Promise<any> {
        options = Object.assign({ 
            successMessage: 'Action complete',
            failureMessage: 'Action failed',
            snackbarDuration: 3000
        }, options);
        
        return this.dispatch(action)
            .then(v => this.snackbar.open(options.successMessage, 'Dismiss', { duration: options.snackbarDuration } ))
            .catch(e =>  {
                this.snackbar.open(options.failureMessage, 'Dismiss', { duration: options.snackbarDuration } );
                throw e;
            });
    }

    public getDataSet(request): Observable<QueryResult> {
        return this.securityService.post("/sandbox/ws/dataSet/execute", JSON.stringify(request))
        .pipe(map(this.createResult));
    }

    public describeDataSet(dataSetId) : Observable<any> {
        return this.securityService.get("/sandbox/ws/dataSet/describe/" + dataSetId);
    }
    
    private createResult(result) {
      result.pending = false;
      return result;
    }
}
