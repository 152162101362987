import { NgModule } from '@angular/core';
import { CommonModule }  from '@angular/common';
import { AppMaterialModule } from '../appMaterial.module';
import { FlexLayoutModule } from "@angular/flex-layout";

import { PlatformModule } from '../platform/platform.module';
import { AuthGuard } from '../platform/guards/auth.guard';

import { Pages } from './pages.component';
import { PagesService } from './pages.service';
import { SelectClientComponent } from './selectClient.component';
import { CreateClaimComponent } from './createClaim/createClaim.component';
import { AvailableDownloadsComponent } from "./availableDownloads.component";
import { DownloadRequestedComponent } from "./downloadRequested.compponent";
import { SitesPageModule } from './sites/sitesPage.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [ CommonModule, PlatformModule, AppMaterialModule, FlexLayoutModule, SitesPageModule, RouterModule ],
  providers: [ AuthGuard, PagesService ],
  declarations: [Pages, SelectClientComponent, CreateClaimComponent, AvailableDownloadsComponent, DownloadRequestedComponent  ],
  entryComponents: [ SelectClientComponent, CreateClaimComponent, AvailableDownloadsComponent, DownloadRequestedComponent ]
})
export class PagesModule {
}
