import { NgModule, ApplicationRef, ErrorHandler } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule , RouteReuseStrategy } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from "@angular/flex-layout";
import { Logger } from "angular2-logger/core";

import { AppRoutingModule } from './app.routing';

import { App } from './app.component';
import { PagesModule } from './pages/pages.module';
import { NotificationService, StoreService, ThemeService, QueryService, SecurityService, AuthGuard, GlobalErrorHandler, RemoteActionService } from './platform';
import { UtilityService } from './platform/services/utility.service';
import { SearchService } from './services';
import { SortablejsModule } from 'angular-sortablejs';

import 'hammerjs';

import { AppRouteReuseStrategy } from './appRouteReuseStrategy';
import { AppHammerGestureConfig } from './appHammerGestureConfig';
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppStoreService } from './app-store.service';
import { DragDropService } from './platform/drag-drop.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  bootstrap: [App],
  declarations: [
    App
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PagesModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AppRoutingModule,
    SortablejsModule.forRoot({ animation: 150 })
  ],
  providers: [ 
    QueryService,
    RemoteActionService,
    SecurityService,
    SearchService,
    UtilityService,
    ThemeService,
    AuthGuard,
    Logger,
    GlobalErrorHandler,
    StoreService,
    NotificationService,
    AppStoreService,
    DragDropService,
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    { provide: ErrorHandler, useExisting: GlobalErrorHandler },
    { provide: HAMMER_GESTURE_CONFIG, useClass: AppHammerGestureConfig }
  ]
})

export class AppModule {

  constructor(public appRef: ApplicationRef) {
  }

}
