import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';

import * as L from 'leaflet';

@Component({
    selector: 'app-leaflet-map',
    templateUrl: './leaflet-map.component.html',
    styleUrls: ['./leaflet-map.component.css']
})
export class LeafletMapComponent implements OnInit {

    @ViewChild('mapContainer')
    private mapContainer: any;

    @Output()
    private initialized: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public boundsChanged: EventEmitter<any> = new EventEmitter<any>();

    private _map: any;

    public get map() { return this._map; };

    constructor() { }

    ngOnInit() {
    }


    ngAfterViewInit() {
        this._map = L.map(this.mapContainer.nativeElement, { preferCanvas: true });

        L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
            attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
            maxZoom: 18,
            id: 'mapbox.streets',
            accessToken: 'pk.eyJ1IjoiZGF2aWRwY29ybGV5IiwiYSI6ImNqY3Awa29objJkbjMyeG1yemk3aGZ4dWwifQ.KG7k5UmmwJmpTNvq_Hp4qA'
        }).addTo(this.map);

        setTimeout(() => {
            this._map.invalidateSize();
            this._map.fitBounds([{ lon: -124.848974, lat: 24.396308 }, { lon: -66.885444, lat: 49.384358 }]);
            this.initialized.next(this._map);
        });
        this._map.on('moveend', e => this.boundsChanged.next(this.map.getBounds()));
    }

}
