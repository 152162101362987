import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-data-set-view-widget',
  templateUrl: './data-set-view-widget.component.html',
  styleUrls: ['./data-set-view-widget.component.css']
})
export class DataSetViewWidgetComponent implements OnInit {

    @Input()
    widgetConfig = {}

    constructor() { }

  ngOnInit() {
  }

}
