import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';

import { Observable ,  BehaviorSubject } from 'rxjs';

import { SecurityService } from '../platform/services/security.service';
import { Logger } from 'angular2-logger/core';

@Injectable()
export class SearchService {

  public searchResults: BehaviorSubject<any> = new BehaviorSubject<any>({ pending: true, results: null });
  private lastRequest: any = {};
  private lastRequestNumber = 0;

  constructor(
    private securityService: SecurityService,
    private logger: Logger) {
  }

  public executeSearch(request) {
      var me = this;
      me.searchResults.next({ pending: true, results: null });
      me.lastRequest = Object.assign({ beginRecord: 1, recordLimit: 20 }, request);
      this.lastRequestNumber++;
      let requestNumber = this.lastRequestNumber;

      me.securityService.post('/sandbox/ws/search/documents', JSON.stringify(me.lastRequest))
        .subscribe(r => {
            if (this.lastRequestNumber == requestNumber) {
                me.searchResults.next({ pending: false,  results: r });
            } else {
                this.logger.debug("ignoring late arriving request results", r);
            }
      });
  }

  public getSearchResult(recordNumber: number) {
      var results = this.searchResults.getValue().results;
      return results && results.results && results.results[recordNumber - results.beginRecord];
  }

  public getPageContaining(recordNumber: number) {
    var me = this,
        newBeginRecord = Math.floor((recordNumber - 1) / me.lastRequest.recordLimit) * me.lastRequest.recordLimit;
    me.executeSearch(Object.assign({}, me.lastRequest, { beginRecord: newBeginRecord }));
  }

  public clear() {
      this.searchResults.next({ pending: false, results: null });
  }
}