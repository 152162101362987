import { Inject, Component, Input, ViewChild, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { Logger } from 'angular2-logger/core';
import { SecurityService } from "../services/security.service";
import { UtilityService } from "../services/utility.service";
import { StoreService, } from "../store/store.service";
import { StoreQuery } from "../index";
import { Subject } from "rxjs";
import { DiagramComponent } from "./diagram.component";
import { takeUntil } from "rxjs/operators";

import * as EXIF from 'exif-js';

@Component({
  selector: 'display-image',
  templateUrl: './displayImage.html',
  styleUrls: [ './displayImage.scss' ]
})
export class DisplayImageComponent implements OnInit, OnChanges {

    @Input()
    private imageId: any;

    @Input()
    private image: any;

    @Input()
    private fileId: any;

    @Input() 
    zoomControls = false;

    @Input()
    redrawRate;

    @Input()
    fixed;

    @Output()
    public imageClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public imageDblClick: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(DiagramComponent)
    private diagram: DiagramComponent;

    @ViewChild('image')
    private imageElement: any;

    private tags: any = {};
    
    public diagramComponents: any[] = [];

    private rotation = 0;
    private imageQuery: StoreQuery;
    private destroyed: Subject<boolean> = new Subject<boolean>();

    private imageUrl;

    constructor(
            private securityService: SecurityService,
            private storeService: StoreService,
            private utilityService: UtilityService,
            private logger: Logger) {

        this.imageQuery = storeService.query();
        this.imageQuery.baseSpec = {
            queryType: 'general/images'
        }
        this.imageQuery.results.pipe(takeUntil(this.destroyed)).subscribe(r => {
            this.processResults(r.records);
        });
    }

    public ngOnInit() {
    }
    
    public ngOnChanges(changes) {
        if (changes.imageId) {
            this.diagramComponents = [];
            this.imageQuery.filters = { fileId: this.fileId, imageId: this.imageId };
            this.imageQuery.ready = true;
        } else if (changes.image) {
            console.log("IMAGE CHANGED", changes.image);
            this.diagramComponents = [];
            this.imageUrl = null;
            if (this.image) {
                this.processResults([ this.image ]);
            }
        } 
    }

    private processResults(records) {
        if (records.length > 0) {
            this.image = records[0];
            this.imageUrl = this.image.imageUrl || ("/sandbox/ws/file/" + records[0].fileId);
        }
    }

    public ngOnDestroy() {
        this.destroyed.next(true);
    }

    public imageLoaded() {
        var me = this;
        EXIF.getData(this.imageElement.nativeElement, function() {
            me.tags = EXIF.getAllTags(this);
            me.rotation = 0;
            if (me.tags.Orientation == "6") {
                me.rotation = 90;
            }
            me.buildDiagramComponents();
        });
    }

    public buildDiagramComponents() {
        if (!this.image || !this.imageElement.nativeElement.complete || !this.diagram) {
            return;
        }

        let width = this.imageElement.nativeElement.width;
        let height = this.imageElement.nativeElement.height;

        this.diagram.setDiagramSize(width, height);
        
        let diagramComponents = [];
        diagramComponents.push({ 
            image: this.imageElement.nativeElement, 
            x: 0, 
            y: 0, 
            width: this.imageElement.nativeElement.width, 
            height: this.imageElement.nativeElement.height
        });

        if (this.image.annotations) {
            this.image.annotations.forEach(r => {
                let vertices = JSON.parse(r.verticesJson);
                diagramComponents.push({ 
                    vertices: vertices, 
                    strokeStyle: 'lime',
                    lineWidth: 4,
                    data: r });
            });
        }
        this.diagramComponents = diagramComponents;
    }

    public redraw() {
        if (this.diagram) {
            this.diagram.redraw();
        }
    }

  }
