import { Injectable } from '@angular/core';

import { SecurityService } from '../services/security.service';

import { Action } from './action';
import { ActionService } from './actionService';

@Injectable() 
export class RemoteActionService implements ActionService {

    constructor(private securityService: SecurityService) {
    }

    public execute(action: Action): Promise<any> {
        console.log("EXECUTING ACTION", action);
        let promise = new Promise<any>((resolve, reject) => {
            this.securityService.post("/sandbox/ws/action/execute", action)
            .toPromise()
            .then(result => {
                if (result.failed) {
                    console.log("ACTION FAILED", result);
                    reject(result);
                } else {
                    console.log("ACTION SUCCESS", result);
                    resolve(result);
                }
            })
            .catch(result => {
                console.log("ACTION EXCEPTION", result);
                reject({ failed: true, errorMessage: 'error while executing action', result: result });
            });
        });
        return promise;
    }

}
