import { BehaviorSubject, Observable } from "rxjs";
import { QueryResult } from "../services";

export abstract class AbstractQuery {
    protected _filters: any;
    protected _sorts: any;
    protected _resultsSubject = new BehaviorSubject<QueryResult>(new QueryResult());
    protected _ready: boolean = false;
    protected _recordLimit: number = null;
    protected _beginRecord = 1;

    get results(): Observable<QueryResult> { return this._resultsSubject; };

    get filters(): any { return this._filters };
    set filters(filters: any) { this._filters = filters; this.refresh(); };

    get sorts(): any { return this._sorts };
    set sorts(sorts: any) { this._sorts = sorts; this.refresh(); };

    get ready(): any { return this._ready };
    set ready(ready: any) { this._ready = ready; this.refresh(); };    

    get recordLimit(): number { return this._recordLimit };
    set recordLimit(recordLimit: number) { this._recordLimit = recordLimit; this.refresh(); };    

    get beginRecord(): number { return this._beginRecord };
    set beginRecord(beginRecord: number) { this._beginRecord = beginRecord; this.refresh(); };

    protected abstract refresh(): void;
}