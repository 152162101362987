import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { StoreService, StoreQuery } from '../../platform/store';
import { PagesService } from '../../pages/pages.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-report-view',
    templateUrl: './report-view.component.html',
    styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit, OnDestroy, OnChanges {

    @Input()
    public reportId;

    @Input()
    public reportName;

    @Input()
    public reportParams;

    @Input()
    public rerunReport = false;
 
    private report;
    private client;

    private destroyed: Subject<boolean> = new Subject<boolean>();
    private reportQuery: StoreQuery;

    private pendingRefresh = false;

    
    constructor(
        private storeService: StoreService,
        private pagesService: PagesService) {

        pagesService.client.pipe(takeUntil(this.destroyed)).subscribe(client => {
            this.client = client;
            this.refresh();
        });

        this.reportQuery = storeService.query();
        this.reportQuery.baseSpec = { 
          queryType: 'general/reports',
        };

        this.reportQuery.results.pipe(takeUntil(this.destroyed)).subscribe(r => {
            if (r.totalRecords > 0) {
                this.report = r.records[0];
                if (this.pendingRefresh) {
                    this.runReport();
                }
            }
        });
    }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        console.log("REPORT VIEW", changes);
        let filters:any = { clientId: this.client.clientId };
        if (this.reportId) {
            filters.reportId = this.reportId;
        }
        if (this.reportName) {
            filters.reportName = this.reportName;
        }
        this.reportQuery.filters = filters;
        console.log("FILTERS", this.reportQuery.filters);
        if (filters.reportId || filters.reportName) {
            if (this.rerunReport) {
                this.pendingRefresh = true;
            }
            this.reportQuery.ready = true;
        }
    }

    ngOnDestroy() {
        this.destroyed.next(true);
    }

    public refresh() {
    }

    public runReport() {
        console.log("running report", this.report);
        let payload = {
            clientId: this.client.clientId,
            reportId: this.report.reportId
        };
        
        payload = Object.assign({}, this.reportParams, payload);
        console.log("RUN REPORT", payload);
        this.storeService.dispatchNotify({
            actionType: 'reports/runReport',
            payload: payload
        }).then(r => this.refresh());
    }

    public downloadResults() {
    }
}
