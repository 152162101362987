import { Component, AfterViewChecked } from '@angular/core';

import { GoogleMapInfoWindowService } from './googleMapInfoWindow.service';

@Component({
  selector: 'google-map-info-window',
  templateUrl: './googleMapInfoWindow.html'
})
export class GoogleMapInfoWindowComponent {

  constructor(private googleMapInfoWindowService : GoogleMapInfoWindowService)
  {
  }

  public ngAfterViewChecked() {
    var me = this;
    me.googleMapInfoWindowService.makeInfoWindowVisible();
  }

}
