import { Component, Input, Output, EventEmitter, HostListener, ViewChild, OnChanges, ElementRef, OnInit, SimpleChanges, AfterViewChecked, OnDestroy } from '@angular/core';
import { Logger } from 'angular2-logger/core';
import { UtilityService, SecurityService} from '../services';
import { StoreService, StoreQuery, StoreRestQuery } from "../store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { DiagramHelper } from '../helpers/diagram-helper';

import * as _ from "lodash";
import { lang } from 'moment';
import * as math from 'mathjs';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
    selector: 'diagram',
    styleUrls: [ 'diagram.scss' ],
    templateUrl: './diagram.html',
})
export class DiagramComponent implements OnDestroy, OnInit, OnChanges {
    private _redrawRate;

    @Input()
    public components: any[] = [];

    @Input()
    public rotation: number = 0;

    @Input()
    public diagramId;

    @Input()
    zoomControls: true;

    @Input()
    set redrawRate(refreshRate: number) { this._redrawRate = refreshRate; this.setRedrawTimer(refreshRate); }

    @Input()
    private fixed = false;

    @Input()
    private defaultLineWidth = 1;

    @Input()    
    public get componentTypeStyles(): number { return this._componentTypeStyles; };
    public set componentTypeStyles(componentTypeStyles: number) { this._componentTypeStyles = componentTypeStyles; this.redraw(); };


    @ViewChild('canvas')
    private canvasElement: any;

    @Output()
    private diagramClick: EventEmitter<any> = new EventEmitter();

    @Output()
    private diagramDblClick: EventEmitter<any> = new EventEmitter();    

    private destroyed: Subject<boolean> = new Subject<boolean>();
    private canvas: any;

    private diagramWidth: number;
    private diagramHeight: number;
    private _componentTypeStyles: any = {};
    private diagramQuery: StoreQuery;
    
    private objects: any[] = [];

     // position of the mouse, in pixels relative to the upper left of the component (non-transformed)
    private mousePosition;

    private scale;
    private canvasScrollOffset;
    private zoomPercent: number;
    private panStartCanvasScrollOffset;
    private pinchStartZoomPercent: any;
    private pan: boolean = true;
    private dragInfo: any;
    
    private pendingScaleObject: any;
    private diagramHelper: DiagramHelper = new DiagramHelper();

    private redrawContext: {
        transform: any,
        transformMatrix: any,
        canvasContext: any
    };

    private redrawInterval;
    private clicks = 0;
    // transform matrix to go from diagram to canvas space (before zoom and pan)
    private baseTransform;
    
    // transform matrix to go from canvas to diagram space (before zoom and pan)
    // NOTE: this is not just the inverse of the baseTransform because
    //  that doesn't work for matrices that have both rotation and translation
    //  see: https://gamedev.stackexchange.com/questions/88481/how-can-i-reverse-the-effect-of-a-transformation-matrix
    private inverseBaseTransform;

    // transform matrix to go from diagram to canvas space (including zoom and pan)
    private globalTransform;

    // transform matrix to go from canvas to diagram space (including zoom and pan)
    private inverseGlobalTransform;


    constructor(
        private utilityService: UtilityService,
        private logger: Logger,
        private storeService: StoreService,
        private securityService: SecurityService
    ) {
        this.diagramQuery = storeService.query();
        this.diagramQuery.baseSpec = {
            queryType: 'general/diagramComponents'
        }
        this.diagramQuery.results.pipe(takeUntil(this.destroyed)).subscribe(r => {
            this.processComponents(r)
        });
    }

    public ngOnInit() {
        this.canvas = this.canvasElement.nativeElement;
        this.initialize();
    }

    public initialize() {
        this.mousePosition = { x: -1, y: -1 };
        this.canvasScrollOffset = { x: 0, y: 0 };
        this.zoomPercent = 100;      
    }

    public ngOnChanges(changes) {
        if (changes.diagramId) {
            this.loadDiagram(); 
        } else if (changes.components) {
            this.initialize();
        }
        this.redraw(); 
    }

    public ngOnDestroy() {
        this.destroyed.next(true);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (!this.utilityService.isDetached(this.canvasElement.nativeElement)) {
            this.redraw();
        }
    }

    private loadDiagram() {
        if (this.diagramId) {
            this.diagramQuery.filters = {
                rootDiagramId: this.diagramId
            }
            this.diagramQuery.ready = true;
        }
    }

    private processComponents(results) {
        let newComponents = [];

        // group the diagrams by diagram id
        let diagrams = this.diagramHelper.groupComponents(results.records);
        
        // figure out which one is the root diagram
        let root = _.find(results.records, r => r.depth == 0);

        if (root) {
            this.setDiagramSize(root.diagramWidth, root.diagramHeight);
            this.buildDiagramComponents(root.diagramId, diagrams, { scaleX: 1, scaleY: 1, offsetX: 0, offsetY: 0 }, newComponents);
        }

        this.components = newComponents;
        this.redraw();
    }

    buildDiagramComponents(diagramId, diagrams, transform, newComponents) {
        for (let i = 0; i < diagrams[diagramId].length; i++) {
            this.buildDiagramComponent(diagrams, diagrams[diagramId][i], transform, newComponents);
        }
    }

    private buildDiagramComponent(diagrams, dc, transform, newComponents) {
        if (dc.visibleFlag != 'N') {
            let topLeft = this.transformPoint(dc.x, dc.y, transform);
            let bottomRight = this.transformPoint(dc.x + dc.width, dc.y + dc.height, transform);
            newComponents.push({
                x: topLeft.x, 
                y: topLeft.y,
                width: bottomRight.x - topLeft.x,
                height: bottomRight.y - topLeft.y,
                borderFlag: dc.borderFlag,
                radius: dc.radius,
                text: dc.text,
                componentType: dc.componentType
            });
        }
        if (dc.childDiagramId) {
            let childDiagram = diagrams[dc.childDiagramId];
            let scaling = this.diagramHelper.getScalingToFitContainer({ 
                height: childDiagram.diagramHeight, 
                width: childDiagram.diagramWidth },
                dc);
            let origin = this.transformPoint(dc.x, dc.y, transform);
            let childTransform = {
                scaleX: transform.scaleX * scaling.x,
                scaleY: transform.scaleY * scaling.y,
                offsetX: origin.x,
                offsetY: origin.y,
            };
            this.buildDiagramComponents(dc.childDiagramId, diagrams, childTransform, newComponents);
        }
    }

    private transformPoint(x, y, transform) {
        x = (x * (transform.scaleX || 1)) + (transform.offsetX || 0);
        y = (y * (transform.scaleY || 1)) + (transform.offsetY || 0);
        return { x: x, y: y };
    }    

    public redraw() {
       
        if (!this.canvas) {
            return;
        }
        let context = this.canvas.getContext('2d');

        // set the canvas height and width to the actual screen height/width
        // need to save these, because as soon as we set clientWidth clientHeight gets reset
        let clientWidth = this.canvas.clientWidth,
            clientHeight = this.canvas.clientHeight;

        context.canvas.width = clientWidth;
        context.canvas.height = clientHeight;

        if (this.canvas.width == 0 || this.canvas.height == 0) {
            return;
        }

        context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        context.fillStyle = "rgb(255, 255, 255)";

        if (!this.diagramWidth || !this.diagramHeight) {
            return;
        }

        // if this is the first draw, then we need to adjust the scaling
        // fit the whole diagram, with a bit of a buffer, after rotation

        let m;

        if (this.pendingScaleObject) {
            let height = this.pendingScaleObject.height;
            let width = this.pendingScaleObject.width;

            this.baseTransform = math.eye(3);
            this.inverseBaseTransform = math.eye(3);

            if (this.rotation) {
                // shift center of image to 0,0
                m = math.matrix([
                    [ 1, 0, 0 - (width / 2.0) ],
                    [ 0, 1, 0 - (height / 2.0) ],
                    [ 0, 0, 1 ]
                ]);

                this.baseTransform = math.multiply(m, this.baseTransform);
                this.inverseBaseTransform = math.multiply(this.inverseBaseTransform, math.inv(m));

                // rotate image
                let rotationRadians = this.rotation * Math.PI / 180;
                m = math.matrix([
                    [ Math.cos(rotationRadians), 0 - Math.sin(rotationRadians), 0 ],
                    [ Math.sin(rotationRadians), Math.cos(rotationRadians), 0 ],
                    [0, 0, 1 ]
                ]);

                this.baseTransform = math.multiply(m, this.baseTransform);
                this.inverseBaseTransform = math.multiply(this.inverseBaseTransform, math.inv(m));                

                // determine the bounding box after rotation

                let points = [ 
                    { x: 0, y: 0 }, 
                    { x: 0, y: height },
                    { x: width, y: height }, 
                    { x: width, y: 0 } 
                ];

                let bounds:any = points.map(p => this.applyTransform(p, this.baseTransform))
                    .reduce((a: any, c: any) => {
                        return { 
                            min: { x: Math.min(a.min.x, c.x), y: Math.min(a.min.y, c.y ) },
                            max: { x: Math.max(a.max.x, c.x), y: Math.max(a.max.y, c.y ) }
                        };
                    }, { min: { x: Number.MAX_VALUE, y: Number.MAX_VALUE}, max: { x: 0, y: 0 } });

                width = bounds.max.x - bounds.min.x;
                height = bounds.max.y - bounds.min.y;

                // shift new upper left to 0,0
                m = math.matrix([
                    [ 1, 0, 0 - bounds.min.x ],
                    [ 0, 1, 0 - bounds.min.y ],
                    [ 0, 0, 1 ]
                ]);
                this.baseTransform = math.multiply(m, this.baseTransform);
                this.inverseBaseTransform = math.multiply(this.inverseBaseTransform, math.inv(m));
            }
    

            let padding = 0;
            this.scale = this.diagramHelper.getScalingToFitContainer({ width: width, height: height }, this.canvas, { padding: padding });
            this.pendingScaleObject = null;
            this.logger.info("set scale", this.scale);

            // add scaling to base transform

            m = math.matrix([ 
                [ this.scale.x, 0, 0 ],
                [ 0, this.scale.y, 0 ],
                [ 0, 0, 1 ]
            ]);
            this.baseTransform = math.multiply(m, this.baseTransform);
            this.inverseBaseTransform = math.multiply(this.inverseBaseTransform, math.inv(m));

            // one last transform to center in the canvas
            let xPadding = (this.canvas.width - (width * this.scale.x)) / 2.0;
            let yPadding = (this.canvas.height - (height * this.scale.y)) / 2.0;

            m = math.matrix([
                [ 1, 0, xPadding ],
                [ 0, 1, yPadding ],
                [ 0, 0, 1 ]
            ]);
            this.baseTransform = math.multiply(m, this.baseTransform);
            this.inverseBaseTransform = math.multiply(this.inverseBaseTransform, math.inv(m));
        }

        m = this.createPanZoomTransform(this.zoomPercent, this.canvasScrollOffset);
        this.globalTransform = math.multiply(m, this.baseTransform);
        this.inverseGlobalTransform = math.multiply(this.inverseBaseTransform, math.inv(m));

        let transformMatrix = this.matrixToTransformParameters(this.globalTransform);

        context.fillRect(0, 0, context.canvas.width, context.canvas.height);        
        this.redrawContext = {
            transform: this.globalTransform,
            transformMatrix: transformMatrix,
            canvasContext: context
        };

        for (let i = 0; i < this.components.length; i++) {
            this.drawComponent(this.components[i]);
        }
    }
    
    private matrixToTransformParameters(matrix) {
        return [ 
            math.subset(matrix, math.index(0, 0)),
            math.subset(matrix, math.index(1, 0)),
            math.subset(matrix, math.index(0, 1)),
            math.subset(matrix, math.index(1, 1)),
            math.subset(matrix, math.index(0, 2)),
            math.subset(matrix, math.index(1, 2))
        ];

    }

    private createPanZoomTransform(zoom, canvasScrollOffset) {
        let matrix = math.matrix([ 
            [ zoom / 100, 0, 0 ],
            [ 0, zoom / 100, 0 ],
            [ 0, 0, 1 ]
        ]);

        matrix = math.multiply([
            [ 1, 0, canvasScrollOffset.x ],
            [ 0, 1, canvasScrollOffset.y ],
            [ 0, 0, 1 ]            
         ], matrix);

        return matrix;
    }

    private applyTransform(p, transform) {
        if (!transform) {
            return p;
        }
        let matrix = math.multiply(transform, math.matrix( [ [ p.x ], [ p.y ], [ 1 ] ]));
        return { x: math.subset(matrix, math.index(0, 0)), y: math.subset(matrix, math.index(1, 0)) };
    }

    public drawComponent(c: any) {
        let context = this.redrawContext.canvasContext;

        if (c.updateComponent) {
            (c.updateComponent)(c);
        }

        context.save();
        if (c.fillStyle) {
            context.fillStyle = c.fillStyle;
        }
        if (c.strokeStyle) {
            context.strokeStyle = c.strokeStyle;
        }
        if (c.hoverStrokeStyle && this.componentContainsMouse(c)) {
            context.strokeStyle = c.hoverStrokeStyle;
        }
        if (c.fillStrokeStyle && this.componentContainsMouse(c)) {
            context.fillStyle = c.fillStrokeStyle;
        }
        context.lineWidth = c.lineWidth || this.defaultLineWidth;

        context.transform.apply(context, this.redrawContext.transformMatrix);

        if (c.render) {
            c.render(this.redrawContext);
        } else if (c.vertices) {
            this.drawVertices(c, this.redrawContext);
        } else if (c.image) {
            this.drawImage(c, this.redrawContext);
        } else if (c.radius) {
            this.drawCircle(c, this.redrawContext);
        } else {
            this.drawRectangle(c, this.redrawContext);
        }

        context.restore();
    }

    private drawRectangle(c: any, componentContext) {
        var style = Object.assign({ selectable: true }, this.componentTypeStyles['default'],  this.componentTypeStyles[c.componentType]);
        let context = componentContext.canvasContext;
        context.save();
        if (this.componentContainsMouse(c)) {
            this.logger.log("mouse is over component", c, style);
            if (style.selectable) {
                context.strokeStyle = style.activeStrokeStyle || "rgba(255,0,0,1)";
            }
        } else {
            context.strokeStyle = style.strokeStyle || "rgba(0,0,0,1)";
        }

        if (c.fillStyle) {
            context.fillStyle = c.fillStyle;
            context.fillRect(c.x, c.y, c.width, c.height);

        } else if (style.fillStyle) {
            context.fillStyle = style.fillStyle;
            context.fillRect(c.x, c.y, c.width, c.height);
        }
        
        if (c.borderFlag == 'Y') {
            context.strokeRect(c.x, c.y, c.width, c.height);
        }

        if (c.text) {
            context.strokeStyle = style.textStrokeStyle || 'black'
            context.fillStyle = style.textStrokeStyle || 'black';
            
            // This is tricky. TextScale specifies how many px per unit of height there should
            // be. Sometimes, that is too small, in which case we need to scale up so that the 
            // font size is not < 1

            let linesPerDiagramUnit = 1.3;
            let minFontSize = 6;
            let textBoxHeight = c.rotateTextFlag == 'Y' ? c.width : c.height;
            let textBoxWidth = c.rotateTextFlag == 'Y' ? c.height : c.width;
            let fontSize = Math.round(textBoxHeight / linesPerDiagramUnit);
            let scale = 1;

            if (fontSize < minFontSize) {
                fontSize = minFontSize;

                // // figure out scaling factor to get to minimum font size
                // scale = minFontSize / (textBoxHeight / linesPerDiagramUnit);
                // fontSize = minFontSize;
                // textBoxHeight = textBoxHeight * scale;

                // // create a scaling matrix to scale back down
                // let m = math.matrix([ 
                //     [ 1 / scale, 0, 0 ],
                //     [ 0, 1 / scale, 0 ],
                //     [ 0, 0, 1 ]
                // ]);

                // // undo the current global transform
                // context.transform.apply(context, this.matrixToTransformParameters(this.inverseGlobalTransform));
                
                // // apply a new transform with the additional scaling
                // let transform = math.multiply(this.globalTransform, m);
                // context.transform.apply(context, this.matrixToTransformParameters(transform));
            }
            context.font = fontSize + "px Arial";
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.translate((c.x + (c.width / 2)) * scale, (c.y + (c.height / 2)) * scale);
            if (c.rotateTextFlag == 'Y') {
                context.rotate(Math.PI / 2.0);
            }

            this.wrapText(context, c.text, 0, 0, textBoxWidth * scale, textBoxHeight * scale);
        }
        context.restore();
    }

    private drawCircle(c, componentContext) {
        componentContext.canvasContext.beginPath();
        componentContext.canvasContext.arc(c.x,c.y,c.radius,0,2 * Math.PI);
        componentContext.canvasContext.stroke();
        if (c.fillStyle) {
            componentContext.canvasContext.fillStyle = c.fillStyle;
            componentContext.canvasContext.fill();
        }
    }

    private drawVertices(c, componentContext) {
        let context = componentContext.canvasContext;
        context.beginPath();
        for (let i = 0; i <= c.vertices.length; i++) {
            let index = i % c.vertices.length;
            let p = c.vertices[index];
            if (i == 0) {
                context.moveTo(p.x, p.y);        
            } else {
                context.lineTo(p.x, p.y);
            }
        }
        context.stroke();
    }

    private drawImage(c: any, componentContext) {
        let context = componentContext.canvasContext;
        context.drawImage(c.image, c.x, c.y, c.width, c.height);
    }

    private wrapText(context, text, x, y, maxWidth, maxHeight) {
        var words = text.replace('\n', ' \n ').split(' ');
        var lines = [];
        var line = '';

        // make one pass through to figure out the total 
        // number of lines needed to write the text

        let maxLineWidth = 0;
        
        for(var n = 0; n < words.length; n++) {
            if (words[n] == '\n') {
                lines.push(line);
                line = '';
            } else {
                let testLine = line + words[n] + ' ';
                let metrics = context.measureText(testLine);
                maxLineWidth = Math.max(maxLineWidth, metrics.width);
                if (metrics.width > maxWidth && line.length > 0) {
                    lines.push(line);
                    line = words[n];
                } else {
                    line = testLine;
                }
            }
        }
        if (line.length > 0) {
            lines.push(line);
        }

        // figure out the maximum line height
        let lineHeight = parseInt(context.font);

        context.save();
        let scalingFactor = 1;

        if (lineHeight * lines.length > maxHeight) {
            scalingFactor = maxHeight / (lineHeight * lines.length);
        }
        
        if (maxLineWidth * scalingFactor > maxWidth) {
            scalingFactor = maxWidth / maxLineWidth;
        }
        
        if (scalingFactor != 1) {
            // create a scaling matrix to scale back down
            let m = math.matrix([ 
                [ scalingFactor, 0, 0 ],
                [ 0, scalingFactor, 0 ],
                [ 0, 0, 1 ]
            ]);

            // undo the current global transform
            context.transform.apply(context, this.matrixToTransformParameters(m));          
        }


            // now that we know the number of lines, we need to make
        // the starting y
        let startY = y - ((lineHeight * (lines.length - 1)) / 2.0);
        for (n = 0; n < lines.length; n++) {
            context.fillText(lines[n], x, startY + (lineHeight * n));
        }
        context.restore();
    }

      private componentContainsMouse(c) {
        if (this.globalTransform) {
            let p = this.applyTransform(this.mousePosition, this.inverseGlobalTransform);

            if (c.vertices) {
                return this.pointInsidePolygon({ x: p.x, y: p.y }, c.vertices);
            } else if (c.radius) {
                return Math.sqrt(Math.pow(p.x - c.x, 2) + Math.pow(p.y - c.y, 2)) < c.radius;
            } else {
                return (p.x >= c.x && p.x < c.x + c.width && 
                        p.y >= c.y && p.y < c.y + c.height);
            }
        }
    }

    private pointInsidePolygon(point, vs) {
        // ray-casting algorithm based on
        // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
        
        let x = point.x,
            y = point.y;
        
        let inside = false;
        for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            let xi = vs[i].x, yi = vs[i].y;
            let xj = vs[j].x, yj = vs[j].y;
            
            let intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) {
                inside = !inside;
            }
        }
        return inside;
    };


    public setDiagramSize(width, height) {
        this.diagramWidth = width;
        this.diagramHeight = height;
        this.pendingScaleObject = { x: 0, y: 0, height: height, width: width };
        this.logger.info("set diagram size", this.pendingScaleObject);
    }

    handleMouseWheelEvent(event) {
        if (!this.fixed) {
            event.stopPropagation();
            event.preventDefault();
            this.setMousePosition(event);
            this.handleZoom(event.wheelDelta);
        }
    }

    handleZoom(delta) {
        let newZoomPercent = this.zoomPercent + Math.sign(delta) * 15;
        if (newZoomPercent < 10) {
            newZoomPercent = 10;
        }
        this.setZoomPercent(newZoomPercent);
    }

    private setZoomPercent(newZoomPercent) {
        // determine the diagram coordinate the mouse was over before the zoom
        let p1 = this.applyTransform(this.mousePosition, this.inverseGlobalTransform);

        // determine the canvas position of the original diagram component with the new zoom
        let transform = math.multiply(this.createPanZoomTransform(newZoomPercent, this.canvasScrollOffset), this.baseTransform);
        let p2 = this.applyTransform(p1, transform);
     
        // shift the canvas to compensate
        this.canvasScrollOffset.x -= (p2.x - this.mousePosition.x);
        this.canvasScrollOffset.y -= (p2.y - this.mousePosition.y);
        this.zoomPercent = newZoomPercent;

        this.redraw();
    }

    handleMouseMoveEvent(event) {
        this.logger.log("handleMouseMoveEvent: pan=" + this.pan, event);
        this.setMousePosition(event);
        this.redraw();
    }    

    handlePanEvent(event) {
        var me = this;

        // if the mouse is over a draggable component
        this.setMousePosition(event);

        if (this.fixed) {
            return;
        }

        if ((event.isFirst || !me.panStartCanvasScrollOffset) && !event.isFinal) {
            me.logger.debug("start pan", event);

            // check to see if this is a drag
            let components = this.getComponentsUnderMouse();
            for (let i = 0; i < components.length; i++) {
                if (components[i].draggable) {
                    this.dragInfo = {
                        component: components[i],
                        x: components[i].x,
                        y: components[i].y
                    };
                    return;
                }
            }
            me.panStartCanvasScrollOffset = Object.assign({}, me.canvasScrollOffset);
        } else if (event.isFinal) {
            me.logger.debug("end pan", event);
            me.dragInfo = undefined;
            me.panStartCanvasScrollOffset = undefined;
        } else {
            me.logger.debug("continue pan", event);

            if (this.dragInfo) {
                // TODO: not implemented
                // need to adjust the location of the component being dragged 
            } else {
                me.canvasScrollOffset = {
                    x: me.panStartCanvasScrollOffset.x + event.deltaX,
                    y: me.panStartCanvasScrollOffset.y + event.deltaY
                };  
            }          
        }
        me.redraw();
    }

    private setMousePosition(event) {
        let canvas = this.canvas,
            rect = canvas.getBoundingClientRect(),
            location: any;

        if (event.clientX) {
            location = { x: event.clientX, y: event.clientY };
        } else if (event.center) {
            location = { x: event.center.x, y: event.center.y };
        } else if (event.srcEvent) {
            location = { x: event.srcEvent.clientX, y: event.srcEvent.clientY };
        }
        this.mousePosition = { x: location.x - rect.left, y: location.y - rect.top };
        let p = this.applyTransform(this.mousePosition, this.inverseGlobalTransform);
    }    

    handlePinchEvent(event) {
        this.setMousePosition(event);
        if (this.fixed) {
            return;
        }
        this.logger.debug("pinch event", event);
        if (event.isFirst || !this.pinchStartZoomPercent || event.type == "pinchstart") {
            this.logger.debug("start pinch", event);
            this.pinchStartZoomPercent = this.zoomPercent;
        } else if (event.isFinal || event.type == "pinchend") {
            this.logger.debug("end pinch", event);
            this.pinchStartZoomPercent = undefined;
        } else {
            this.logger.debug("continue pinch", event);
            this.setZoomPercent(this.pinchStartZoomPercent * event.scale);
        }
        this.redraw();
    }

    handleClickEvent(event) {

        this.setMousePosition(event);

        this.clicks++;
        console.log("CLICKS: ", this.clicks);
        if (this.clicks == 1) {
            let newEvent = {
                // TODO: add diagram x, diagram y coordinates
                components: this.getComponentsUnderMouse()
            };
            setTimeout(() => {
                if (this.clicks == 1) {
                    this.diagramClick.next(newEvent);
                } else {
                    this.diagramDblClick.next(newEvent);
                }
                this.clicks = 0;
            }, 200);
        }
        event.stopPropagation();
    }

    private getComponentsUnderMouse() {
        // loop through the components to see if we are in Component
        let components = [];
        for (let c of this.components) {
            if (this.componentContainsMouse(c)) {
                components.push(c);
            }
        }
        return components;
    }

    private setRedrawTimer(rate) {
        if (this.redrawInterval) {
            clearInterval(this.redrawInterval);
        }
        if (rate) {
            this.redrawInterval = setInterval(() => this.redraw(), rate);
        }
    }
    
    // https://stackoverflow.com/questions/2936112/text-wrap-in-a-canvas-element
    private getLines(ctx, text, maxWidth) {
        var words = text.split(" ");
        var lines = [];
        var currentLine = words[0];
    
        for (var i = 1; i < words.length; i++) {
            var word = words[i];
            var width = ctx.measureText(currentLine + " " + word).width;
            if (width < maxWidth) {
                currentLine += " " + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        }
        lines.push(currentLine);
        return lines;
    }
 }