import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { PagesRoutingModule } from './pages/pages.routing';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'pages',
    data: { reusable: true }
  }
];

@NgModule({
    imports: [
        PagesRoutingModule,
        RouterModule.forRoot(routes, { useHash: true })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {};

