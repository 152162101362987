import { Component, OnInit, Input, ViewChild, AfterViewInit, HostListener, EventEmitter, Output, OnChanges } from '@angular/core';

import * as d3 from 'd3';
import * as venn from 'venn.js';

@Component({
    selector: 'app-venn-diagram',
    templateUrl: './venn-diagram.component.html',
    styleUrls: ['./venn-diagram.component.css']
})
export class VennDiagramComponent implements AfterViewInit, OnChanges {

    @Input()
    sets: any[];

    @ViewChild('venn')
    private venn: any;

    @Output()
    public dataClick: EventEmitter<any> = new EventEmitter<any>();

    private vennSets: any[];

    private viewInitialized = false;

    constructor() { }


    ngAfterViewInit() {
        // wait for the clientSize and clientHeight to settle down a bit
        setTimeout(() => { 
            this.viewInitialized = true;
            this.redraw();
        }, 0);
    }

    ngOnChanges(changes) {
        if (this.viewInitialized && changes.sets) {
            this.redraw();
        }
    }

    @HostListener('window:resize', ['$event'])
    redraw() {
        let allSets = this.sets.reduce((p, c) => this.union(p, c.sets), []);
        let allSubsets: any[] = this.subsets(allSets).filter(s => s.length > 0);

        let sets = allSubsets.map(subset => {
            let total = 0;
            this.sets.forEach(s => {
                if (this.containsAll(s.sets, subset)) {
                    total += s.size;
                }
            });
            return { sets: subset, size: total };
        });

        var chart = venn.VennDiagram()
            .width(this.venn.nativeElement.clientWidth)
            .height(this.venn.nativeElement.clientHeight)
            .duration(0);

        let div = d3.select(this.venn.nativeElement);
        div.datum(sets).call(chart);
        div.selectAll("g")
            .on("click", (d, i) => {
                this.dataClick.next(d);
            });
    }

    subsets(s) {
        return s.reduce((subsets, value) => subsets.concat(subsets.map(set => [ value, ...set])), [[]]);
    }
    
    containsAll(a: any[], b: any[]) {
        for (let i = 0; i < b.length; i++) {
            if (a.indexOf(b[i]) < 0) {
                return false;
            }
        }
        return true;
    }

    union(a: any[], b: any[]) {
        let both = [...a, ...b];
        let result = [];
        both.forEach(x => {
            if (result.indexOf(x) < 0) {
                result.push(x);
            }
        });
        return result;
    }
}
