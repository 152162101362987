import { Component, Input, OnChanges } from '@angular/core';
import { Logger } from 'angular2-logger/core';

import { UtilityService, Query, QueryService } from '../../platform';

@Component({
    selector: 'physical-layout',
    templateUrl: './physicalLayout.html'
})
export class PhysicalLayoutComponent implements OnChanges {
    @Input()
    private equipmentId: any;
    
    physicalLayoutQuery: Query;

    constructor(private queryService: QueryService, private logger: Logger) {
        var me = this;
        me.physicalLayoutQuery = new Query(queryService);
    }

    ngOnChanges(changes) {
        var me = this;
        if (changes.equipmentId) {
            me.physicalLayoutQuery.execute({
                queryType: 'sites/physicalLayout',
                filters: { equipmentId: me.equipmentId }
            });
        }
    }

        
}
