import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../appMaterial.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { PlatformModule } from '../../platform/platform.module';

import { SiteDetailComponent } from './siteDetail.component';
import { EquipmentExplorerComponent } from './equipmentExplorer.component';
import { PhysicalLayoutComponent } from './physicalLayout.component';
import { PortLayoutComponent } from './portLayout.component';
import { AddressDetailComponent } from './addressDetail.component';
import { LocationDetailComponent } from './locationDetail.component';
import { EquipmentDetailComponent } from './equipmentDetail.component';
import { EquipmentEditComponent } from './equipmentEdit.component';
import { EquipmentImageGalleryComponent } from './equipmentImageGallery.component';

import { SiteDetailOverviewComponent } from './site-detail-overview/site-detail-overview.component';
import { CommonComponentsModule } from '../../common-components/common-components.module';
import { SiteEquipmentListComponent } from './site-equipment-list/site-equipment-list.component';

import { RouterModule } from '@angular/router';
import { RetailSiteDetailComponent } from './retail-site-detail/retail-site-detail.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PlatformModule,
    AppMaterialModule,   
    CommonComponentsModule,
    RouterModule
  ],
  declarations: [
    SiteDetailComponent,
    EquipmentExplorerComponent,
    PhysicalLayoutComponent,
    PortLayoutComponent,
    AddressDetailComponent,
    LocationDetailComponent,
    EquipmentDetailComponent,
    EquipmentEditComponent,
    EquipmentImageGalleryComponent,
    SiteDetailOverviewComponent,
    SiteEquipmentListComponent,
    RetailSiteDetailComponent
  ],
  exports: [
    EquipmentImageGalleryComponent
  ],
  entryComponents: [ EquipmentEditComponent ],
  providers: []
})
export class SitesPageModule {
}


