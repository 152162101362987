import { Component, Input, ViewChild, ChangeDetectorRef, OnInit, ElementRef, OnDestroy, AfterViewChecked, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'angular2-logger/core';
import { MatSnackBar, MatDialog, MatTabGroup, MatTab } from '@angular/material';

import * as L from 'leaflet';
import 'leaflet-measure';

import { QueryService, Query, DisplayImageDialogComponent, StoreQuery, StoreService } from '../../platform';
import { PagesService } from '../pages.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DownloadRequestedComponent } from "../downloadRequested.compponent";
import { EquipmentEditComponent } from '.';
import { LeafletMapComponent } from '../../platform/leaflet-map/leaflet-map.component';

@Component({
    selector: 'site-detail',
    styleUrls: ['./siteDetail.scss'],
    templateUrl: './siteDetail.html'
})
export class SiteDetailComponent implements OnInit, OnDestroy {

    @Input()
    public locationId: number;

    @ViewChild(LeafletMapComponent)
    private map: LeafletMapComponent;

    private markerLayer: any;

    public allowEquipmentAdd = false;

    private componentTypeStyles = {
        rack: {
            fillStyle: "rgb(237,240,255)",
            strokeStyle: "rgb(50,50,50)",
            textStrokeStyle: "rgb(127,124,125)",
            selectable: true
        },
        default: {
            fillStyle: "rgb(255,255,255)",
            strokeStyle: "rgb(50,50,50)",
            textStrokeStyle: "rgb(127,124,125)",
            selectable: false
        }
    };

    @ViewChild('siteDiagram')
    private siteDiagram: any;

    @ViewChildren(MatTab)
    private tabs: QueryList<MatTab>;

    @ViewChild(MatTabGroup)
    private tabGroup: MatTabGroup;

    public site: any;

    selectedAddressMarker: any;

    nearbyPopAddressesQuery: StoreQuery;
    invoiceQuery: StoreQuery;
    inventoryQuery: StoreQuery;
    private siteQuery: StoreQuery;

    private nearbyPopAddresses: any[] = [];

    private matchedLocations: any[];
    private addressPops: any[];
    private mapBounds: any;

    public selectedPhysicalLocation: any;

    private destroyed: Subject<boolean> = new Subject<boolean>();

    constructor(private route: ActivatedRoute,
        private router: Router,
        private elementRef: ElementRef,
        private logger: Logger,
        private storeService: StoreService,
        private pagesService: PagesService,
        public dialog: MatDialog,
        private snackbar: MatSnackBar,
        private activatedRoute: ActivatedRoute) {

        this.siteQuery = storeService.query();
        this.siteQuery.baseSpec = {
            queryType: 'sites/matchingLocations'
        };

        this.invoiceQuery = storeService.query();
        this.inventoryQuery = storeService.query();
        this.nearbyPopAddressesQuery = storeService.query();
    }

    public ngOnInit() {

        this.allowEquipmentAdd = !!window['allowEquipmentAdd'] || false;

        this.siteQuery.results.pipe(takeUntil(this.destroyed)).subscribe(results => {
            console.log("SITE QUERY RESULTS: ", results);
            this.matchedLocations = results.records;
            if (!results.pending) {
                this.site = results.records.find(r => r.matchLocationId == this.locationId);
                let prop = this.site.externalSystemProperties.find(p => p.propertyName == 'inventoryQuery');
                this.inventoryQuery.baseSpec = {
                    queryType: prop ? prop.propertyValue : 'sites/locationInventory',
                    recordLimit: 50,
                    determinePopulated: true
                };
                prop = this.site.externalSystemProperties.find(p => p.propertyName == 'invoiceQuery');
                this.invoiceQuery.baseSpec = {
                    queryType: prop ? prop.propertyValue : 'sites/locationLineItemDetail',
                    recordLimit: 50,
                    determinePopulated: true
                };
                this.refreshDetails();
            }
        });

        this.nearbyPopAddressesQuery.baseSpec = {
            queryType: 'popAddressesByLocationDistance',
            filters: {
                maxDistanceMiles: 50
            },
            sorts: [{ property: 'distanceMiles', sortDirection: 'ascending' }],
            recordLimit: 50
        };
        this.nearbyPopAddressesQuery.results.pipe(takeUntil(this.destroyed)).subscribe(results => {
            console.log("NEARBY POP ADDRESSES", results);
            this.nearbyPopAddresses = results.records;
            this.displayNearbyPopAddresses();
        });

        this.route.params.pipe(takeUntil(this.destroyed)).subscribe(params => {
            this.locationId = +params['locationId'];
            this.refresh();

        });
    }

    public ngOnDestroy() {
        this.destroyed.next(true);
    }

    public ngOnChanges() {
        this.refresh();
    }

    initializeMap() {
        let measureControl = L.control.measure({
            primaryAreaUnit: 'sqfeet', secondaryAreaUnit: 'sqmiles',
            primaryLengthUnit: 'feet', secondaryLengthUnit: 'miles',
            activeColor: '#9932CC'
        });
        measureControl.addTo(this.map.map);

        this.markerLayer = L.featureGroup();
        this.map.map.addLayer(this.markerLayer);
        this.displayNearbyPopAddresses();
    }

    private refresh() {
        if (this.locationId) {
            this.siteQuery.filters = { locationId: this.locationId };
            this.siteQuery.ready = true;
        }
    }

    private displayNearbyPopAddresses() {

        if (!this.markerLayer) {
            return;
        }

        this.markerLayer.clearLayers();

        if (!this.site) {
            return;
        }
        let siteMarker = L.circleMarker([this.site.lat, this.site.lon], {
            stroke: true,
            fillColor: '#FF8C00',
            color: '#FF8C00',
            fillOpacity: 1,
            radius: 8
        });
        this.markerLayer.addLayer(siteMarker);

        this.nearbyPopAddresses.forEach(address => {

            let marker = L.circleMarker([address.lat, address.lon], {
                stroke: true,
                fillColor: '#f03',
                color: '#f03',
                fillOpacity: 0.25,
                radius: 4
            });

            let popup = `
<div class="popup">
<h5>${address.addressText}</h5>
<i>${address.distanceMiles.toFixed(1)} miles</i>
<hr>`
            address.pops.forEach(pop => {
                popup = popup + `<div>${pop.companyName}</div>`;
            });
            popup = popup + '</div>'
            marker.bindPopup(popup);

            address.marker = marker;
            address.markerDisplayed = true;
            this.markerLayer.addLayer(marker);
            this.map.map.fitBounds(this.markerLayer.getBounds());
        });
    }

    private refreshDetails() {
        this.nearbyPopAddressesQuery.filters = {
            locationId: this.locationId,
        };
        this.nearbyPopAddressesQuery.ready = true;

        this.invoiceQuery.filters = {
            locationId: this.locationId
        };
        this.invoiceQuery.ready = true;

        this.inventoryQuery.filters = {
            locationId: this.locationId
        };
        this.inventoryQuery.ready = true;
    }

    handleBoundsChanged(bounds) {
        this.mapBounds = bounds;
    }

    handleAddressMarkerSelected(data) {
        this.selectedAddressMarker = data.obj;
        this.addressPops = data.obj.pops;
    }

    invoiceRowStyle(record) {
        let style: any = {};
        if (record.inventoryMatchFlag == 'Y') {
            style.color = 'blue';
        }
        return style;
    }

    createAuditTemplate(locationId) {
        this.storeService.dispatch({
            actionType: 'sites/createLocationAuditTemplate',
            payload: {
                locationId: locationId
            }
        }).then(
            x => this.snackbar.openFromComponent(DownloadRequestedComponent, { duration: 10000 }),
            x => this.snackbar.open("Failed to request download", "Dismiss", { duration: 10000 })
        );
    }

    public handleSubLocationClick(location) {
        this.router.navigate(['../', location.locationId], { relativeTo: this.activatedRoute });
    }

    addEquipment() {
        this.dialog.open(EquipmentEditComponent, {
            disableClose: true,
            data: { locationId: this.locationId }
        });
    }

    handleSelectedTabChange() {
        if (this.markerLayer) {
            this.map.map.invalidateSize();
            this.map.map.fitBounds(this.markerLayer.getBounds());
        }
        if (this.siteDiagram) {
            this.siteDiagram.redraw();
        }
    }
}
