import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityService } from '../services/security.service';

@Component({
    selector: 'app-file-preview',
    templateUrl: './file-preview.component.html',
    styleUrls: ['./file-preview.component.css']
})
export class FilePreviewComponent implements OnInit, OnChanges {

    @Input()
    public fileId;

    @Input()
    public title;

    @Input()
    public contentType: 'html' | 'text' | 'code' = 'html';

    @Output()
    public close = new EventEmitter<any>();

    public previewHtml;
    public previewText;


    constructor(
        private sanitizer: DomSanitizer,
        private securityService: SecurityService
    ) { }

    ngOnInit() {
    }

    ngOnChanges(changes)
    {
        if (this.fileId && this.contentType) {
            let url = "/sandbox/ws/file/" + this.fileId;
            switch (this.contentType) {
            case 'html':
                this.previewHtml = this.sanitizer.bypassSecurityTrustHtml('<object type="text/html" style="width: 100%; height: 100%;" data="' + url + '"></object>');
                break;
            case 'text':
            case 'code':
                this.securityService.get(url, { responseType: 'text' }).subscribe(t  => {
                    this.previewText = t;
                });
                break;
            }
        }
    }

}
