import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UtilityService } from '../../services';
import { AnalyzeDataSetService } from '../analyze-data-set.service';
import { MatMenuTrigger } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'analyze-data-set-table',
  templateUrl: './analyze-data-set-table.component.html',
  styleUrls: ['./analyze-data-set-table.component.scss']
})
export class AnalyzeDataSetTableComponent  {

  @Input()
  public displaySettings = false;

  @ViewChild(MatMenuTrigger)
  cellContextMenuTrigger: MatMenuTrigger;
  
  public cellContextMenuX = 0;
  public cellContextMenuY = 0;
  public results: any;

  public properties;
  public dimensions;
  public aggregates;
  public sorts;

  private dragStartArray;
  private dragValue;
  
  constructor(
    public analyzeDataSetService: AnalyzeDataSetService,
    private elementRef: ElementRef,
    private router: Router) { }

  public handleContextMenu(event, record, column) {
    event.preventDefault();
    this.cellContextMenuTrigger.menuData = {
      record: record,
      column: column
    };
    let rect = this.elementRef.nativeElement.getBoundingClientRect();
    this.cellContextMenuX = event.clientX - rect.x;
    this.cellContextMenuY = event.clientY - rect.y;
    this.cellContextMenuTrigger.openMenu();
  }

  public handleContextMenuSearch(record, column) {
    this.router.navigate([ '/pages/search' ], { queryParams: { searchText: record[column.name] } });
  }

  public handleContextMenuFilter(record, column) {
    this.analyzeDataSetService.addFilter({
      operator: 'equal',
      values: [ { property: column.name }, record[column.name] ]
    });
  }
}
