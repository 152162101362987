import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Logger } from 'angular2-logger/core';

import { Query, UtilityService } from '../services';

@Component({
    selector: 'pager',
    templateUrl: './pager.html',
    styleUrls: [ './pager.scss' ]
})
export class PagerComponent  {

    @Input()
    totalRecords = 0;

    @Input()
    beginRecord = 1;

    @Input()
    currentRecords = 0;

    @Input()
    recordsPerPage = 10;

    @Output()
    private changeBeginRecord: EventEmitter<any> = new EventEmitter<any>();

    constructor(private logger: Logger, private utilityService: UtilityService) {
    }

    private handleChangeBeginRecord(beginRecord) {
        this.changeBeginRecord.emit(beginRecord);
    }
}
