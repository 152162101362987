import { Component, Input, OnChanges } from '@angular/core';
import { Logger } from 'angular2-logger/core';

import { UtilityService, Query, QueryService } from '../../platform';

@Component({
    selector: 'port-layout',
    templateUrl: './portLayout.html'
})
export class PortLayoutComponent implements OnChanges {
    @Input()
    private equipmentId: any;
    
    portLayoutQuery: Query;

    constructor(private queryService: QueryService, private logger: Logger) {
        var me = this;
        me.portLayoutQuery = new Query(queryService);
    }

    ngOnChanges(changes) {
        var me = this;
        if (changes.equipmentId) {
            me.portLayoutQuery.execute({
                queryType: 'sites/portLayout',
                filters: { equipmentId: me.equipmentId }
            });
        }
    }

}
