import { Directive } from '@angular/core';

@Directive({
  selector: '[appDisableAutocomplete]',
  host: {
    'autocomplete': 'off',
    'data-lpignore': 'true'
  }
})
export class DisableAutocompleteDirective {
  constructor() { }
}
