import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { StoreService, StoreQuery } from '../../platform';
import { PagesService } from '../../pages';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'app-equipment-list',
    templateUrl: './equipment-list.component.html',
    styleUrls: ['./equipment-list.component.css']
})
export class EquipmentListComponent implements OnInit, OnChanges {

    @Input()
    private locationId: any;

    @Input()
    private parentEquipmentId: any;

    public equipmentQuery: StoreQuery;

    private destroyed: Subject<boolean> = new Subject<boolean>();
    private client: any;

    constructor(
        private storeService: StoreService,
        private pagesService: PagesService,
        private router: Router
    ) {
        this.equipmentQuery = storeService.query();
        this.equipmentQuery.baseSpec = {
            queryType: 'model/equipment',
            filters: {
                attachedToEquipmentId: { isNull: true }
            },
            sorts: [{ property: 'equipmentName', direction: 'ascending' }]
        };
    }

    ngOnInit() {
        this.pagesService.client.pipe(takeUntil(this.destroyed)).subscribe(client => this.handleChangeClient(client));
    }

    ngOnDestroy() {
        this.destroyed.next(true);
    }

    handleChangeClient(client) {
        var me = this;
        this.client = client;
    }

    ngOnChanges() {
        this.refresh();
    }

    refresh() {
        let filters:any = {};
        if (this.locationId) {
            filters.locationId = this.locationId;
        }
        if (this.parentEquipmentId) {
            filters.parentEquipmentId = this.parentEquipmentId;
        }
        this.equipmentQuery.filters = filters;
        this.equipmentQuery.ready = true;
    }


}
