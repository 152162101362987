import { Component, OnInit, Input, EventEmitter, Output, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'analyze-data-set-property-list',
  templateUrl: './analyze-data-set-property-list.component.html',
  styleUrls: ['./analyze-data-set-property-list.component.scss']
})
export class AnalyzeDataSetPropertyListComponent implements OnInit {

  @Input()
  public title: string;

  @Input()
  public array: any[];

  @Input()
  public removable: boolean = true;

  @Input()
  public droppable: boolean = true;

  @Input()
  public draggable: boolean = true;

  @ContentChild(TemplateRef) 
  public template: TemplateRef<any>;

  @Output()
  public propertyDoubleClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  private handleRemove(property) {
    let index = this.array.indexOf(property);
    if (index > -1) {
      this.array.splice(index, 1);
    }
  }

  public handleRemoveAllClick() {
    if (this.array.length) {
      this.array.splice(0, this.array.length);
    }
  }

  public handleValueDroppedTo(event, property?) {
    console.log("DROP LIST", event, property);
    let index = -1;
    if (property) {
      index = this.array.indexOf(property);
    }
    if (index == -1) {
      this.array.push(event.value);
    } else {
      this.array.splice(index, 0, event.value);
    }
  }

  public handleValueDroppedFrom(event) {
    if (this.removable) {
      let index = this.array.indexOf(event.value);
      this.array.splice(index, 1);
    }
  }

  public handleDoubleClick(property) {
    this.propertyDoubleClicked.next(property);
  }
}