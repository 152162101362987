import { Injectable } from '@angular/core';
import { StoreService } from './platform';
import { Observable ,  Subject } from 'rxjs';
import { Logger } from 'angular2-logger/core';

@Injectable()
export class AppStoreService {

  constructor(
    private storeService: StoreService,
    private logger: Logger) {
  }


  public getEquipment(equipmentId: number) {
    return this.getSingleQueryResult({ 
      queryType: 'model/equipment',
      filters: {
        equipmentId: equipmentId
      }
    });
  }

  private getSingleQueryResult(spec) {
    let query = this.storeService.query(),
        subject = new Subject<any>();
    
    query.baseSpec = spec;
    query.ready = true;
    query.results.subscribe(r => {
      this.logger.debug("received results in app store service", r, spec);
      if (!r.pending) {
        subject.next(r.records[0]);
        subject.complete();
        query.destroy();
      }
    });
    return subject.asObservable();
  }
}
