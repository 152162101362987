import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Logger } from 'angular2-logger/core';

import { UtilityService, StoreQuery, StoreService, QuerySelectionDialogComponent } from '../../platform';

import { EquipmentEditComponent } from '.';

@Component({
    selector: 'equipment-detail',
    templateUrl: './equipmentDetail.html',
    styleUrls: [ './equipmentDetail.scss' ]
})
export class EquipmentDetailComponent implements OnChanges {
    @Input()
    equipment: any;

    @ViewChild('downloadIframe')
    private downloadIframe: any;

    equipmentQuery: StoreQuery;
    portQuery: StoreQuery;
    equipmentPhysicalConfigurationId: number;

    constructor(
            private activatedRoute: ActivatedRoute, 
            private router: Router, 
            private storeService: StoreService, 
            private logger: Logger,
            private dialog: MatDialog) {

        this.equipmentQuery = storeService.query();
        this.portQuery = storeService.query();
    }
    
     public ngOnChanges(changes) {
        if (changes.equipment) {
            this.equipmentPhysicalConfigurationId = 
                (this.equipment.equipmentPhysicalConfiguration && this.equipment.equipmentPhysicalConfiguration.equipmentPhysicalConfigurationId) ||
                (this.equipment.equipmentModel.equipmentPhysicalConfiguration && this.equipment.equipmentModel.equipmentPhysicalConfiguration.equipmentPhysicalConfigurationId);

            this.equipmentQuery.baseSpec = { 
                queryType: 'model/equipment',
                filters: { 
                    attachedToEquipmentId: this.equipment.equipmentId
                },
                sorts: [ { property: 'firstOrdinalPosition', direction: 'ascending' } ]
            };
            this.equipmentQuery.ready = true;

            this.portQuery.baseSpec = { 
                queryType: 'sites/portLayout',
                filters: { 
                    rootEquipmentId: this.equipment.equipmentId, 
                    depthExcludingJacks: 0
                },
                sorts: [ { property: 'descendantSortPosition', direction: 'ascending' }, { property: 'portOrdinalPosition', direction: 'ascending' } ]
            };
            this.portQuery.ready = true;
        }
    }

    handleEquipmentSurvey(event, equipment) {
        event.stopPropagation();
        this.router.navigate([ '/pages/physicalAudit/equipmentAudit', equipment.equipmentId ]);
    }

    handleRowClick(child) {
        var me = this;
        me.router.navigate([ '../../equipment', child.equipmentId ], { relativeTo: me.activatedRoute });
    }

    log(o) {
        console.log(o);
    }

    editEquipment() {
        this.dialog.open(EquipmentEditComponent);
    }

    downloadTemplate() {
        this.downloadIframe.nativeElement.src = '/sandbox/ws/test/executeResponseScript?script=export/siteAuditTemplate&equipmentId=' + this.equipment.equipmentId;
    }  

    handleEditPhysicalConfiguration() {
        this.dialog.open(QuerySelectionDialogComponent, {
            data: { 
                baseSpec: {
                    queryType: 'physicalAudit/equipmentPhysicalConfigurations'
                },
                displayField: 'name'
            }
        }).afterClosed().subscribe(result => {
            if (result) { 
                this.storeService.dispatch({ 
                    actionType: 'updateEquipment',
                    payload: {
                        data: {
                            equipmentId: this.equipment.equipmentId,
                            equipmentPhysicalConfigurationId: result.equipmentPhysicalConfigurationId
                        }
                    }
                });
            }
        });
    }

    addEquipment() {
        console.log(this.equipment);
        this.dialog.open(EquipmentEditComponent, { 
            disableClose: true, 
            data: { attachedToEquipment: this.equipment } 
        });
    }
    
}
