import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';
import { AbstractQueryAutocomplete, QueryAutocompleteDialogComponent } from '../query-autocomplete-dialog/query-autocomplete-dialog.component';
import { StoreService } from '../store';

@Component({
    selector: 'app-query-autocomplete-label',
    templateUrl: './query-autocomplete-label.component.html',
    styleUrls: ['./query-autocomplete-label.component.scss']
})
export class QueryAutocompleteLabelComponent extends AbstractQueryAutocomplete implements OnInit {

    @Input()
    public value;

    @Input()
    public displayValue;

    @Input()
    public emptyDisplayValue;

    @Output()
    public valueChanged: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(QueryAutocompleteDialogComponent)
    private dialog: QueryAutocompleteDialogComponent;

    @ViewChild('label')
    public labelElement: ElementRef;

    @ContentChild(TemplateRef)
    public displayTemplate: TemplateRef<any>;    
   
    constructor(private storeService: StoreService) {
        super();
    }

    ngOnInit() {
    }

    private handleChangeRecord(record) {
        this.displayValue = record[this.displayProperty];
        if (this.value != record[this.valueProperty]) {
            this.value = record[this.valueProperty];
            this.valueChanged.emit(this.value);
        }
    }

    handleInputClick(event) {
        this.dialog.openDialog(this.displayValue);
    }

}
