import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StoreService, StoreQuery, SecurityService } from "../../platform";
import { FileUploader, FileItem } from "ng2-file-upload";

@Component({
    selector: 'equipment-image-gallery',
    templateUrl: './equipmentImageGallery.html',
    styleUrls: [ './equipmentImageGallery.scss']
})
export class EquipmentImageGalleryComponent implements OnInit {
    imageQuery: StoreQuery;

    @Input()
    private equipmentId;

    @Output()
    private imageClicked: EventEmitter<any> = new EventEmitter<any>();

    private fileUploader: FileUploader = new FileUploader({ 
        url: '/sandbox/ws/action/upload',
        autoUpload: true
       });

    private fileEquipmentList: any[] = [];

    constructor(
            private route: ActivatedRoute,
            private router: Router,
            private storeService: StoreService,
            private securityService: SecurityService) {

        this.equipmentId = route.snapshot.paramMap.get('equipmentId');
        this.imageQuery = storeService.query();
        this.imageQuery.baseSpec = {
            queryType: 'model/equipmentImages'
        };

        this.fileUploader.onBuildItemForm = (fileItem: FileItem, form: any): any => this.buildUploadForm(fileItem, form);
        this.fileUploader.authToken = securityService.getAuthToken();        
        
    }

    public ngOnInit() {
        this.imageQuery.filters = {
            rootEquipmentId: this.equipmentId
        };
        this.imageQuery.ready = true;
        
    this.imageQuery.addRefreshNotification({
            type: 'equipmentImageUploaded',
            'payload.equipmentId': this.equipmentId
        });
        this.imageQuery.addRefreshNotification({
            type: 'equipmentImageAnnotated',
            'payload.equipmentId': this.equipmentId
        });
    }

    private buildUploadForm(fileItem: FileItem, form: any) {
        let index = this.fileEquipmentList.findIndex(fe => fe.file == fileItem._file);
        form.append("action", JSON.stringify({ actionType: 'physicalAudit/uploadEquipmentPhoto', payload: { equipmentId: this.equipmentId }}));
        return { fileItem, form };
    }

    private uploadPhoto(event, equipment) {
        console.log("UPLOAD");
        console.log(event);
        console.log(equipment);
        this.fileUploader.addToQueue(event.target.files);
        event.target.value = null;
    }
    
}