import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';

import * as Chart from 'chart.js';
import 'chart.piecelabel.js';
import { ThemeService } from '../../services';
import { AnalyzeDataSetService } from '../analyze-data-set.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'analyze-data-set-chart',
  templateUrl: './analyze-data-set-chart.component.html',
  styleUrls: ['./analyze-data-set-chart.component.scss']
})
export class AnalyzeDataSetChartComponent implements OnInit, OnDestroy {

  @Input()
  public displaySettings = false;

  @ViewChild('chartCanvas')
  private chartCanvas: any;

  public chartReady = false;

  private chart: any;

  public chartType = 'bar';
  public chartOptions;
  public results;

  private destroyed: Subject<boolean> = new Subject<boolean>();
  
  constructor(
    public analyzeDataSetService: AnalyzeDataSetService,
    private themeService: ThemeService) { 
  }

  ngOnInit() {
    this.analyzeDataSetService.results.pipe(takeUntil(this.destroyed)).subscribe(r => {
      this.results = r;
      this.displayChart();
    });
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  public displayChart() {

    if (!this.chartCanvas) {
      return;
    }

    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
      let canvas = this.chartCanvas.nativeElement;
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    }

    let records = this.results.records;
    let columnNames = this.results.columns.map(c => c.name);

    this.chartReady = records.length > 0;

    console.log("RESULTS", this.results);
    if (records) {
      // use the first dimension in the list as the label
      // use all measures in the list as the data

      let labelColumn = this.results.columns.find(c => c.measureFlag == 'N');
      let dataColumns = this.results.columns.filter(c => c.measureFlag == 'Y');

      console.log("CHART", labelColumn, dataColumns);

      var data = {
        labels: records.map(r => this.analyzeDataSetService.formatValue(r[labelColumn.name] || 'N/A', labelColumn)),
        datasets: []
      };

      for (let i = 0; i < dataColumns.length; i++) {
        let c = dataColumns[i];
        let dataset: any = {
          label: c.label,
          data: records.map(r => r[c.name])
        };

        console.log("DATASET", dataset);

        if (this.chartType != 'pie') {
          dataset.backgroundColor = this.themeService.getChartColorForId(i);
        } else {
          dataset.backgroundColor = this.themeService.getChartColors();
        }
        data.datasets.push(dataset);
      }

      this.chart = new Chart(this.chartCanvas.nativeElement, {
        type: this.chartType,
        data: data,
        options: Object.assign({
          responsive: true,
          maintainAspectRatio: false,
          tooltips: { enabled: false }
        },
          this.chartOptions)
      });

    }
  }
}
