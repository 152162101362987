import * as _ from "lodash";

export class DiagramHelper {

    groupComponents(components) {
        // group the diagrams by diagram id
        return _.groupBy(components, r => r.diagramId);
    }

    // diagrams is an array of arrays of diagram components
    // buildData is a function that will be called to generate the data for a given diagram component
    
    buildDiagramComponents(diagramId, diagrams, componentArray, options) {
        // console.log("BUILD DIAGRAM COMPONENTS", diagramId, diagrams, componentArray);
        // apply defaults
        let diagram = diagrams[diagramId];
        if (diagram) {
            for (let i = 0; i < diagram.length; i++) {
                this.buildDiagramComponent(diagram[i], diagrams, componentArray, options);
            }
        }
    }

    private buildDiagramComponent(dc, diagrams: any[], componentArray, options) {
        let transform = Object.assign({ scaleX: 1, scaleY: 1, offsetX: 0, offsetY: 0 }, options.transform);
        if (dc.visibleFlag != 'N') {
            let topLeft = this.transformPoint(dc.x, dc.y, transform);
            let bottomRight = this.transformPoint(dc.x + dc.width, dc.y + dc.height, transform);
            componentArray.push(Object.assign({
                x: topLeft.x, 
                y: topLeft.y,
                width: bottomRight.x - topLeft.x,
                height: bottomRight.y - topLeft.y,
                borderFlag: dc.borderFlag,
                radius: dc.radius
            }, 
            options.buildOverride(dc, options.parentDiagramComponent)));
        }
        if (dc.childDiagramId) {
            let childDiagram = diagrams[dc.childDiagramId];
            let scaling = this.getScalingToFitContainer({ 
                height: childDiagram.diagramHeight, 
                width: childDiagram.diagramWidth },
                dc);
            let origin = this.transformPoint(dc.x, dc.y, transform);
            let childTransform = {
                scaleX: transform.scaleX * scaling.x,
                scaleY: transform.scaleY * scaling.y,
                offsetX: origin.x,
                offsetY: origin.y,
            };
            this.buildDiagramComponents(dc.childDiagramId, diagrams, componentArray, 
                Object.assign({}, options, { 
                    transform: childTransform,
                    parentDiagramComponent: dc
                }));
        }
    }

    transformPoint(x, y, transform) {
        x = (x * (transform.scaleX || 1)) + (transform.offsetX || 0);
        y = (y * (transform.scaleY || 1)) + (transform.offsetY || 0);
        return { x: x, y: y };
    }    
    
    public getScalingToFitContainer(obj: any, container: any, options?:any) {
        let scale = 1.0;
    
        options = Object.assign({ padding: .1 }, options);
        
        if (options.scalingMethod == 'width') {
            scale = (obj.width / container.width);
        } else if (obj.width / container.width > obj.height / container.height) {
            scale = (container.width  * (1 - options.padding)) / obj.width;
        } else {
            scale = (container.height * (1- options.padding)) / obj.height;
        }
        return { x: scale, y: scale };
      }
        
}