import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { StoreQuery, StoreService } from '../../platform';

@Component({
    selector: 'app-location-list',
    templateUrl: './location-list.component.html',
    styleUrls: ['./location-list.component.css']
})
export class LocationListComponent implements OnInit {

    @Input()
    private parentLocationId: any;

    @Output()
    public locationClick: EventEmitter<any> = new EventEmitter<any>();

    public locationQuery: StoreQuery;

    constructor(
        private storeService: StoreService
    ) {
        this.locationQuery = storeService.query();
        this.locationQuery.baseSpec = {
            queryType: 'model/locations',
            sorts: [{ property: 'locationName', direction: 'ascending' }]
        };
    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.refresh();
    }

    private refresh() {
        let filters:any = {};
        if (this.parentLocationId) {
            filters.parentLocationId = this.parentLocationId;
            this.locationQuery.filters = filters;
            this.locationQuery.ready = true;
        }
    }

}
