import { Directive, HostListener, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { DragDropService } from './drag-drop.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appDragDrop]',
  host: { 
    '(dragstart)': 'handleDragStart($event)',
    '(dragend)': 'handleDragEnd($event)',
    '(dragover)': 'handleDragOver($event)',
    '(drop)': 'handleDrop($event)',
    '(mousemove)': 'handleMouseMove($event)',
    '[draggable]': 'true'
  }
})
export class DragDropDirective implements OnDestroy {

  @Input()
  private dragValue: any;

  @Input()
  private droppable: boolean = false;

  @Input()
  private draggable: boolean = false;

  @Output()
  private valueDroppedFrom: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  private valueDroppedTo: EventEmitter<any> = new EventEmitter<any>();

  private dragSubscription;
  private destroyed: Subject<boolean> = new Subject<boolean>();

  constructor(private dragDropService: DragDropService) {
  }

  public ngOnDestroy() {
    this.destroyed.next(true);
  }

  private handleDrop(event) {
    console.log("DROP", event);
    if (this.droppable) {
      event.preventDefault();
      event.stopPropagation();
      this.valueDroppedTo.next({ sourceEvent: event, value: this.dragDropService.drop() });
    }
  }

  private handleDragStart(event) {
    console.log("DRAGSTART", event);
    if (this.dragValue && this.draggable) {
      this.dragDropService.startDrag(this.dragValue)
      .pipe(takeUntil(this.destroyed))
      .subscribe(v => this.valueDroppedFrom.next({ sourceEvent: event, value: v }));
    }
  }

  private handleDragEnd(event) {
    console.log("DRAGEND", event);
    this.dragDropService.endDrag();
  }

  private handleDragOver(event) {
    if (this.droppable) {
      //console.log("DRAGOVER", event);
      event.preventDefault();
    }
  }

  private handleMouseMove(event) {
    // if (this.dragValue) {
    //   event.preventDefault();
    // }
  }
}
