import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'containsNone'
})
export class ContainsNonePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!value) {
            return true;
        }
        return !args.find(a => value.find(v => v == a));
    }

}
