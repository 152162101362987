import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UtilityService, SecurityService, StoreService } from '../../platform';

@Component({
    selector: 'app-file-card',
    templateUrl: './file-card.component.html',
    styleUrls: ['./file-card.component.css']
})
export class FileCardComponent implements OnInit {

    @Input()
    public file: any;

    @ViewChild('downloadIframe')
    private downloadIframe: any;

    constructor(
        public utilityService: UtilityService,
        private storeService: StoreService
    ) { }

    ngOnInit() {
    }

    handleFileTypeChanged(file, fileTypeId) {
        console.log("FILE", file, fileTypeId);
        this.storeService.dispatch({
            actionType: 'file/setFileType',
            payload: {
                data: {
                    fileId: file.fileId,
                    fileTypeId: fileTypeId
                }
            }
        });
    }
    
    private download(fileId) {
        this.downloadIframe.nativeElement.src = '/sandbox/ws/file/' + fileId + '?attachment=true';
    }

    private reprocess(fileId) {
        this.storeService.dispatch({
            actionType: 'file/reprocessFile',
            payload: {
                fileId: fileId,
            }
        });   
    }    
}
