import { Component, OnInit, Input, Output, TemplateRef, ElementRef, ViewChild, EventEmitter, ContentChild } from '@angular/core';
import { StoreQuery, StoreService } from '../store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OverlayConfig, ConnectionPositionPair } from '@angular/cdk/overlay';

export abstract class AbstractQueryAutocomplete {

    @Input()
    public baseSpec: any;

    @Input()
    public displayProperty: string;

    @Input()
    public valueProperty: string;

    @Input()
    public placeholder: string;

    @Input()
    public recordLimit: number = 20;

};

@Component({
    selector: 'app-query-autocomplete-dialog',
    templateUrl: './query-autocomplete-dialog.component.html',
    styleUrls: ['./query-autocomplete-dialog.component.css']
})
export class QueryAutocompleteDialogComponent extends AbstractQueryAutocomplete implements OnInit {

    @Input()
    set baseSpec(v) {
        this._baseSpec = v;
        if (this.query) {
            this.query.baseSpec = v;
        }
    };

    get baseSpec() { return this._baseSpec };
    private _baseSpec: any;
    
    @Input()
    private value: any;

    @Input()
    private relativeTo;

    @Output()
    private changeRecord: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('dialogTemplate')
    public dialogTemplate: TemplateRef<any>;

    @ViewChild('dialogInput')
    public dialogInput: ElementRef;   

    @Input()
    public displayTemplate: TemplateRef<any>;

    public query: StoreQuery;
    public records: any[] = [];
    public displayPager = false;
    public dialogInputValue;
    
    private selectedRecord: any;
    private dialogRef: MatDialogRef<any>;

    private destroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private storeService: StoreService,
        private dialog: MatDialog
    ) { 
        super();
        this.query = storeService.query();
        this.query.baseSpec = this.baseSpec;
        this.query.recordLimit = this.recordLimit;
        this.query.results.pipe(takeUntil(this.destroyed)).subscribe(r => this.handleQueryResults(r));       
        
        // This is a total hack to support positioning dialogs relative to another element.
        // This is something that seems to be in the roadmap for angular material, 
        // so it will need to be removed before then.

        let me = this;
        let tmp:any = this.dialog;
        if (!tmp.__getOverlayConfig) {
            tmp.__getOverlayConfig = tmp._getOverlayConfig;
            tmp._getOverlayConfig = 
                function (dialogConfig: any): OverlayConfig {
                    let value = this.__getOverlayConfig(dialogConfig);
                    if (dialogConfig.relativeTo) {
                        value.positionStrategy = this._overlay.position().connectedTo(dialogConfig.relativeTo, { originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'top' });
                        value.positionStrategy.withPositions([
                                 new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'top' }),
                                 new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'bottom' })
                             ]);
                        
                        value.positionStrategy.width = function() { return this; };
                        value.positionStrategy.height = function() { return this; };
                        value.positionStrategy.centerHorizontally = function() { return this; };
                        value.positionStrategy.centerVertically = function() { return this; };
                    }
                    return value;
                };
        }
    }

    ngOnInit() {
        if (!this.valueProperty) {
            this.valueProperty = this.displayProperty;
        }
    }

    ngOnChanges(changes) {
        if ((changes.value || changes.valueProperty) && this.value && this.valueProperty) {

            // check to see if the value is already in the record list
            let record = this.records.find(r => r[this.valueProperty] == this.value);
            if (!record) {
                let filters = {};
                filters[this.valueProperty] = this.value;
                this.query.filters = filters;
                this.query.ready = true;
            } else if (record != this.selectedRecord) {
                this.selectedRecord = record;
                this.records = [ record ];
                this.changeRecord.emit(record);
            }
        }
    }

    private handleQueryResults(results) {
        this.records = results.records;

        // if the selected record is not set then try and set it
        if (this.value && !this.selectedRecord) {
            this.selectedRecord = results.records.find(r => r[this.valueProperty] == this.value);
            this.changeRecord.emit(this.selectedRecord);
        }
        this.displayPager = (this.records.length < results.totalRecords);
    }    

    handleInputChange() {
        this.filterQuery(this.dialogInput.nativeElement.value);
    }

    filterQuery(value) {
        let filters = {};
        filters[this.displayProperty] = { like: '%' + value + '%' };
        this.query.filters = filters;
        this.query.ready = true;
    }

    handleDialogKeyDown(event) {
        if (event.keyCode == '9' && this.records.length > 0) {
            event.preventDefault();
            this.handleRecordClick(this.records[0]);
        }
    }

    openDialog(inputValue) {
        this.dialogInputValue = inputValue;
        if (!inputValue) {
            this.query.filters = {};
            this.query.ready = true;
        }
        // This is the second part of the hack to make the dialog appear relative to
        // an element. Will need to be removed when angular material supports it
        // for real

        let config:any = new MatDialogConfig();
        config.relativeTo = this.relativeTo;
        this.dialogRef = this.dialog.open(this.dialogTemplate, config);
        this.dialogRef.afterOpen().subscribe(() => {
            this.dialogInput.nativeElement.setSelectionRange(0, this.dialogInput.nativeElement.value.length);
        });
    }

    handleRecordClick(record) {
        this.selectRecord(record);
        this.dialogRef.close();
    }

    selectRecord(record) {
        this.selectedRecord = record;
        this.records = [ record ];
        this.changeRecord.emit(this.selectedRecord);
    }    
}
