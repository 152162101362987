import { Component, Input, OnInit, Inject, ViewChild } from "@angular/core";
import { StoreService, StoreQuery } from "../store";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'query-selection-dialog',
    templateUrl: './querySelectionDialog.html',
    styleUrls: [ './querySelectionDialog.scss' ]
})
export class QuerySelectionDialogComponent implements OnInit {
    
    @Input()
    title: any;

    optionsQuery: StoreQuery;
    selectedRecord: any;

    @ViewChild("input")
    private input: any;

    constructor(
            @Inject(MAT_DIALOG_DATA) private data: any,
            private dialogRef: MatDialogRef<QuerySelectionDialogComponent>,
            private storeService: StoreService)
    {
        this.optionsQuery = storeService.query();
        this.optionsQuery.baseSpec = this.data.baseSpec;
        this.optionsQuery.ready = true;
    }

    public ngOnInit() {
    }

    public handleInputChange(event) {
        this.filter(event.target.value);
    }

    private filter(value) {
        let filters = {};
        filters[this.data.displayField] = { like: '%' + value + '%' };
        this.optionsQuery.filters = filters;
    }

    private selectItem(record) {
        this.selectedRecord = record;
        this.input.nativeElement.value = record[this.data.displayField];
        this.filter(record[this.data.displayField]);
    }

    save() {
        this.dialogRef.close(this.selectedRecord);
    }

}