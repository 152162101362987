import { Component, Input, Output, OnChanges, EventEmitter } from '@angular/core';

import { Logger } from 'angular2-logger/core';

import { Query } from '../services';
import { StoreQuery } from '../index';

@Component({
    selector: 'query-pager',
    templateUrl: './queryPager.html',
    styleUrls: [ 'queryPager.scss' ]
})
export class QueryPagerComponent {

    @Input()
    query: Query | StoreQuery;

    @Output()
    private changeBeginRecord: EventEmitter<any> = new EventEmitter<any>();

    constructor(private logger: Logger) {
    }

    private handleChangeBeginRecord(beginRecord) {
        this.logger.debug("setting begin record to " + beginRecord);
        this.query.beginRecord = beginRecord;
        if (this.query instanceof Query) {
            this.query.execute();
        }
    }
}
