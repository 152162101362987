import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { StoreService } from '../store';
import { QueryResult } from '../services';
import { AnalyzeDataSetTableComponent } from './analyze-data-set-table/analyze-data-set-table.component';
import { AnalyzeDataSetChartComponent } from './analyze-data-set-chart/analyze-data-set-chart.component';
import { Router } from '@angular/router';
import { AnalyzeDataSetService } from './analyze-data-set.service';
import { PagerComponent } from '../components';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'analyze-data-set',
  templateUrl: './analyze-data-set.component.html',
  styleUrls: ['./analyze-data-set.component.css'],
  providers: [ AnalyzeDataSetService ]
})
export class AnalyzeDataSetComponent implements OnChanges, OnInit, OnDestroy {

  @Input()
  private dataSetId: number;

  @ViewChild(AnalyzeDataSetTableComponent)
  private tableView: AnalyzeDataSetTableComponent;

  @ViewChild(AnalyzeDataSetChartComponent)
  private chartView: AnalyzeDataSetChartComponent;  

  @ViewChild(PagerComponent)
  private pager: PagerComponent;
  
  public descriptor: any;

  private destroyed: Subject<boolean> = new Subject<boolean>();

  constructor(
    private analyzeDataSetService: AnalyzeDataSetService,
    private storeService: StoreService,
    private router: Router) {
  }

  ngOnInit() {
    this.analyzeDataSetService.results.pipe(takeUntil(this.destroyed)).subscribe(r => this.handleNewResults(r));
    this.pager.recordsPerPage = this.analyzeDataSetService.recordLimit;
    this.pager.beginRecord = this.analyzeDataSetService.beginRecord;
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  ngOnChanges(changes) {
    if (changes.dataSetId) {
      this.analyzeDataSetService.dataSetId = this.dataSetId;
    }
  }

  private handleNewResults(results) {
    console.log(results);
    this.pager.beginRecord = results.beginRecord;
    this.pager.totalRecords = results.totalRecords;
    this.pager.currentRecords = results.records.length;
  }

  refresh() {
    this.analyzeDataSetService.refreshDataSet();
  }

  handleMarkerClick(event) {
    if (event.locationId) {
      this.router.navigate([ "/pages/sites/explore/location", event.locationId ]);
    }
  }

  handleChangeBeginRecord(beginRecord) {
    console.log("CHANGE BEGIN", beginRecord);
    this.analyzeDataSetService.beginRecord = beginRecord;
  }
}
