import { Inject, Component, Input, ViewChild, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Logger } from 'angular2-logger/core';
import { SecurityService } from "../services/security.service";
import { UtilityService } from "../services/utility.service";
import { StoreService, } from "../store/store.service";
import { StoreQuery } from "../index";
import { Subject } from "rxjs";
import { DiagramComponent } from "./diagram.component";
import { takeUntil } from "rxjs/operators";


@Component({
  selector: 'display-image-dialog',
  templateUrl: './displayImageDialog.html',
  styleUrls: [ './displayImageDialog.scss' ]
})
export class DisplayImageDialogComponent implements OnInit {

    @Input()
    private fileId: any;

    @ViewChild(DiagramComponent)
    private diagram: DiagramComponent;

    private image: any;
    private annotations: any[];

    private rotation = 0;
    private annotationQuery: StoreQuery;
    private destroyed: Subject<boolean> = new Subject<boolean>();
    
    constructor(
            @Inject(MAT_DIALOG_DATA) data: any, 
            private securityService: SecurityService,
            private storeService: StoreService,
            private utilityService: UtilityService,
            private logger: Logger) {

        if (data.fileId) {
            this.fileId = data.fileId;
        }
        this.annotationQuery = storeService.query();
        this.annotationQuery.baseSpec = {
            queryType: 'general/imageAnnotations'
        }
        this.image = new Image();
        this.annotationQuery.results.pipe(takeUntil(this.destroyed)).subscribe(r => { this.annotations = r.records; this.buildDiagramComponents(); });
    }

    public ngOnInit() {
        var me = this;
        console.log(this);


        this.logger.debug("fileId", this.fileId);
        this.annotationQuery.filters = { fileId: this.fileId };
        this.annotationQuery.ready = true;

        this.image.onload = () => this.buildDiagramComponents();
        this.image.src = "/sandbox/ws/file/" + this.fileId;
    }

    public ngOnDestroy() {
        this.destroyed.next(true);
    }

    private buildDiagramComponents() {
        if (!this.image.complete || !this.diagram) {
            return;
        }

        this.diagram.setDiagramSize(this.image.width, this.image.height);
        
        let diagramComponents = [];
        diagramComponents.push({ 
            image: this.image, 
            x: 0, 
            y: 0, 
            width: this.image.width, 
            height: this.image.height
        });

        this.annotations.forEach(r => {
            let vertices = JSON.parse(r.verticesJson);
            diagramComponents.push({ vertices: vertices, strokeStyle: 'lime' });
        });

        this.diagram.components = diagramComponents;
    }
  }
