import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinkHelperService {

    public linkTypeHandlerMap = {};

    constructor() { }

    public addLinkHandler(linkType, handler) {
        this.linkTypeHandlerMap[linkType] = handler;
    }

    public getLinkRoute(linkType, object) {
        console.log("GET LINK ROUTE", linkType, object, this.linkTypeHandlerMap);
        let handler = this.linkTypeHandlerMap[linkType];
        if (handler) {
            return handler(object);
        }
    }

    public getLinkTypeFromHints(hints) {
        let linkTypes = (hints && hints.split(",").filter(h => h.startsWith("link:")));
        if (linkTypes && linkTypes.length != 0) {
            return linkTypes[0].substring(5);
        }
        return null;
    }   

}
