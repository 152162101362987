import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Logger } from 'angular2-logger/core';

import { Subject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { AuthenticationEvent } from './authenticationEvent.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class SecurityService {

    public authenticationEvents: Subject<AuthenticationEvent> = new Subject<AuthenticationEvent>();

    private sessionId: string;
    private authenticationHeaders: HttpHeaders = new HttpHeaders();
    private user: any;

    constructor(
        private http: HttpClient, 
        private logger: Logger,
        private MatSnackBar: MatSnackBar
    ) {
        this.sessionId = localStorage.getItem("sessionId");
    }

    public login(email: string, password: string) {
        var me = this;

        me.sessionId = undefined;

        var body = new HttpParams()
            .set("email", email)
            .set("password", password);
        
        this.http.post("/sandbox/ws/security/login", 
            body.toString(), 
            { 
                responseType: 'text',
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
            })
            .subscribe(response => this.createSession(response), error => me.handleError(error));
    }

    public logout()
    {
        this.clearSession();
    }

    public restore() {
        let sessionId = localStorage.getItem("sessionId");
        if (sessionId) {
            this.createSession(sessionId);
        } else {
            this.clearSession();
            this.authenticationEvents.next(new AuthenticationEvent('restoreFailed', {}));
        }
    }

    private clearSession() {
        this.user = undefined;
        this.sessionId = undefined;
        this.authenticationHeaders = new HttpHeaders();
        localStorage.removeItem("sessionId");
        this.authenticationEvents.next(new AuthenticationEvent('restoreFailed', {}));
    }

    private createSession(sessionId) {
        this.sessionId = sessionId;
        localStorage.setItem("sessionId", this.sessionId);
        this.authenticationHeaders = new HttpHeaders().append("Authorization", this.getAuthToken());
        this.authenticationEvents.next(new AuthenticationEvent('login', {}));

        // fetch the user information
        this.get("/sandbox/ws/security/user")
            .subscribe(response => this.handleUserResponse(response), error => this.handleError(error));
    }

    private handleUserResponse(result) {
        // the security service returns an object with the applicationUser and applicationRoles. Combine them to make a user
        // object with all of the properties of the applicationUser and a applicationRoles property
        this.user = Object.assign({}, result.applicationUser, { applicationRoles: result.applicationRoles });
        console.log(this.user);
        this.authenticationEvents.next(new AuthenticationEvent('user', this.user));
    }

    private handleError(error: any) {
        this.authenticationEvents.next(new AuthenticationEvent('error', {}));
    }

    public getUser() {
        return this.user;
    }

    public getAuthToken() {
        return "Custom " + this.sessionId;
    }

    public get(url, options?) {
        this.logger.debug("getting " + url, this.user, this.authenticationHeaders);
        let observable: Observable<any> = this.http.get(url, Object.assign({ headers: this.authenticationHeaders }, options)).pipe(share());
        observable.subscribe(response => null, error => this.checkAuthenticationError(error));
        return observable;
    }

    public post(url, data, headers?:HttpHeaders): Observable<any> {
        this.logger.debug("posting to " + url, this.user);
        let observable: Observable<any> = this.http.post(url, data, { headers: this.buildHeaders(headers) }).pipe(share());
        observable.subscribe(response => null, error => this.checkAuthenticationError(error));
        return observable;
    }    

    private buildHeaders(headers: HttpHeaders) {
        if (!headers) {
            headers = new HttpHeaders();
        }
        for (let key of this.authenticationHeaders.keys()) {
           headers = headers.append(key, this.authenticationHeaders.get(key));
            console.log("HEADER", key, this.authenticationHeaders.get(key));
        }
        return headers;
    }

    public log(message) {
        this.http.post("/sandbox/ws/log/logMessage", message).subscribe(r => r);
    }    

    public userHasRole(role) {
        this.logger.debug("checking for role: " + role);
        return this.user && (!!this.user.applicationRoles.find(r => r.applicationRoleName == role));
    }

    private checkAuthenticationError(error) {
        if (error.status == 401) {
            this.logger.error("authentication error", error);
            this.authenticationEvents.next(new AuthenticationEvent('error', {}));
        }
    }

  public getAndSnackbar(url, successMessage, failureMessage, callback) {
    var me = this;
      me.get(url)
      .subscribe(
        function(result) {
            me.MatSnackBar.open(successMessage, "Dismiss", { duration: 3000 });
            if (callback) {
                callback();
            }
        },
        function(error) {
            me.MatSnackBar.open(failureMessage, "Dismiss", { duration: 3000 }) 
        });      
  }    
}