import { Component, Input, Output, EventEmitter } from '@angular/core';

import { QueryService } from '../services/query.service';
import { QueryResult } from '../services/queryResult.model';

@Component({
  selector: 'query',
  template: '<ng-content></ng-content>',
})
export class QueryComponent {

  @Input()
  private queryType: string;

  @Input()
  private queryModifiers: any = {};

  @Output()
  private results: EventEmitter<QueryResult> = new EventEmitter<QueryResult>();

  constructor(protected queryService: QueryService) {
  }

  ngAfterViewInit() {
    var me = this;
    this.queryService.getQueryResult(Object.assign({ queryType: me.queryType }, me.queryModifiers))
      .subscribe(function (queryResult: QueryResult) {
        me.results.next(queryResult);
      });
  }
}
