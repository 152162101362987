import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Logger } from 'angular2-logger/core';
import { SecurityService } from "./platform";

 @Injectable()
 export class AppRouteReuseStrategy implements RouteReuseStrategy {

    private cache: {[key: string]: {
        handle: DetachedRouteHandle,
        component: any
    }} = {};

    constructor(private logger: Logger, private securityService: SecurityService) {
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // If the current route and the next route have the same URL, then reuse
        // I think that this skips storage of the handle, but I'm not sure

        let value = (this.getRouteKey(curr) == this.getRouteKey(future));
        this.logger.debug('AppRouteReuseStrategy:shouldReuseRoute', value, this.getRouteKey(future), this.getRouteKey(curr), future, curr);
        return value;
    }
    
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        let value = this.isReusable(route);
        this.logger.debug('AppRouteReuseStrategy:shouldDetach', value, this.getRouteKey(route), route);
        return value;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        let entry = {
            handle: handle,
            component: route.component || route.routeConfig.component
        };
        let key = this.getRouteKey(route);

        // Only cache one of each component type.  If we are already storing a component of 
        // this type, and it isn't the same path as the one we're trying to store, then destroy
        // it (using a hidden attribute of the handle - bad), and then remove from the cache.

        Object.keys(this.cache).map(k => {
            if (this.cache[k].component == route.component && k != key) {
                this.logger.debug('AppRouteReuseStrategy:store evict', this.cache[k], key, k);
                (this.cache[k].handle as any).componentRef.destroy();
                delete this.cache[k];
            }
        });

        this.cache[key] = entry;
        this.logger.debug('AppRouteReuseStrategy:store', this.getRouteKey(route), route, handle);
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        let value = false,
            key = this.getRouteKey(route);

        if (key) {
            let entry = 
            value = this.cache[key] ? true : false;
        }
        this.logger.debug('AppRouteReuseStrategy:shouldAttach', route, key, value);
        return value;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        let handle, 
            path = this.getRouteKey(route);
        
        if (path && this.cache[path]) {
            handle = this.cache[path].handle;
        }
        window.setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
        this.logger.debug('AppRouteReuseStrategy:retrieve', route, path, handle);
        return handle;
    }

    private getRouteKey(route: ActivatedRouteSnapshot): string {
        // generate the key from the URL segments from all route snapshots to this
        // one, and remove any trailing slash. 

        return route.pathFromRoot.reduce((a, c) => a + "/" + c.url.join("/"), "/");
    }

    private isReusable(route: ActivatedRouteSnapshot): boolean {
        if (!route) {
            return true;
        }
        if (!route.routeConfig.data || !route.routeConfig.data.reusable) {
            return false;
        }
        return true;
    }    
}