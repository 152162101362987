import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Logger } from 'angular2-logger/core';

import * as numeral from 'numeral';
import * as moment from 'moment';
import * as moment_duration_format from 'moment-duration-format';
import { FormGroup } from "@angular/forms";

@Injectable()
export class UtilityService {

  public moment:any = moment;
  public momentDurationFormat: any = moment_duration_format;
  public numeral = numeral;

  constructor(private logger: Logger) {
  }

  public formatCurrency(amt: number, forceDecimal?: boolean) {
    return numeral(amt).format(forceDecimal ? '($0,0.00)' : '($0,0[.]00)');
  }

  public formatInteger(amt: number) {
    return numeral(amt).format('0,0)');
  }

  public formatDate(date) {
    return moment(date).format("L");
  }

  public formatNumber(value, format) {
    return numeral(value).format(format);
  }

  public dateDifferenceValue(value, minDays, minValue, defaultValue) {
        let today:any = new Date();
        let compare:any = new Date(value);
        let days = Math.round((today - compare) / (1000 * 60 * 60 * 24));
        if (days >= minDays) {
            return minValue;
        }
        return defaultValue;
    }  

   public formatTimeDifference(begin, end) {
      var me = this;

      if (begin) {
        begin = moment(begin);
      } else {
        begin = me.moment();
      }

      if (end) {
        end = moment(end);
      } else {
        end = me.moment();
      }
      return me.moment.duration(end-begin).format('HH:mm:ss', { trim: false });
   }
   
    public delimitersToSpaces(str) {
      return str.replace(/_/g, ' ');
    }

    public chartOptions(...args: string[]) {
      var me = this;
      var options: any = { legend: { display: false }, scales: { yAxes: [], xAxes: [] } };

      for (let i = 0; i < arguments.length; i++) {
        switch (args[i]) {
          case 'legend':
            options.legend.display = true;
            break

          case 'nopoints':
            options.elements = { point: { radius: 0 } };
            break;

          case 'x-date':
            options.scales.xAxes[0] = options.scales.xAxes[0] || { };
            options.scales.xAxes[0].ticks = { callback: function(x) { return me.formatDate(x); } };
            break;

          case 'x-currency':
            options.scales.xAxes[0] = options.scales.xAxes[0] || { };
            options.scales.xAxes[0].ticks = { beginAtZero: true, callback: function(x) { return me.formatCurrency(x); } };
            break;
            
          case 'x-stacked':
            options.scales.xAxes[0] = options.scales.yAxes[0] || { };
            options.scales.xAxes[0].stacked = true;
            break;

          case 'y-currency':
            options.scales.yAxes[0] = options.scales.yAxes[0] || { };
            options.scales.yAxes[0].ticks = { beginAtZero: true, callback: function(x) { return me.formatCurrency(x); } };
            break;

          case 'x-integer':
            options.scales.xAxes[0] = options.scales.xAxes[0] || { };
            options.scales.xAxes[0].ticks = { beginAtZero: true, callback: function(x) { return me.formatInteger(x); } };
            break;
          
          case 'y-integer':
            options.scales.yAxes[0] = options.scales.yAxes[0] || { };
            options.scales.yAxes[0].ticks = { beginAtZero: true, callback: function(x) { return me.formatInteger(x); } };
            break;
          case 'y-stacked':
            options.scales.yAxes[0] = options.scales.yAxes[0] || { };
            options.scales.yAxes[0].stacked = true;
            break;

          case 'pie-currency':
            options.pieceLabel = {
              format: function(x) { return me.formatCurrency(x); }
            };
            break;
        }
      }
      return options;
    }

  public getInitials(name: string) {
    var tmp = name.replace(/[^A-Z]*([A-Z]).*([A-Z])[^A-Z]*$/,'$1$2');
    if (tmp == name) {
      tmp = name.substring(0, 1).toUpperCase();
    }
    return tmp;
  }

 public toCamelCase(input) { 
    return input.toLowerCase().replace(/[-_ ](.)/g, function(match, group1) {
        return group1.toUpperCase();
    });
  }   

  public isDetached(element): boolean {
    var me = this;
    if (element.offsetHeight == 0 || element.offsetWidth == 0) {
      me.logger.debug("element has zero width or height");
      return true;
    }
    for (let node = element; node; node = node.parentNode) {
      if (node == document) {
        return false;
      }
    }
    me.logger.debug("element is detached - no path to docment");
    return true;
  }

  public stringCompare(a, b) {
    if (!a) { 
      a = '';
    } 
    if (!b) {
      b = '';
    }
    return a.localeCompare(b);
  }

  public enableFormControls(form: FormGroup, controlNames: string[], enabled: boolean) {
      controlNames
          .map(c => form.get(c))
          .filter(c => c.enabled != enabled)
          .forEach(c => enabled ? c.enable() : c.disable());

  }

  public evaluateExpressionsInContext(str, context) {
    let regex = /\$\{([^}]+)\}/g;
    let match = null;
    let replaced = str;
    while (match = regex.exec(str)) {
        let f = eval('context => ' + match[1]);
        let val = null;
        try {
            val = f.call(null, context);
        } finally {
        }
        replaced = replaced.replace(match[0], JSON.stringify(val));
    }
    console.log("REPLACED", replaced);
    return eval(replaced);
  }

  public formatColumnValue(value, column) {
    if (column.dataFormat == "date") {
      return this.formatDate(value);
    }

    if (column.dataFormat == "currency") {
      return this.formatCurrency(value);
    }

    if (column.dataType == "number" && column.dataFormat) {
      return  this.formatNumber(value, column.dataFormat);
    }
    return value;
  }    

  public distinctValues(array: any[]) {
      return [...Array.from(new Set(array))];
  }
}