import { Component } from '@angular/core';
import { MatDialogRef} from '@angular/material';

@Component({
  selector: 'simple-cell-edit',
  templateUrl: './simpleCellEdit.html',
  styleUrls: [ './simpleCellEdit.scss' ]
})
export class SimpleCellEditComponent {

  constructor(private  dialogRef: MatDialogRef<SimpleCellEditComponent>) {
  }

}