import { BehaviorSubject ,  Observable ,  Subscription, Subject } from 'rxjs';

import { Logger } from 'angular2-logger/core';

import { Query, QueryResult, QueryService, NotificationService } from '../services';
import { AbstractQuery } from './abstract-query';

export class StoreQuery extends AbstractQuery {
    private _baseSpec: any;
    private _query: Query;
    private subscriptions: Subscription[] = [];

    get baseSpec(): any { return this._baseSpec };
    set baseSpec(baseSpec: any) { this._baseSpec = baseSpec; this.refresh(); };

    constructor(private _queryService: QueryService, private notificationService: NotificationService, private _logger: Logger) {
        super();
        this._query = new Query(_queryService);
        this._query.results.subscribe(r => this.processResults(r));
    }


    public refresh() {
        if (this._ready) {
            // create the spec
            let spec = this.createSpec();

            // otherwise, execute the query and emit the results
            this._query.recordLimit = this.recordLimit;
            this._query.beginRecord = this._beginRecord;

            // check the query store to see if we already have results for this query

            let key = this.specToKey(spec);
            let cachedEntry = sessionStorage.getItem(key);
            
            if (cachedEntry) {
                let result = JSON.parse(cachedEntry);
                this._logger.debug("using cached results for query", spec);
                this.processResults(result);
            } else {
                this._logger.debug("executing store query", spec);
                this._query.execute(spec);
            }
        }
    }

    private createSpec() {
        let spec = Object.assign({}, this._baseSpec);

        if (this._filters) {
            spec.filters = Object.assign({}, this._baseSpec.filters, this._filters);        
        }

        if (this._sorts) {
            spec.sorts = this._sorts.concat(this._baseSpec.sorts || []);
        }
        return spec;
    }

    public addRefreshNotification(filter) {
        let sub = this.notificationService.notify(filter).subscribe(x => { 
            console.log("refresh notification: " + x); this.refresh(); 
        });
        this.subscriptions.push(sub);
    }

    public removeAllRefreshNotifications() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public createDataSet() {
        let spec = this.createSpec();
        return this._queryService.createDataSet(spec);
    }

    private processResults(results) {

        //
        // The following lines are the beginning of the result cache implementation.
        // Before uncommenting, need to make sure:
        //   1. there is some kind of aging implemented
        //   2. refresh notifiations are honored (need to remove relevant entries from the cache)
        //   3. need to handle when session storage is full (I believe the browser throws an exception)
        // 
        // if (!results.pending) {
        //     let key = this.specToKey(results.spec);
        //     sessionStorage.setItem(key, JSON.stringify(results));
        // }

        this._resultsSubject.next(results);
    }

    public destroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this._query.destroy();
    }

    private specToKey(spec) {
        let clean = Object.assign({ beginRecord: 1 }, spec);
        let key = btoa(JSON.stringify(clean));
        return key;
    }
}

export class QueryStore {
    private queries: StoreQuery[] = [];
    
    constructor(
        private queryService: QueryService, 
        private notificationService: NotificationService,
        private logger: Logger) {
    }

    public query() : StoreQuery {
        let q = new StoreQuery(this.queryService, this.notificationService, this.logger);
        this.queries.push(q);
        return q;
    }

    public destroy(query: StoreQuery) {
        let i = this.queries.indexOf(query);
        if (i >= 0) {
            this.queries[i].destroy();
            this.queries.splice(i, 1);
        }
    }
}