import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StoreQuery, StoreService } from '../../../platform/store';
import { PagesService } from '../../pages.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-site-equipment-list',
    templateUrl: './site-equipment-list.component.html',
    styleUrls: ['./site-equipment-list.component.css']
  })
  export class SiteEquipmentListComponent implements OnInit, OnChanges {

    @Input()
    private locationId: any;

    @Input()
    private parentEquipmentId: any;

    @Output()
    private equipmentClick: EventEmitter<any> = new EventEmitter<any>();

    public childEquipmentQuery: StoreQuery;

    private destroyed: Subject<boolean> = new Subject<boolean>();
    private client: any;

    constructor(
        private storeService: StoreService,
        private pagesService: PagesService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.childEquipmentQuery = storeService.query();
        this.childEquipmentQuery.baseSpec = {
            queryType: 'model/equipment',
            filters: {
                attachedToEquipmentId: { isNull: true }
            },
            sorts: [{ property: 'equipmentName', direction: 'ascending' }]
        };

        this.activatedRoute.params.pipe(takeUntil(this.destroyed)).subscribe(params => {
            this.parentEquipmentId = params.parentEquipmentId;
            this.refresh();
        });

        this.activatedRoute.parent.params.pipe(takeUntil(this.destroyed)).subscribe(params => {
            this.locationId = params.locationId;
            this.refresh();
        })
    }

    ngOnInit() {
        this.pagesService.client.pipe(takeUntil(this.destroyed)).subscribe(client => this.handleChangeClient(client));
    }

    ngOnDestroy() {
        this.destroyed.next(true);
    }

    handleChangeClient(client) {
        var me = this;
        this.client = client;
    }

    ngOnChanges() {
        this.refresh();
    }

    refresh() {
        console.log("REFRESH", this.locationId, this.parentEquipmentId);
        if (this.locationId) {
            if (this.parentEquipmentId) {
                this.childEquipmentQuery.filters = { attachedToEquipmentId: this.parentEquipmentId };
                this.childEquipmentQuery.ready = true;
            } else {
                this.childEquipmentQuery.filters = { locationId: this.locationId };
                this.childEquipmentQuery.ready = true;
            }
        } else {
            this.childEquipmentQuery.ready = false;
        }
    }

    public handleEquipmentClick(equipment) {
        this.router.navigate([ '/pages/sites/explore/equipment', equipment.equipmentId ]);
    }    

    
    handleEquipmentSurvey(event, equipment) {
        event.stopPropagation();
        this.router.navigate([ '/pages/physicalAudit/equipmentAudit', equipment.equipmentId ]);
    }
    
}
