import { Injectable, OnInit } from '@angular/core';
import { Logger } from 'angular2-logger/core';
import { GoogleMapService } from './googleMap.service';

@Injectable()
export class GoogleMapInfoWindowService {

    private map: any;
    private google: any;

    private contentElement: any;

    private infoWindow: any;
    private marker: any;
    private pendingOpen: boolean;


    constructor(private googleMapService: GoogleMapService, private logger: Logger) {
        var me = this;
        me.googleMapService.getMap().subscribe(function (data) {
            me.map = data.map;
            me.google = data.google;
        });
    }

    public setContentElement(contentElement: any) {
        this.contentElement = contentElement;
    }

    public clearInfoWindow() {
        var me = this;
        if (me.infoWindow) {
            me.infoWindow.close();
        }
    }

    public makeInfoWindowVisible() {
        var me = this;
        if (me.pendingOpen) {
            me.pendingOpen = false;
            let content = me.contentElement.querySelector(".google-map-info-window") || me.contentElement;
            me.logger.debug("info window", content);
            me.contentElement = content;

            if (!me.infoWindow) {
                me.infoWindow = new me.google.maps.InfoWindow({ content: content });
            } else {
                me.infoWindow.setContent(content);
            }
            me.infoWindow.open(me.map, me.marker);
        }
    }

    public openInfoWindow(marker) {
        var me = this;
        me.marker = marker;
        me.pendingOpen = true;
    }

}


