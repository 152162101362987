import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-filter-prompt',
    templateUrl: './filter-prompt.component.html',
    styleUrls: ['./filter-prompt.component.css']
})
export class FilterPromptComponent implements OnInit {

    @Input()
    promptConfig: any = {};

    @Output()
    filterChange: EventEmitter<any> = new EventEmitter<any>();

    private defaultConfig = {
        allowedOperators:  [ 'equal', 'between', 'contains' ],
        dataType: 'string'
    };

    public allowedOperators;
    public dataType;
    public label;
    public validValuesDescriptor;
    public filter;

    public allOperators = [ 'equal', 'between', 'contains' ];

    public operatorTitles = {
        eq: 'Equal To',
        between: 'Between',
        contains: 'Contains'
    };

    public selectedOperator;

    validValuesQuerySpec: any;
    validValuesDisplayProperty: any;
    validValuesValueProperty: any;
    inputType: 'queryAutocomplete';
    value: any;

    constructor() {
        this.updateConfig();
     }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        this.updateConfig();
    }

    private updateConfig() {
        console.log("PROMPT CONFIG", this.promptConfig)
        for (let property of ['allowedOperators', 'dataType', 'label' ]) {
            this[property] = this.promptConfig[property] || this.defaultConfig[property];
        }
        if (!this.selectedOperator && this.allowedOperators && this.allowedOperators.length > 0) {
            this.selectedOperator = this.allowedOperators[0];
        }

        let vvDescriptor = this.promptConfig.validValuesDescriptor;
        this.validValuesQuerySpec = null;
        this.validValuesDisplayProperty = null;
        this.validValuesValueProperty = null;
        if (vvDescriptor) {
            switch (vvDescriptor.type) {
            case 'query':
                this.validValuesQuerySpec = JSON.parse(vvDescriptor.querySpecTemplate);
                this.validValuesDisplayProperty = vvDescriptor.displayProperty;
                this.validValuesValueProperty = vvDescriptor.valueProperty;
                this.inputType = 'queryAutocomplete';
                break;
            }
        }
    }

    handleValueChange(value) {
        this.value = value;
        this.notifyFilterChange();

    }

    handleOperatorChange() {
        this.notifyFilterChange();
    }

    notifyFilterChange() {
        this.filter = { 
            property: this.promptConfig.propertyName,
            operator: this.selectedOperator,
            values: this.value
        };
        console.log("UPDATED FILTER", this.filter);
        this.filterChange.next(this.filter);
    }
}
