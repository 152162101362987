import { ChangeDetectorRef, ElementRef, ViewChild, Component, Input, OnChanges, ViewChildren, ViewContainerRef, ContentChildren, AfterViewInit, QueryList, ChangeDetectionStrategy } from '@angular/core';
import { ComponentPortal, Portal, TemplatePortalDirective } from '@angular/cdk/portal';
import { MatInput } from '@angular/material';

import { QueryTableColumnComponent } from './queryTableColumn.component';
import { SimpleCellEditComponent } from './simpleCellEdit.component';
import { UtilityService } from '../../services';
import { RemoteActionService } from '../../actions';
import { Router } from '@angular/router';
import { LinkHelperService } from '../../link-helper.service';

@Component({
    selector: 'query-table-cell',
    templateUrl: './queryTableCell.html',
    styleUrls: [ './queryTableCell.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryTableCellComponent implements OnChanges {

    @Input() 
    column: QueryTableColumnComponent;

    @Input()
    record: any;

    public formattedValue;

    panelOpen: boolean = false;

    private dialogRef: any;

    constructor(
        private changeDetectorRef: ChangeDetectorRef, 
        private actionService: RemoteActionService, 
        private elementRef: ElementRef, 
        private viewContainerRef: ViewContainerRef, 
        private utilityService: UtilityService,
        private linkHelperService: LinkHelperService,
        private router: Router) {
    }

    ngOnChanges() {
        if (this.column.template) {
            this.viewContainerRef.createEmbeddedView(this.column.template, { record: this.record, column: this.column });
        } else {
            this.formattedValue = this.defaultValueFormatter(this.column, this.record);
        }
    }

    defaultValueFormatter(column, record) {
        let me = this;
        let value = record[column.property];
        if (column.format == "currency") {
            return me.utilityService.formatCurrency(value, true);
        } else if (column.format == "integer") {
            return me.utilityService.formatInteger(value);
        } else if (column.format == "time") {
            if (value) {
                let m = me.utilityService.moment(value);
                value = m.format('L') + ' ' + m.format('HH:mm:ss');
            }
        } else if (column.format == "date") {
            if (value) {
                let m = me.utilityService.moment(value);
                value = m.format('L');
            }
        }
        return value;
    }

    focus() {
        this.elementRef.nativeElement.focus();
    }

    handleLinkClick(event) {
        let location = this.linkHelperService.getLinkRoute(this.column.linkType, this.record);
        if (location) {
            this.router.navigate(location);
            event.stopPropagation();
        }
    }
    
}