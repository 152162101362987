import { Injectable, Inject } from '@angular/core';
import {  Observable ,  Subject ,  Observer } from 'rxjs';
import { Logger } from 'angular2-logger/core';
import { DOCUMENT } from '@angular/platform-browser';

@Injectable()
export class NotificationService {

    private webSocket: WebSocket;
    private webSocketSubscription: any;
    private nextId = 1;
    private observers: { [propName: number]: Observer<any>[] } = { };
    private outgoingMessages: string[] = [];

    constructor(
        private logger: Logger,
        @Inject(DOCUMENT) private document: any) {

        let matches = /([^:]*):\/\/([^\/]*)\/(.*)$/.exec(document.URL);
        if (matches) {
            let protocol = matches[1];
            let host = matches[2];
            let resource = matches[3];

            if (protocol == "https") {
                // NOTE: this doesn't support secure web sockets with ports specified
                protocol = "wss";
            } else {
                protocol = "ws";
            }

            // hack for development environment
            if (host == "localhost:3000") {
                host = "localhost:8080";
            }

            let url = protocol + "://" + host + "/sandbox/wss/notify";

            this.logger.info("connecting to notification service at: " + url);
            this.webSocket = new WebSocket(url);

            this.webSocket.onopen = e => this.handleWebSocketOpen();
            this.webSocket.onmessage = msg => this.handleNotification(msg.data);
            this.webSocket.onerror = e => this.logger.error("error from notification web socket", e);
            this.webSocket.onclose = e => this.logger.info("notification web socket closed");

            window.setInterval(() => { 
                console.log("PINGING", this.webSocket); 
                this.sendMessage(JSON.stringify({ action: 'ping' }));
            }, 30000);
        }
    }

    private handleWebSocketOpen() {
        this.logger.info("web socket connected");
        this.outgoingMessages.forEach(m => this.sendMessage(m));
        this.outgoingMessages = [];
    }

    private sendMessage(message) {
        if (!this.webSocket || this.webSocket.readyState != this.webSocket.OPEN) {
            this.outgoingMessages.push(message);
        } else {
            this.webSocket.send(message);
        }
    }

    private handleNotification(msg) {
        this.logger.debug("received notification ", msg);
        let notification = JSON.parse(msg);
        if (notification.subscriptionIds) {
            notification.subscriptionIds.forEach(id => {
                this.observers[id].forEach(observer => observer.next(notification));
            });
        }
    }

    public notify(filter: any) {
        let id = this.nextId++;
        
        this.sendMessage(JSON.stringify({ 
            action: 'subscribe', 
            id: id, 
            filters: filter 
        }));

        this.observers[id] = [];
        let observable = new Observable<any>(observer => {
            this.observers[id].push(observer);

            // return the function that gets called at unsubscribe time
            // in this case, remove the subscription from the list
            return () => { 
                    let index = this.observers[id].indexOf(observer);
                    if (index > -1) {
                        this.observers[id].splice(index, 1);
                    }
                    if (this.observers[id].length == 0) {
                        // send unsubscribe request to the client
                        this.sendMessage(JSON.stringify({ 
                            action: 'unsubscribe', 
                            id: id
                        }));
                    }
                };
            });
        return observable;            
    }
}