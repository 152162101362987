import { 
    Component,
    Inject
} from '@angular/core';

import { 
    FormBuilder,
    FormGroup
} from '@angular/forms';

import {
    StoreService,
    StoreQuery,
    FormHelper
} from '../../platform';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'edit-equipment',
    templateUrl: './equipmentEdit.html',
    styleUrls: [ './equipmentEdit.scss' ]
})
export class EquipmentEditComponent {

    form: FormGroup;
    formHelper: FormHelper;

    vendorBaseSpec: any;
    modelBaseSpec: any;
    positionBaseSpec: any;

    public saving = false;
    
    constructor(
            @Inject(FormBuilder) private formBuilder: FormBuilder,
            @Inject(MAT_DIALOG_DATA) public dialogData: any,
            private dialogRef: MatDialogRef<EquipmentEditComponent>,
            private storeService: StoreService) {
        
        this.form = formBuilder.group({ 
            equipmentType: null,
            equipmentVendor: formBuilder.control({ value: null, disabled: true }),
            equipmentModel: formBuilder.control({ value: null, disabled: true }),
            assetTagNumber: '',
            equipmentName: '',
            attachmentPosition: null
        });

        this.formHelper = new FormHelper(this.form);
        this.formHelper.dependencies = [
            { control: 'equipmentVendor', dependency: 'equipmentType' },
            { control: 'equipmentModel', dependency: 'equipmentVendor' }
        ];

        this.form.valueChanges.subscribe(data => this.formHelper.check());

        this.form.get('equipmentType').valueChanges.subscribe(v => {
            console.log("V", v);
            if (v) {
                this.vendorBaseSpec = { 
                    queryType: 'sites/equipmentVendors',
                    filters: { 
                        equipmentTypeId: v.equipmentTypeId,
                        equipmentPhysicalConfigurationId: { isNull: false }
                    },
                    sorts: [ { property: 'equipmentVendorName', direction: 'ascending' } ]
                }
            }
        });

         this.form.get('equipmentVendor').valueChanges.subscribe(v => {  
            console.log('VENDOR', v);
            if (v) {
                this.modelBaseSpec = { 
                    queryType: 'sites/equipmentModels',
                    filters: { 
                        equipmentVendorName: v.equipmentVendorName,
                        equipmentTypeId: this.form.get('equipmentType').value.equipmentTypeId,
                        equipmentPhysicalConfigurationId: { isNull: false }
                    },
                    sorts: [ { property: 'modelName', direction: 'ascending' } ]
                }
            }
         });

         if (dialogData.attachedToEquipment) {
            this.positionBaseSpec = {
                queryType: 'sites/equipmentAttachmentPositions', 
                filters: { 
                    equipmentId: dialogData.attachedToEquipment.equipmentId
                }, 
                sorts: [ 
                    { property: 'ordinalPosition', direction: 'ascending'}
                ] 
            };
        }
    }

    close() {
    }

    save() {
        let attachedToEquipment = this.dialogData && this.dialogData.attachedToEquipment;
        let locationId = this.dialogData.locationId || (attachedToEquipment && attachedToEquipment.locationId);
        let attachedToEquipmentId = attachedToEquipment && attachedToEquipment.equipmentId;
        let attachedToOrdinalPosition;

        if (attachedToEquipmentId) {
            attachedToOrdinalPosition = this.form.get('attachmentPosition').value;
        }
        console.log("ATTACHED TO ORDINAL", attachedToOrdinalPosition, this.form);
        this.saving = true;
        console.log("MODEL: ", this.form.get('equipmentModel'));
        let p = this.storeService.dispatchNotify({
            actionType: 'physicalAudit/createEquipment',
            payload: {
                equipmentModelId: this.form.get('equipmentModel').value,
                attachedToEquipmentId: attachedToEquipmentId,
                attachedToOrdinalPosition: attachedToOrdinalPosition,
                equipmentName: this.form.get('equipmentName').value,
                asstTagNumber: this.form.get('assetTagNumber').value,
                locationId: locationId,
                equipmentInventoryId: 2,  //TODO: fix this
                clientId: 61
            }
        }).then(() => {
            this.dialogRef.close();
            this.saving = false;
        }).catch(() => {
            this.saving = false;
        });
    }
}