import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class DragDropService {

  private dragValue: any;
  private dragSubject: Subject<any>;

  constructor() { }

  public startDrag(dragValue): Observable<any> {
    this.dragValue = dragValue;
    this.dragSubject = new Subject<any>();
    return this.dragSubject.asObservable();
  }

  public endDrag() {
    if (this.dragSubject) {
      this.dragSubject.complete();
    }
    this.dragValue = null;
  }

  public drop(): any  {
    if (this.dragSubject) {
      this.dragSubject.next(this.dragValue);
      this.dragSubject.complete();
      this.dragSubject = null;
    }
    return this.dragValue;
  }
}
