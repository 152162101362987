import { FormGroup } from "@angular/forms";

export class FormHelper {

    public dependencies: any[];

    constructor(private form: FormGroup) {}

    public check() {
        this.dependencies.forEach(d => {
            let control = this.form.get(d.control),
            dependentValue = this.form.get(d.dependency).value,
            disable = !dependentValue;

        // change state of control if necessary
        if (disable && !control.disabled) {
            control.disable();
        } else if (!disable && control.disabled) {
            control.enable();
        }
       
        });
    }
}
