import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { StoreService } from '../store';
import { QueryResult } from '../services';
import { UtilityService } from '../services';
import { Router } from '@angular/router';

@Injectable()
export class AnalyzeDataSetService {

  private _dataSetId;
  public get dataSetId() { return this._dataSetId; };
  public set dataSetId(dataSetId) { this._dataSetId = dataSetId; this.getDescriptor(); }

  private _beginRecord = 1;
  public get beginRecord() { return this._beginRecord };
  public set beginRecord(beginRecord) { 
    if (beginRecord != this._beginRecord) {
      this._beginRecord = beginRecord; 
      this.refreshDataSet(); 
    }
  };

  private _recordLimit = 100;
  public get recordLimit() { return this._recordLimit };
  public set recordLimit(recordLimit) { this._recordLimit = recordLimit; this.refreshDataSet(); };

  public descriptor: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public results: BehaviorSubject<any> = new BehaviorSubject<any>(new QueryResult());
  
  // from the descriptor - available dimensions and measures
  public dimensions;
  public measures;

  // selected dimensions/measures
  public selects;
  public filters = [];
  public sorts;  

  constructor(
    private storeService: StoreService,
    private utilityService: UtilityService,
    private router: Router) { }

  private getDescriptor() {
    this.storeService.describeDataSet(this.dataSetId).subscribe(r => { 
      r.dataSetProperties = r.dataSetProperties.sort((a, b) => a.dataSetPropertyId - b.dataSetPropertyId);
      this.dimensions = [];
      this.measures = [];
      this.selects = [];
      this.sorts = [];
      this.filters = [];

      r.dataSetProperties.forEach(p => {
        console.log("PROPERTY", p);
        if (p.measureFlag == 'Y') {
          this.measures.push(p);
        } else {
          this.dimensions.push(p);
        }

        if (!p.hints || p.hints.indexOf('hidden') == -1) {
            if (p.measureFlag != 'N' || p.distinctValueCount) {
              this.selects.push(p);
            }
        }

      });
      this.descriptor.next(r);
      this.refreshDataSet();
    });
  }

  public refreshDataSet() {
    if (this.descriptor) {
      let request = {
        dataSetId: this._dataSetId,
        selects: [],
        filters: this.filters,
        sorts: [],
        beginRecord: this._beginRecord,
        recordLimit: this._recordLimit
      };

      this.selects.forEach(p => request.selects.push({ 
        property: p.name,
        aggregateFunction: p.measure ? 'sum' : undefined
      }));

      this.sorts.forEach(p => request.sorts.push({ 
        expression: { property: p.name },
        sortDirection: p.sortDirection || 'ascending'
      }));      

      this.results.next(new QueryResult());
      this.storeService.getDataSet(request).subscribe(r => {
        console.log("RESULTS", r);
        this.results.next(r);
      });
    }    
  }
  public addToSelect(property) {
    this.selects.push(property);
  }

  public addFilter(filter) {
    this.filters.push(filter);
  }

  public changeSortDirection(property) {
    console.log("CHANGE SORT", property);
    if (property.sortDirection == 'descending') {
      property.sortDirection = 'ascending';
    } else {
      property.sortDirection = 'descending';
    }
  }

  public formatValue(value, column) {
    if (column.dataFormat == "date") {
      return this.utilityService.formatDate(value);
    }

    if (column.dataFormat == "currency") {
      return this.utilityService.formatCurrency(value);
    }

    if (column.dataType == "number" && column.dataFormat) {
      return  this.utilityService.formatNumber(value, column.dataFormat);
    }
    return value;
  }  

  public handleLinkAction(action) {
    console.log("HANDLE LINK ACTION", action);
    if (action.locationId) {
      this.router.navigate([ '/pages/sites/explore/location', action.locationId]);
    }
  }
}
