import { Component, Input, OnInit, ContentChild, TemplateRef } from '@angular/core';

@Component({
    selector: 'query-table-column',
    template: '<ng-content #template></ng-content>',
})
export class QueryTableColumnComponent {

    @Input() name: string;
    @Input() property: string;
    @Input() title: string;
    @Input() sortable: boolean = true;
    @Input() format: string;
    @Input() editable: boolean = false;
    @Input() placeholder: string;
    @Input() actionType: string;
    @Input() hidden: boolean = false;
    @Input() linkType: string;

    @ContentChild(TemplateRef) 
    public template: TemplateRef<any>;

}