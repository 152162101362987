import { Component, Input, ViewChild, ChangeDetectorRef, OnInit, OnDestroy, NgZone, ElementRef, OnChanges, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Logger } from 'angular2-logger/core';

import { UtilityService, Query, QueryService, StoreService, StoreQuery } from '../../platform';
import { PagesService } from '../../pages/pages.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LeafletMapComponent } from '../../platform/leaflet-map/leaflet-map.component';

import * as L from 'leaflet';
import { ObservableMedia } from '@angular/flex-layout';

@Component({
    selector: 'site-list',
    styleUrls: ['./site-list.component.scss'],
    templateUrl: './site-list.component.html'
})
export class SiteListComponent implements OnInit, OnDestroy, OnChanges {

    @Input()
    public orientation = 'portrait';

    @Input()
    public displayList = true;

    @ViewChild(LeafletMapComponent)
    private map: LeafletMapComponent;

    private markerLayer: any;
    private siteListDisplay;

    // these two are for client-side filtering. No need to go back to the server if 
    // the bounds are the same and the text is more filtered
    lastQuerySites: any[] = [];
    lastQueryBounds: any;
    lastQuerySearchText: string;
    lastQueryExternalSystemId: number;
    
    sites: any[] = [];
    

    firstSite = 1;
    equipmentFilter: boolean = false;
    siteQuery: StoreQuery;

    private searchText: any;
    private searchOptions: any[] = [];

    private locationListQuery: StoreQuery;

    private bounds: any;

    private simple: any;
    private hadFirstBoundsChange: boolean = false;

    private client: any;

    private filters: any = {};

    private selectedLocationList: any;

    private destroyed: Subject<boolean> = new Subject<boolean>();

    private initialLocationListName: any;

    public siteChartOptions = { 
        fixMirror: true,
        legend: { display: false }, 
        scales: { 
            yAxes: [{ ticks: { mirror: true } } ], 
            xAxes: [{ ticks: { maxRotation: 0, beginAtZero: true, callback: x => this.utilityService.formatCurrency(x) } } ] 
        }
     };

    constructor(
        private queryService: QueryService,
        private storeService: StoreService,
        private changeDetector: ChangeDetectorRef,
        private utilityService: UtilityService, 
        private logger: Logger, 
        private pagesService: PagesService, 
        private router: Router, 
        private activatedRoute: ActivatedRoute,
        private media: ObservableMedia) {
        
        this.siteQuery = storeService.query();
        this.siteQuery.baseSpec = {
            queryType: 'map/locations',
            sorts: [
                { property: 'recurringAmount', direction: 'descending' }
            ],
            recordLimit: 100000
        };

        this.locationListQuery = storeService.query();
        this.locationListQuery.baseSpec = {
            queryType: 'sites/locationLists'
        };
        this.locationListQuery.results.pipe(takeUntil(this.destroyed)).subscribe(r => this.handleLocationListChanged(r));

        this.media.asObservable().pipe(takeUntil(this.destroyed)).subscribe(mc => {
            this.orientation = this.media.isActive('lt-md') ? 'portrait' : 'landscape';
        });
        this.activatedRoute.queryParamMap.subscribe(map => {
            this.initialLocationListName = map.get('initialLocationListName');
          });  
    }

    ngOnInit() {
        var me = this;
        this.pagesService.client.pipe(takeUntil(this.destroyed)).subscribe(client => this.handleChangeClient(client));
    }

    initializeMap(map) {
        this.markerLayer = L.featureGroup();
        this.map.map.addLayer(this.markerLayer);  
        
        this.siteQuery.results.subscribe(results => {
            if (!results.pending) {
                this.logger.debug("received " + results.records.length + " sites");

                // check to see if we need to change the page
                if (this.firstSite > results.records.length) {
                    this.firstSite = 1;
                }
                this.sites = results.records;
                this.lastQuerySites = results.records;
                this.createMarkers();
            }
        });
        
    }

    ngOnChanges(changes) {
        this.refreshSites();
    }

    handleLocationListChanged(results) {

        if (results.totalRecords > 0) {
            results.records.forEach(r => {
                // add a siteListDisplay field to the record from external system properties
                r.detailBaseUrl = '/pages/sites/explore/location';
                r.externalSystemProperties.forEach(p => {
                    if (p.propertyName == 'siteListDisplay') {
                        r.siteListDisplay = p.propertyValue;
                    }
                    if (p.propertyName == 'detailBaseUrl') {
                        r.detailBaseUrl = p.propertyValue;
                    }
                    if (p.propertyName == 'primaryLocationList' && p.propertyValue == 'Y') {
                        if (!this.initialLocationListName) {
                            this.initialLocationListName = r.externalSystemName;
                        }
                    }
                });

            });
            if (this.initialLocationListName) {
                this.selectedLocationList = results.records.find(l => l.externalSystemName == this.initialLocationListName)
            } else {
                this.selectedLocationList = results.records[0];
            }            
            this.refreshSites();
        } else {
            this.selectedLocationList = undefined;
        }
    }

    refreshSites() {
        console.log("REFRESH SITES");
        if (!this.client || !this.selectedLocationList || !this.bounds) {
            return;
        }

        if (this.lastQueryFilterable()) {
            console.log("FILTER");
            this.filterSites();
        } else {
            console.log("QUERY");
            let filters: any = {
                clientId: this.client.clientId,
                externalSystemId: this.selectedLocationList.externalSystemId,
                locationGeometry: { intersectsGeometry: 
                    [ 
                        this.bounds.getSouthWest().lng, 
                        this.bounds.getSouthWest().lat, 
                        this.bounds.getNorthEast().lng,
                        this.bounds.getNorthEast().lat
                    ] }
            };

            if (this.searchText) {
                filters.locationName = { like: '%' + this.searchText + '%' };
            }

            if (this.equipmentFilter) {
                filters.equipmentCount = { greaterThan: 0 };
            }

            this.siteQuery.filters = filters;
            this.siteQuery.ready = true;

            this.lastQueryBounds = this.bounds;
            this.lastQuerySearchText = this.searchText;
            this.lastQueryExternalSystemId = this.selectedLocationList.externalSystemId;
        }
    }

    ngOnDestroy() {
        this.destroyed.next(true);
    }

    handleChangeClient(client) {
        this.client = client;
        this.selectedLocationList = undefined;

        if (this.markerLayer) {
            this.markerLayer.clearLayers();
        }
        
        if (client) {
            this.locationListQuery.filters = {
                clientId: this.client.clientId
            };        
            this.locationListQuery.ready = true;
        }
    }

    handleBoundsChanged(bounds) {
        this.bounds = bounds;
        this.refreshSites();
    }

    lastQueryFilterable() {
        if (this.lastQueryExternalSystemId != this.selectedLocationList.externalSystemId) {
            return false;
        }

        if (!this.lastQueryBounds || !this.lastQueryBounds.contains(this.bounds)) {
            return false;
        }
        if (this.lastQuerySearchText && !this.searchText) {
            return false;
        }
        if (this.lastQuerySearchText && this.searchText && !this.searchText.includes(this.lastQuerySearchText)) {
            return false;
        }
        return true;
    }

    filterSites() {
        let upperCaseSearchText;

        if (this.searchText) {
            upperCaseSearchText = this.searchText.toUpperCase();
        }
        // filter the site list
        this.sites = this.lastQuerySites
            .filter(site => this.bounds.contains({ lat: site.lat, lon: site.lon }))
            .filter(site => !upperCaseSearchText || (site.locationName.toUpperCase() && site.locationName.toUpperCase().includes(upperCaseSearchText)));

    
        // hide any markers that don't match the search text
        if (this.searchText != this.lastQuerySearchText) {
            for (let i = 0; i < this.lastQuerySites.length; i++) {
                let site = this.lastQuerySites[i];
                let displayMarker = !this.searchText || site.locationName.toUpperCase().includes(upperCaseSearchText);
                if (!displayMarker && site.markerDisplayed) {
                    this.markerLayer.removeLayer(site.marker);
                } else if (displayMarker && !site.markerDisplayed) {
                    this.markerLayer.addLayer(site.marker);
                }
                site.markerDisplayed = displayMarker;
            }
        }
    }

    trackBySite(index, item) {
        return item.addressId;
    }

    handleKeyUp(event) {
        console.log("KEY UP", event);
        if (this.searchText != event.target.value) {
            this.searchText = event.target.value;
            this.refreshSites();
        }
    }

    createMarkers() {
        this.markerLayer.clearLayers();
        this.lastQuerySites.forEach(r => {
            let marker = L.circleMarker([ r.lat, r.lon ], {
                stroke: true,
                fillColor: '#f03',
                color: '#f03',
                fillOpacity: 0.25,
                radius: 4
              });
              marker.on('click', e => this.router.navigate([ this.selectedLocationList.detailBaseUrl, r.locationId ], { relativeTo: this.activatedRoute }));

              // add the marker to the object returned by the query
              // a little bit of a hack, but want to be able to turn them on and off later

              r.marker = marker;
              r.markerDisplayed = true;
              this.markerLayer.addLayer(marker);
        });
    }
   
}

