import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { StoreService } from '../../store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataSetQuery } from '../../store/data-set-store';

@Component({
    selector: 'app-data-set-venn-view',
    templateUrl: './data-set-venn-view.component.html',
    styleUrls: ['./data-set-venn-view.component.css']
})
export class DataSetVennViewComponent implements OnInit, OnDestroy, OnChanges {

    vennSets: any[] = [];

    @Input()
    dataSetId: number;

    @Input()
    viewConfig: any;

    private dataSetQuery: DataSetQuery;
    private destroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private storeService: StoreService
    ) { 
        this.dataSetQuery = storeService.dataSetQuery();
        this.dataSetQuery.recordLimit = 50;
        this.dataSetQuery.results.pipe(takeUntil(this.destroyed)).subscribe(results => {
            console.log("DSQ results", results);
            this.buildDataSet(results.records);
        });        
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.destroyed.next(true);
    }

    ngOnChanges(changes) {
        if (changes.dataSetId) {
            this.refresh();
        }
    }

    refresh() {
        if (this.dataSetId) {
            this.dataSetQuery.dataSetId = this.dataSetId;
            this.dataSetQuery.sorts = this.viewConfig.sorts;
            this.dataSetQuery.selects = [ 
                { property: this.viewConfig.setsProperty },
                { property: this.viewConfig.sizeProperty }
            ];
            this.dataSetQuery.ready = true;
        } else {
            this.dataSetQuery.ready = false;
        }
    }

    buildDataSet(records) {
        this.vennSets = [];
        console.log("RECORDS", records);

        if (records) {
            this.vennSets = records.map(r =>
                ({ 
                    sets: r[this.viewConfig.setsProperty].split("|"),
                    size: r[this.viewConfig.sizeProperty]
                }));
            console.log("VENN SETS", this.vennSets);
        }
    }

    public handleChartClick(event) {
        console.log("CHART CLICK", event);
        //this.router.navigate(['pages/savings'], { queryParams: { vendorId: event.vendorId } });
    }

    public handleVennClick(event) {
        console.log("VENN CLICK", event);
        //this.router.navigate(['/pages/reports/view/'], { queryParams: { reportName: "Inventory Match" } });
    }
}
